import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ReactGA from 'react-ga4';
import Balances from "./stake";

ReactGA.initialize('G-RQ4Z615NWK');
const router = createBrowserRouter([
  {
    path: "/",
    element: <Balances />,
  },
  {
    path: "/stakeincomee",
    element: <Balances />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;