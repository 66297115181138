import { useEffect, useState } from "react";

import axios from "axios";

export default function Balances() {
  const [total, setTotal] = useState(0);
  const [totalNfts, setTotalNfts] = useState();
  const [spreadingPowers, setSpreadingPowers] = useState([]);
  const [nftId, setNftId] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [hasBooster, setHasBooster] = useState(false);
  const [booster, setBooster] = useState("");
  const [check, setCheck] = useState(false);

  const m1 = [
    {
      name: "Pathogen Lab #1",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1.png",
      edition: 1,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #10",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/10.png",
      edition: 10,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #100",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/100.png",
      edition: 100,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1000",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1000.png",
      edition: 1000,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1001",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1001.png",
      edition: 1001,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1002",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1002.png",
      edition: 1002,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1003",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1003.png",
      edition: 1003,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1004",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1004.png",
      edition: 1004,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1005",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1005.png",
      edition: 1005,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1006",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1006.png",
      edition: 1006,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1007",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1007.png",
      edition: 1007,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1008",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1008.png",
      edition: 1008,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1009",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1009.png",
      edition: 1009,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #101",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/101.png",
      edition: 101,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1010",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1010.png",
      edition: 1010,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1011",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1011.png",
      edition: 1011,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1012",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1012.png",
      edition: 1012,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1013",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1013.png",
      edition: 1013,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1014",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1014.png",
      edition: 1014,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1015",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1015.png",
      edition: 1015,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1016",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1016.png",
      edition: 1016,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1017",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1017.png",
      edition: 1017,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1018",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1018.png",
      edition: 1018,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1019",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1019.png",
      edition: 1019,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #102",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/102.png",
      edition: 102,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1020",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1020.png",
      edition: 1020,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 600,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1021",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1021.png",
      edition: 1021,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1022",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1022.png",
      edition: 1022,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1023",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1023.png",
      edition: 1023,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1024",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1024.png",
      edition: 1024,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1025",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1025.png",
      edition: 1025,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1026",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1026.png",
      edition: 1026,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1027",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1027.png",
      edition: 1027,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1028",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1028.png",
      edition: 1028,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1029",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1029.png",
      edition: 1029,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #103",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/103.png",
      edition: 103,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1030",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1030.png",
      edition: 1030,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1031",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1031.png",
      edition: 1031,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1032",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1032.png",
      edition: 1032,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1033",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1033.png",
      edition: 1033,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1034",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1034.png",
      edition: 1034,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1035",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1035.png",
      edition: 1035,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1036",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1036.png",
      edition: 1036,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1037",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1037.png",
      edition: 1037,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1038",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1038.png",
      edition: 1038,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1039",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1039.png",
      edition: 1039,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 650,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #104",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/104.png",
      edition: 104,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1040",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1040.png",
      edition: 1040,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1041",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1041.png",
      edition: 1041,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 650,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1042",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1042.png",
      edition: 1042,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1043",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1043.png",
      edition: 1043,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1044",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1044.png",
      edition: 1044,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1045",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1045.png",
      edition: 1045,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1046",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1046.png",
      edition: 1046,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1047",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1047.png",
      edition: 1047,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1048",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1048.png",
      edition: 1048,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1049",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1049.png",
      edition: 1049,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #105",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/105.png",
      edition: 105,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 600,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1050",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1050.png",
      edition: 1050,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1051",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1051.png",
      edition: 1051,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1052",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1052.png",
      edition: 1052,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1053",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1053.png",
      edition: 1053,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1054",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1054.png",
      edition: 1054,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1055",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1055.png",
      edition: 1055,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1056",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1056.png",
      edition: 1056,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1057",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1057.png",
      edition: 1057,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1058",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1058.png",
      edition: 1058,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1059",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1059.png",
      edition: 1059,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #106",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/106.png",
      edition: 106,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1060",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1060.png",
      edition: 1060,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1061",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1061.png",
      edition: 1061,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1062",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1062.png",
      edition: 1062,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1063",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1063.png",
      edition: 1063,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1064",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1064.png",
      edition: 1064,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1065",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1065.png",
      edition: 1065,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1066",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1066.png",
      edition: 1066,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1067",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1067.png",
      edition: 1067,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1068",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1068.png",
      edition: 1068,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1069",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1069.png",
      edition: 1069,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #107",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/107.png",
      edition: 107,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1070",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1070.png",
      edition: 1070,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1071",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1071.png",
      edition: 1071,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1072",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1072.png",
      edition: 1072,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1073",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1073.png",
      edition: 1073,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1074",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1074.png",
      edition: 1074,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 700,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1075",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1075.png",
      edition: 1075,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1076",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1076.png",
      edition: 1076,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1077",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1077.png",
      edition: 1077,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1078",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1078.png",
      edition: 1078,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1079",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1079.png",
      edition: 1079,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 750,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #108",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/108.png",
      edition: 108,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1080",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1080.png",
      edition: 1080,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1081",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1081.png",
      edition: 1081,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1082",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1082.png",
      edition: 1082,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1083",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1083.png",
      edition: 1083,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1084",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1084.png",
      edition: 1084,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1085",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1085.png",
      edition: 1085,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1086",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1086.png",
      edition: 1086,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1087",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1087.png",
      edition: 1087,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1088",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1088.png",
      edition: 1088,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1089",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1089.png",
      edition: 1089,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #109",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/109.png",
      edition: 109,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1090",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1090.png",
      edition: 1090,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1091",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1091.png",
      edition: 1091,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1092",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1092.png",
      edition: 1092,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1093",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1093.png",
      edition: 1093,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1094",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1094.png",
      edition: 1094,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1095",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1095.png",
      edition: 1095,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1096",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1096.png",
      edition: 1096,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1097",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1097.png",
      edition: 1097,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1098",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1098.png",
      edition: 1098,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1099",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1099.png",
      edition: 1099,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #11",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/11.png",
      edition: 11,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #110",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/110.png",
      edition: 110,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1100",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1100.png",
      edition: 1100,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1101",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1101.png",
      edition: 1101,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1102",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1102.png",
      edition: 1102,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1103",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1103.png",
      edition: 1103,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1104",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1104.png",
      edition: 1104,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1105",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1105.png",
      edition: 1105,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1106",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1106.png",
      edition: 1106,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1107",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1107.png",
      edition: 1107,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1108",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1108.png",
      edition: 1108,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1109",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1109.png",
      edition: 1109,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #111",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/111.png",
      edition: 111,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1110",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1110.png",
      edition: 1110,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1111",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1111.png",
      edition: 1111,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1112",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1112.png",
      edition: 1112,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1113",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1113.png",
      edition: 1113,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 600,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1114",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1114.png",
      edition: 1114,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1115",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1115.png",
      edition: 1115,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1116",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1116.png",
      edition: 1116,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1117",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1117.png",
      edition: 1117,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1118",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1118.png",
      edition: 1118,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1119",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1119.png",
      edition: 1119,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #112",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/112.png",
      edition: 112,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1120",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1120.png",
      edition: 1120,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1121",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1121.png",
      edition: 1121,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1122",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1122.png",
      edition: 1122,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1123",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1123.png",
      edition: 1123,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1124",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1124.png",
      edition: 1124,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1125",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1125.png",
      edition: 1125,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1126",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1126.png",
      edition: 1126,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1127",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1127.png",
      edition: 1127,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1128",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1128.png",
      edition: 1128,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1129",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1129.png",
      edition: 1129,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #113",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/113.png",
      edition: 113,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1130",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1130.png",
      edition: 1130,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1131",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1131.png",
      edition: 1131,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1132",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1132.png",
      edition: 1132,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1133",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1133.png",
      edition: 1133,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1134",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1134.png",
      edition: 1134,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1135",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1135.png",
      edition: 1135,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1136",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1136.png",
      edition: 1136,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 700,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1137",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1137.png",
      edition: 1137,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1138",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1138.png",
      edition: 1138,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 650,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1139",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1139.png",
      edition: 1139,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #114",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/114.png",
      edition: 114,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 700,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1140",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1140.png",
      edition: 1140,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1141",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1141.png",
      edition: 1141,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 650,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1142",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1142.png",
      edition: 1142,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1143",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1143.png",
      edition: 1143,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1144",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1144.png",
      edition: 1144,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1145",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1145.png",
      edition: 1145,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1146",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1146.png",
      edition: 1146,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1147",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1147.png",
      edition: 1147,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1148",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1148.png",
      edition: 1148,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1149",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1149.png",
      edition: 1149,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #115",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/115.png",
      edition: 115,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1150",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1150.png",
      edition: 1150,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1151",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1151.png",
      edition: 1151,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1152",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1152.png",
      edition: 1152,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1153",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1153.png",
      edition: 1153,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1154",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1154.png",
      edition: 1154,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 750,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1155",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1155.png",
      edition: 1155,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1156",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1156.png",
      edition: 1156,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1157",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1157.png",
      edition: 1157,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1158",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1158.png",
      edition: 1158,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1159",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1159.png",
      edition: 1159,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #116",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/116.png",
      edition: 116,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1160",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1160.png",
      edition: 1160,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1161",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1161.png",
      edition: 1161,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1162",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1162.png",
      edition: 1162,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1163",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1163.png",
      edition: 1163,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1164",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1164.png",
      edition: 1164,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1165",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1165.png",
      edition: 1165,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1166",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1166.png",
      edition: 1166,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1167",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1167.png",
      edition: 1167,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1168",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1168.png",
      edition: 1168,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1169",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1169.png",
      edition: 1169,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #117",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/117.png",
      edition: 117,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1170",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1170.png",
      edition: 1170,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1171",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1171.png",
      edition: 1171,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1172",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1172.png",
      edition: 1172,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1173",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1173.png",
      edition: 1173,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1174",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1174.png",
      edition: 1174,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1175",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1175.png",
      edition: 1175,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1176",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1176.png",
      edition: 1176,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1177",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1177.png",
      edition: 1177,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1178",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1178.png",
      edition: 1178,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1179",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1179.png",
      edition: 1179,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #118",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/118.png",
      edition: 118,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1180",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1180.png",
      edition: 1180,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1181",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1181.png",
      edition: 1181,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1182",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1182.png",
      edition: 1182,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1183",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1183.png",
      edition: 1183,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1184",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1184.png",
      edition: 1184,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1185",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1185.png",
      edition: 1185,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1186",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1186.png",
      edition: 1186,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1187",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1187.png",
      edition: 1187,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1188",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1188.png",
      edition: 1188,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1189",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1189.png",
      edition: 1189,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #119",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/119.png",
      edition: 119,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1190",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1190.png",
      edition: 1190,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1191",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1191.png",
      edition: 1191,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1192",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1192.png",
      edition: 1192,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1193",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1193.png",
      edition: 1193,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1194",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1194.png",
      edition: 1194,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1195",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1195.png",
      edition: 1195,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1196",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1196.png",
      edition: 1196,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1197",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1197.png",
      edition: 1197,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1198",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1198.png",
      edition: 1198,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1199",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1199.png",
      edition: 1199,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #12",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/12.png",
      edition: 12,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #120",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/120.png",
      edition: 120,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1200",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1200.png",
      edition: 1200,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1201",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1201.png",
      edition: 1201,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1202",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1202.png",
      edition: 1202,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1203",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1203.png",
      edition: 1203,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1204",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1204.png",
      edition: 1204,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1205",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1205.png",
      edition: 1205,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1206",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1206.png",
      edition: 1206,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1207",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1207.png",
      edition: 1207,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1208",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1208.png",
      edition: 1208,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1209",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1209.png",
      edition: 1209,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #121",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/121.png",
      edition: 121,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1210",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1210.png",
      edition: 1210,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1211",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1211.png",
      edition: 1211,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1212",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1212.png",
      edition: 1212,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1213",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1213.png",
      edition: 1213,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1214",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1214.png",
      edition: 1214,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1215",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1215.png",
      edition: 1215,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1216",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1216.png",
      edition: 1216,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1217",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1217.png",
      edition: 1217,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1218",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1218.png",
      edition: 1218,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1219",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1219.png",
      edition: 1219,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #122",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/122.png",
      edition: 122,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1220",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1220.png",
      edition: 1220,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1221",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1221.png",
      edition: 1221,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1222",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1222.png",
      edition: 1222,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1223",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1223.png",
      edition: 1223,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1224",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1224.png",
      edition: 1224,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1225",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1225.png",
      edition: 1225,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1226",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1226.png",
      edition: 1226,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1227",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1227.png",
      edition: 1227,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1228",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1228.png",
      edition: 1228,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1229",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1229.png",
      edition: 1229,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #123",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/123.png",
      edition: 123,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1230",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1230.png",
      edition: 1230,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1231",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1231.png",
      edition: 1231,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1232",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1232.png",
      edition: 1232,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 700,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1233",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1233.png",
      edition: 1233,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1234",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1234.png",
      edition: 1234,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1235",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1235.png",
      edition: 1235,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1236",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1236.png",
      edition: 1236,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1237",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1237.png",
      edition: 1237,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1238",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1238.png",
      edition: 1238,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1239",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1239.png",
      edition: 1239,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #124",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/124.png",
      edition: 124,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1240",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1240.png",
      edition: 1240,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1241",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1241.png",
      edition: 1241,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1242",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1242.png",
      edition: 1242,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1243",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1243.png",
      edition: 1243,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1244",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1244.png",
      edition: 1244,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1245",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1245.png",
      edition: 1245,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1246",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1246.png",
      edition: 1246,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1247",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1247.png",
      edition: 1247,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1248",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1248.png",
      edition: 1248,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1249",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1249.png",
      edition: 1249,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #125",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/125.png",
      edition: 125,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 650,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1250",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1250.png",
      edition: 1250,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1251",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1251.png",
      edition: 1251,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1252",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1252.png",
      edition: 1252,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1253",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1253.png",
      edition: 1253,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1254",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1254.png",
      edition: 1254,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1255",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1255.png",
      edition: 1255,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1256",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1256.png",
      edition: 1256,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1257",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1257.png",
      edition: 1257,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1258",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1258.png",
      edition: 1258,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1259",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1259.png",
      edition: 1259,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #126",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/126.png",
      edition: 126,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1260",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1260.png",
      edition: 1260,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1261",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1261.png",
      edition: 1261,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1262",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1262.png",
      edition: 1262,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1263",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1263.png",
      edition: 1263,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1264",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1264.png",
      edition: 1264,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1265",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1265.png",
      edition: 1265,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1266",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1266.png",
      edition: 1266,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1267",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1267.png",
      edition: 1267,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1268",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1268.png",
      edition: 1268,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1269",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1269.png",
      edition: 1269,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #127",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/127.png",
      edition: 127,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1270",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1270.png",
      edition: 1270,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1271",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1271.png",
      edition: 1271,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1272",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1272.png",
      edition: 1272,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1273",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1273.png",
      edition: 1273,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1274",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1274.png",
      edition: 1274,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1275",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1275.png",
      edition: 1275,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 600,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1276",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1276.png",
      edition: 1276,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1277",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1277.png",
      edition: 1277,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1278",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1278.png",
      edition: 1278,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1279",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1279.png",
      edition: 1279,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #128",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/128.png",
      edition: 128,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1280",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1280.png",
      edition: 1280,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1281",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1281.png",
      edition: 1281,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1282",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1282.png",
      edition: 1282,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1283",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1283.png",
      edition: 1283,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1284",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1284.png",
      edition: 1284,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1285",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1285.png",
      edition: 1285,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1286",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1286.png",
      edition: 1286,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1287",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1287.png",
      edition: 1287,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1288",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1288.png",
      edition: 1288,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1289",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1289.png",
      edition: 1289,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #129",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/129.png",
      edition: 129,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1290",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1290.png",
      edition: 1290,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1291",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1291.png",
      edition: 1291,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1292",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1292.png",
      edition: 1292,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1293",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1293.png",
      edition: 1293,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1294",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1294.png",
      edition: 1294,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 600,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1295",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1295.png",
      edition: 1295,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1296",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1296.png",
      edition: 1296,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1297",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1297.png",
      edition: 1297,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1298",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1298.png",
      edition: 1298,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1299",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1299.png",
      edition: 1299,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #13",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/13.png",
      edition: 13,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #130",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/130.png",
      edition: 130,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1300",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1300.png",
      edition: 1300,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1301",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1301.png",
      edition: 1301,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1302",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1302.png",
      edition: 1302,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1303",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1303.png",
      edition: 1303,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1304",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1304.png",
      edition: 1304,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1305",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1305.png",
      edition: 1305,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1306",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1306.png",
      edition: 1306,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1307",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1307.png",
      edition: 1307,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1308",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1308.png",
      edition: 1308,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 700,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1309",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1309.png",
      edition: 1309,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #131",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/131.png",
      edition: 131,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1310",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1310.png",
      edition: 1310,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 600,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1311",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1311.png",
      edition: 1311,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1312",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1312.png",
      edition: 1312,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1313",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1313.png",
      edition: 1313,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1314",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1314.png",
      edition: 1314,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1315",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1315.png",
      edition: 1315,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1316",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1316.png",
      edition: 1316,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1317",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1317.png",
      edition: 1317,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1318",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1318.png",
      edition: 1318,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1319",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1319.png",
      edition: 1319,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #132",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/132.png",
      edition: 132,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 600,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1320",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1320.png",
      edition: 1320,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1321",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1321.png",
      edition: 1321,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 600,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1322",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1322.png",
      edition: 1322,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1323",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1323.png",
      edition: 1323,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1324",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1324.png",
      edition: 1324,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1325",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1325.png",
      edition: 1325,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1326",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1326.png",
      edition: 1326,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 700,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1327",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1327.png",
      edition: 1327,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1328",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1328.png",
      edition: 1328,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1329",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1329.png",
      edition: 1329,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #133",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/133.png",
      edition: 133,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1330",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1330.png",
      edition: 1330,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1331",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1331.png",
      edition: 1331,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1332",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1332.png",
      edition: 1332,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1333",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1333.png",
      edition: 1333,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1334",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1334.png",
      edition: 1334,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1335",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1335.png",
      edition: 1335,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1336",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1336.png",
      edition: 1336,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1337",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1337.png",
      edition: 1337,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1338",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1338.png",
      edition: 1338,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1339",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1339.png",
      edition: 1339,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #134",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/134.png",
      edition: 134,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1340",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1340.png",
      edition: 1340,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1341",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1341.png",
      edition: 1341,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1342",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1342.png",
      edition: 1342,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1343",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1343.png",
      edition: 1343,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1344",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1344.png",
      edition: 1344,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1345",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1345.png",
      edition: 1345,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1346",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1346.png",
      edition: 1346,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 750,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1347",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1347.png",
      edition: 1347,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1348",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1348.png",
      edition: 1348,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1349",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1349.png",
      edition: 1349,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #135",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/135.png",
      edition: 135,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1350",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1350.png",
      edition: 1350,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1351",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1351.png",
      edition: 1351,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1352",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1352.png",
      edition: 1352,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1353",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1353.png",
      edition: 1353,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1354",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1354.png",
      edition: 1354,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1355",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1355.png",
      edition: 1355,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1356",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1356.png",
      edition: 1356,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1357",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1357.png",
      edition: 1357,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1358",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1358.png",
      edition: 1358,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1359",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1359.png",
      edition: 1359,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #136",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/136.png",
      edition: 136,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1360",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1360.png",
      edition: 1360,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1361",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1361.png",
      edition: 1361,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1362",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1362.png",
      edition: 1362,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1363",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1363.png",
      edition: 1363,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1364",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1364.png",
      edition: 1364,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1365",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1365.png",
      edition: 1365,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1366",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1366.png",
      edition: 1366,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1367",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1367.png",
      edition: 1367,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1368",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1368.png",
      edition: 1368,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1369",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1369.png",
      edition: 1369,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #137",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/137.png",
      edition: 137,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1370",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1370.png",
      edition: 1370,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1371",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1371.png",
      edition: 1371,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1372",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1372.png",
      edition: 1372,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1373",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1373.png",
      edition: 1373,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1374",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1374.png",
      edition: 1374,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1375",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1375.png",
      edition: 1375,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1376",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1376.png",
      edition: 1376,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1377",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1377.png",
      edition: 1377,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 600,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1378",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1378.png",
      edition: 1378,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1379",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1379.png",
      edition: 1379,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #138",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/138.png",
      edition: 138,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1380",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1380.png",
      edition: 1380,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1381",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1381.png",
      edition: 1381,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1382",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1382.png",
      edition: 1382,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1383",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1383.png",
      edition: 1383,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1384",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1384.png",
      edition: 1384,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1385",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1385.png",
      edition: 1385,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1386",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1386.png",
      edition: 1386,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1387",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1387.png",
      edition: 1387,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1388",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1388.png",
      edition: 1388,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1389",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1389.png",
      edition: 1389,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #139",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/139.png",
      edition: 139,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1390",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1390.png",
      edition: 1390,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1391",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1391.png",
      edition: 1391,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1392",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1392.png",
      edition: 1392,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1393",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1393.png",
      edition: 1393,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1394",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1394.png",
      edition: 1394,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1395",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1395.png",
      edition: 1395,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1396",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1396.png",
      edition: 1396,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1397",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1397.png",
      edition: 1397,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1398",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1398.png",
      edition: 1398,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1399",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1399.png",
      edition: 1399,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #14",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/14.png",
      edition: 14,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #140",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/140.png",
      edition: 140,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1400",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1400.png",
      edition: 1400,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1401",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1401.png",
      edition: 1401,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1402",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1402.png",
      edition: 1402,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1403",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1403.png",
      edition: 1403,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1404",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1404.png",
      edition: 1404,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1405",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1405.png",
      edition: 1405,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1406",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1406.png",
      edition: 1406,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1407",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1407.png",
      edition: 1407,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1408",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1408.png",
      edition: 1408,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1409",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1409.png",
      edition: 1409,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #141",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/141.png",
      edition: 141,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1410",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1410.png",
      edition: 1410,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1411",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1411.png",
      edition: 1411,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1412",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1412.png",
      edition: 1412,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1413",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1413.png",
      edition: 1413,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1414",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1414.png",
      edition: 1414,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1415",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1415.png",
      edition: 1415,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1416",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1416.png",
      edition: 1416,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1417",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1417.png",
      edition: 1417,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1418",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1418.png",
      edition: 1418,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1419",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1419.png",
      edition: 1419,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #142",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/142.png",
      edition: 142,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1420",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1420.png",
      edition: 1420,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1421",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1421.png",
      edition: 1421,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1422",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1422.png",
      edition: 1422,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1423",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1423.png",
      edition: 1423,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1424",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1424.png",
      edition: 1424,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1425",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1425.png",
      edition: 1425,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1426",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1426.png",
      edition: 1426,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1427",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1427.png",
      edition: 1427,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1428",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1428.png",
      edition: 1428,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1429",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1429.png",
      edition: 1429,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #143",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/143.png",
      edition: 143,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1430",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1430.png",
      edition: 1430,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1431",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1431.png",
      edition: 1431,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1432",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1432.png",
      edition: 1432,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1433",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1433.png",
      edition: 1433,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1434",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1434.png",
      edition: 1434,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1435",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1435.png",
      edition: 1435,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1436",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1436.png",
      edition: 1436,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1437",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1437.png",
      edition: 1437,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1438",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1438.png",
      edition: 1438,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1439",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1439.png",
      edition: 1439,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #144",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/144.png",
      edition: 144,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1440",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1440.png",
      edition: 1440,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1441",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1441.png",
      edition: 1441,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1442",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1442.png",
      edition: 1442,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1443",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1443.png",
      edition: 1443,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1444",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1444.png",
      edition: 1444,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1445",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1445.png",
      edition: 1445,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1446",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1446.png",
      edition: 1446,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1447",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1447.png",
      edition: 1447,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1448",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1448.png",
      edition: 1448,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1449",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1449.png",
      edition: 1449,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #145",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/145.png",
      edition: 145,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1450",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1450.png",
      edition: 1450,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1451",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1451.png",
      edition: 1451,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1452",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1452.png",
      edition: 1452,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 700,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1453",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1453.png",
      edition: 1453,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1454",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1454.png",
      edition: 1454,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1455",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1455.png",
      edition: 1455,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1456",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1456.png",
      edition: 1456,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1457",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1457.png",
      edition: 1457,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1458",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1458.png",
      edition: 1458,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1459",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1459.png",
      edition: 1459,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #146",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/146.png",
      edition: 146,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 600,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1460",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1460.png",
      edition: 1460,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1461",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1461.png",
      edition: 1461,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 700,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1462",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1462.png",
      edition: 1462,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1463",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1463.png",
      edition: 1463,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 650,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1464",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1464.png",
      edition: 1464,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1465",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1465.png",
      edition: 1465,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1466",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1466.png",
      edition: 1466,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1467",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1467.png",
      edition: 1467,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1468",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1468.png",
      edition: 1468,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1469",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1469.png",
      edition: 1469,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #147",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/147.png",
      edition: 147,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1470",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1470.png",
      edition: 1470,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1471",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1471.png",
      edition: 1471,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1472",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1472.png",
      edition: 1472,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1473",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1473.png",
      edition: 1473,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1474",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1474.png",
      edition: 1474,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1475",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1475.png",
      edition: 1475,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1476",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1476.png",
      edition: 1476,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1477",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1477.png",
      edition: 1477,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1478",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1478.png",
      edition: 1478,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1479",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1479.png",
      edition: 1479,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #148",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/148.png",
      edition: 148,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1480",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1480.png",
      edition: 1480,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1481",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1481.png",
      edition: 1481,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1482",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1482.png",
      edition: 1482,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1483",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1483.png",
      edition: 1483,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1484",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1484.png",
      edition: 1484,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1485",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1485.png",
      edition: 1485,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1486",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1486.png",
      edition: 1486,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1487",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1487.png",
      edition: 1487,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1488",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1488.png",
      edition: 1488,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 600,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1489",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1489.png",
      edition: 1489,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #149",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/149.png",
      edition: 149,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1490",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1490.png",
      edition: 1490,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1491",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1491.png",
      edition: 1491,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1492",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1492.png",
      edition: 1492,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1493",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1493.png",
      edition: 1493,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1494",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1494.png",
      edition: 1494,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1495",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1495.png",
      edition: 1495,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1496",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1496.png",
      edition: 1496,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1497",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1497.png",
      edition: 1497,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1498",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1498.png",
      edition: 1498,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1499",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1499.png",
      edition: 1499,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #15",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/15.png",
      edition: 15,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #150",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/150.png",
      edition: 150,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1500",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1500.png",
      edition: 1500,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1501",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1501.png",
      edition: 1501,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1502",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1502.png",
      edition: 1502,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1503",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1503.png",
      edition: 1503,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1504",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1504.png",
      edition: 1504,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1505",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1505.png",
      edition: 1505,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1506",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1506.png",
      edition: 1506,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1507",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1507.png",
      edition: 1507,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1508",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1508.png",
      edition: 1508,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1509",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1509.png",
      edition: 1509,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #151",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/151.png",
      edition: 151,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1510",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1510.png",
      edition: 1510,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1511",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1511.png",
      edition: 1511,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1512",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1512.png",
      edition: 1512,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1513",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1513.png",
      edition: 1513,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1514",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1514.png",
      edition: 1514,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1515",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1515.png",
      edition: 1515,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1516",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1516.png",
      edition: 1516,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1517",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1517.png",
      edition: 1517,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1518",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1518.png",
      edition: 1518,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1519",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1519.png",
      edition: 1519,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #152",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/152.png",
      edition: 152,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1520",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1520.png",
      edition: 1520,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 650,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1521",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1521.png",
      edition: 1521,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1522",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1522.png",
      edition: 1522,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1523",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1523.png",
      edition: 1523,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1524",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1524.png",
      edition: 1524,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1525",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1525.png",
      edition: 1525,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1526",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1526.png",
      edition: 1526,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1527",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1527.png",
      edition: 1527,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 650,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1528",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1528.png",
      edition: 1528,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1529",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1529.png",
      edition: 1529,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #153",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/153.png",
      edition: 153,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1530",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1530.png",
      edition: 1530,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1531",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1531.png",
      edition: 1531,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1532",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1532.png",
      edition: 1532,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1533",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1533.png",
      edition: 1533,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1534",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1534.png",
      edition: 1534,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1535",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1535.png",
      edition: 1535,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1536",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1536.png",
      edition: 1536,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 650,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1537",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1537.png",
      edition: 1537,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1538",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1538.png",
      edition: 1538,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1539",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1539.png",
      edition: 1539,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #154",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/154.png",
      edition: 154,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1540",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1540.png",
      edition: 1540,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1541",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1541.png",
      edition: 1541,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1542",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1542.png",
      edition: 1542,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1543",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1543.png",
      edition: 1543,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1544",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1544.png",
      edition: 1544,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1545",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1545.png",
      edition: 1545,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1546",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1546.png",
      edition: 1546,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1547",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1547.png",
      edition: 1547,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1548",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1548.png",
      edition: 1548,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1549",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1549.png",
      edition: 1549,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #155",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/155.png",
      edition: 155,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1550",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1550.png",
      edition: 1550,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1551",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1551.png",
      edition: 1551,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1552",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1552.png",
      edition: 1552,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1553",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1553.png",
      edition: 1553,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1554",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1554.png",
      edition: 1554,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1555",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1555.png",
      edition: 1555,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1556",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1556.png",
      edition: 1556,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1557",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1557.png",
      edition: 1557,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1558",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1558.png",
      edition: 1558,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1559",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1559.png",
      edition: 1559,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #156",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/156.png",
      edition: 156,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1560",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1560.png",
      edition: 1560,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1561",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1561.png",
      edition: 1561,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1562",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1562.png",
      edition: 1562,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1563",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1563.png",
      edition: 1563,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1564",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1564.png",
      edition: 1564,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1565",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1565.png",
      edition: 1565,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1566",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1566.png",
      edition: 1566,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1567",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1567.png",
      edition: 1567,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1568",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1568.png",
      edition: 1568,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1569",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1569.png",
      edition: 1569,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #157",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/157.png",
      edition: 157,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1570",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1570.png",
      edition: 1570,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1571",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1571.png",
      edition: 1571,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1572",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1572.png",
      edition: 1572,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1573",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1573.png",
      edition: 1573,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1574",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1574.png",
      edition: 1574,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1575",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1575.png",
      edition: 1575,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1576",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1576.png",
      edition: 1576,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1577",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1577.png",
      edition: 1577,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1578",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1578.png",
      edition: 1578,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1579",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1579.png",
      edition: 1579,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #158",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/158.png",
      edition: 158,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1580",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1580.png",
      edition: 1580,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1581",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1581.png",
      edition: 1581,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1582",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1582.png",
      edition: 1582,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1583",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1583.png",
      edition: 1583,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1584",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1584.png",
      edition: 1584,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1585",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1585.png",
      edition: 1585,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1586",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1586.png",
      edition: 1586,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1587",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1587.png",
      edition: 1587,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1588",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1588.png",
      edition: 1588,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1589",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1589.png",
      edition: 1589,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #159",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/159.png",
      edition: 159,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1590",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1590.png",
      edition: 1590,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1591",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1591.png",
      edition: 1591,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1592",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1592.png",
      edition: 1592,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1593",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1593.png",
      edition: 1593,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1594",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1594.png",
      edition: 1594,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1595",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1595.png",
      edition: 1595,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1596",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1596.png",
      edition: 1596,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1597",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1597.png",
      edition: 1597,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1598",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1598.png",
      edition: 1598,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1599",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1599.png",
      edition: 1599,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #16",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/16.png",
      edition: 16,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #160",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/160.png",
      edition: 160,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1600",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1600.png",
      edition: 1600,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1601",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1601.png",
      edition: 1601,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1602",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1602.png",
      edition: 1602,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1603",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1603.png",
      edition: 1603,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1604",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1604.png",
      edition: 1604,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1605",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1605.png",
      edition: 1605,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1606",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1606.png",
      edition: 1606,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1607",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1607.png",
      edition: 1607,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1608",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1608.png",
      edition: 1608,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1609",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1609.png",
      edition: 1609,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #161",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/161.png",
      edition: 161,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1610",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1610.png",
      edition: 1610,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1611",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1611.png",
      edition: 1611,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1612",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1612.png",
      edition: 1612,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1613",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1613.png",
      edition: 1613,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1614",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1614.png",
      edition: 1614,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1615",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1615.png",
      edition: 1615,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1616",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1616.png",
      edition: 1616,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1617",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1617.png",
      edition: 1617,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1618",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1618.png",
      edition: 1618,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1619",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1619.png",
      edition: 1619,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 650,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #162",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/162.png",
      edition: 162,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1620",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1620.png",
      edition: 1620,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1621",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1621.png",
      edition: 1621,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1622",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1622.png",
      edition: 1622,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1623",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1623.png",
      edition: 1623,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1624",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1624.png",
      edition: 1624,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1625",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1625.png",
      edition: 1625,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1626",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1626.png",
      edition: 1626,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1627",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1627.png",
      edition: 1627,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1628",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1628.png",
      edition: 1628,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1629",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1629.png",
      edition: 1629,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #163",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/163.png",
      edition: 163,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1630",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1630.png",
      edition: 1630,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1631",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1631.png",
      edition: 1631,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1632",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1632.png",
      edition: 1632,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1633",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1633.png",
      edition: 1633,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1634",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1634.png",
      edition: 1634,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1635",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1635.png",
      edition: 1635,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1636",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1636.png",
      edition: 1636,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1637",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1637.png",
      edition: 1637,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1638",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1638.png",
      edition: 1638,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1639",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1639.png",
      edition: 1639,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #164",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/164.png",
      edition: 164,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1640",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1640.png",
      edition: 1640,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1641",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1641.png",
      edition: 1641,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1642",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1642.png",
      edition: 1642,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1643",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1643.png",
      edition: 1643,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1644",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1644.png",
      edition: 1644,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1645",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1645.png",
      edition: 1645,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1646",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1646.png",
      edition: 1646,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1647",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1647.png",
      edition: 1647,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 600,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1648",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1648.png",
      edition: 1648,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1649",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1649.png",
      edition: 1649,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #165",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/165.png",
      edition: 165,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1650",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1650.png",
      edition: 1650,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1651",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1651.png",
      edition: 1651,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1652",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1652.png",
      edition: 1652,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1653",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1653.png",
      edition: 1653,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1654",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1654.png",
      edition: 1654,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1655",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1655.png",
      edition: 1655,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1656",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1656.png",
      edition: 1656,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1657",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1657.png",
      edition: 1657,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1658",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1658.png",
      edition: 1658,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1659",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1659.png",
      edition: 1659,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 750,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #166",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/166.png",
      edition: 166,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1660",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1660.png",
      edition: 1660,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1661",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1661.png",
      edition: 1661,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1662",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1662.png",
      edition: 1662,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1663",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1663.png",
      edition: 1663,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1664",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1664.png",
      edition: 1664,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1665",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1665.png",
      edition: 1665,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1666",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1666.png",
      edition: 1666,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1667",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1667.png",
      edition: 1667,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1668",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1668.png",
      edition: 1668,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1669",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1669.png",
      edition: 1669,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #167",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/167.png",
      edition: 167,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1670",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1670.png",
      edition: 1670,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1671",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1671.png",
      edition: 1671,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1672",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1672.png",
      edition: 1672,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1673",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1673.png",
      edition: 1673,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1674",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1674.png",
      edition: 1674,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1675",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1675.png",
      edition: 1675,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1676",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1676.png",
      edition: 1676,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1677",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1677.png",
      edition: 1677,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1678",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1678.png",
      edition: 1678,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1679",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1679.png",
      edition: 1679,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #168",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/168.png",
      edition: 168,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1680",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1680.png",
      edition: 1680,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1681",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1681.png",
      edition: 1681,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1682",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1682.png",
      edition: 1682,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1683",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1683.png",
      edition: 1683,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1684",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1684.png",
      edition: 1684,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1685",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1685.png",
      edition: 1685,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1686",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1686.png",
      edition: 1686,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1687",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1687.png",
      edition: 1687,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1688",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1688.png",
      edition: 1688,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1689",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1689.png",
      edition: 1689,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #169",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/169.png",
      edition: 169,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1690",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1690.png",
      edition: 1690,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 600,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1691",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1691.png",
      edition: 1691,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1692",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1692.png",
      edition: 1692,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1693",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1693.png",
      edition: 1693,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1694",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1694.png",
      edition: 1694,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 600,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1695",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1695.png",
      edition: 1695,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1696",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1696.png",
      edition: 1696,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1697",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1697.png",
      edition: 1697,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1698",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1698.png",
      edition: 1698,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1699",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1699.png",
      edition: 1699,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #17",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/17.png",
      edition: 17,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #170",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/170.png",
      edition: 170,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1700",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1700.png",
      edition: 1700,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1701",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1701.png",
      edition: 1701,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1702",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1702.png",
      edition: 1702,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1703",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1703.png",
      edition: 1703,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1704",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1704.png",
      edition: 1704,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1705",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1705.png",
      edition: 1705,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1706",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1706.png",
      edition: 1706,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1707",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1707.png",
      edition: 1707,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1708",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1708.png",
      edition: 1708,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1709",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1709.png",
      edition: 1709,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #171",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/171.png",
      edition: 171,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1710",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1710.png",
      edition: 1710,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1711",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1711.png",
      edition: 1711,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1712",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1712.png",
      edition: 1712,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1713",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1713.png",
      edition: 1713,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1714",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1714.png",
      edition: 1714,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1715",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1715.png",
      edition: 1715,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1716",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1716.png",
      edition: 1716,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1717",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1717.png",
      edition: 1717,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1718",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1718.png",
      edition: 1718,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1719",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1719.png",
      edition: 1719,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #172",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/172.png",
      edition: 172,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1720",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1720.png",
      edition: 1720,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1721",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1721.png",
      edition: 1721,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1722",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1722.png",
      edition: 1722,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1723",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1723.png",
      edition: 1723,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1724",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1724.png",
      edition: 1724,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1725",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1725.png",
      edition: 1725,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1726",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1726.png",
      edition: 1726,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1727",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1727.png",
      edition: 1727,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1728",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1728.png",
      edition: 1728,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1729",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1729.png",
      edition: 1729,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #173",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/173.png",
      edition: 173,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1730",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1730.png",
      edition: 1730,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1731",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1731.png",
      edition: 1731,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1732",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1732.png",
      edition: 1732,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1733",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1733.png",
      edition: 1733,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1734",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1734.png",
      edition: 1734,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1735",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1735.png",
      edition: 1735,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1736",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1736.png",
      edition: 1736,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1737",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1737.png",
      edition: 1737,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1738",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1738.png",
      edition: 1738,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1739",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1739.png",
      edition: 1739,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #174",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/174.png",
      edition: 174,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1740",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1740.png",
      edition: 1740,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1741",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1741.png",
      edition: 1741,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1742",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1742.png",
      edition: 1742,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1743",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1743.png",
      edition: 1743,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1744",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1744.png",
      edition: 1744,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1745",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1745.png",
      edition: 1745,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1746",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1746.png",
      edition: 1746,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1747",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1747.png",
      edition: 1747,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1748",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1748.png",
      edition: 1748,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1749",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1749.png",
      edition: 1749,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #175",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/175.png",
      edition: 175,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1750",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1750.png",
      edition: 1750,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1751",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1751.png",
      edition: 1751,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1752",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1752.png",
      edition: 1752,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1753",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1753.png",
      edition: 1753,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1754",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1754.png",
      edition: 1754,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1755",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1755.png",
      edition: 1755,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1756",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1756.png",
      edition: 1756,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1757",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1757.png",
      edition: 1757,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1758",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1758.png",
      edition: 1758,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1759",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1759.png",
      edition: 1759,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #176",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/176.png",
      edition: 176,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1760",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1760.png",
      edition: 1760,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1761",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1761.png",
      edition: 1761,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1762",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1762.png",
      edition: 1762,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1763",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1763.png",
      edition: 1763,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1764",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1764.png",
      edition: 1764,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1765",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1765.png",
      edition: 1765,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1766",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1766.png",
      edition: 1766,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1767",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1767.png",
      edition: 1767,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1768",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1768.png",
      edition: 1768,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1769",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1769.png",
      edition: 1769,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #177",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/177.png",
      edition: 177,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1770",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1770.png",
      edition: 1770,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1771",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1771.png",
      edition: 1771,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1772",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1772.png",
      edition: 1772,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 650,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1773",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1773.png",
      edition: 1773,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1774",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1774.png",
      edition: 1774,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 650,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1775",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1775.png",
      edition: 1775,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1776",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1776.png",
      edition: 1776,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1777",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1777.png",
      edition: 1777,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1778",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1778.png",
      edition: 1778,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1779",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1779.png",
      edition: 1779,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #178",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/178.png",
      edition: 178,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1780",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1780.png",
      edition: 1780,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1781",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1781.png",
      edition: 1781,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1782",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1782.png",
      edition: 1782,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1783",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1783.png",
      edition: 1783,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1784",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1784.png",
      edition: 1784,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1785",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1785.png",
      edition: 1785,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1786",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1786.png",
      edition: 1786,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1787",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1787.png",
      edition: 1787,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1788",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1788.png",
      edition: 1788,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1789",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1789.png",
      edition: 1789,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #179",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/179.png",
      edition: 179,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1790",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1790.png",
      edition: 1790,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1791",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1791.png",
      edition: 1791,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1792",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1792.png",
      edition: 1792,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1793",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1793.png",
      edition: 1793,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1794",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1794.png",
      edition: 1794,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1795",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1795.png",
      edition: 1795,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1796",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1796.png",
      edition: 1796,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1797",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1797.png",
      edition: 1797,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1798",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1798.png",
      edition: 1798,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1799",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1799.png",
      edition: 1799,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #18",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/18.png",
      edition: 18,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #180",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/180.png",
      edition: 180,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1800",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1800.png",
      edition: 1800,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1801",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1801.png",
      edition: 1801,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1802",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1802.png",
      edition: 1802,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1803",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1803.png",
      edition: 1803,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1804",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1804.png",
      edition: 1804,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1805",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1805.png",
      edition: 1805,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1806",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1806.png",
      edition: 1806,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1807",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1807.png",
      edition: 1807,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1808",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1808.png",
      edition: 1808,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1809",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1809.png",
      edition: 1809,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #181",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/181.png",
      edition: 181,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1810",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1810.png",
      edition: 1810,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1811",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1811.png",
      edition: 1811,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1812",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1812.png",
      edition: 1812,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 700,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1813",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1813.png",
      edition: 1813,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1814",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1814.png",
      edition: 1814,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1815",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1815.png",
      edition: 1815,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1816",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1816.png",
      edition: 1816,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1817",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1817.png",
      edition: 1817,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1818",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1818.png",
      edition: 1818,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1819",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1819.png",
      edition: 1819,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #182",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/182.png",
      edition: 182,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 700,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1820",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1820.png",
      edition: 1820,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1821",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1821.png",
      edition: 1821,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1822",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1822.png",
      edition: 1822,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1823",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1823.png",
      edition: 1823,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1824",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1824.png",
      edition: 1824,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1825",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1825.png",
      edition: 1825,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 650,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1826",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1826.png",
      edition: 1826,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1827",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1827.png",
      edition: 1827,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1828",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1828.png",
      edition: 1828,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1829",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1829.png",
      edition: 1829,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #183",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/183.png",
      edition: 183,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1830",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1830.png",
      edition: 1830,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1831",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1831.png",
      edition: 1831,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1832",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1832.png",
      edition: 1832,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1833",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1833.png",
      edition: 1833,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1834",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1834.png",
      edition: 1834,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1835",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1835.png",
      edition: 1835,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1836",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1836.png",
      edition: 1836,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1837",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1837.png",
      edition: 1837,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1838",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1838.png",
      edition: 1838,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1839",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1839.png",
      edition: 1839,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #184",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/184.png",
      edition: 184,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1840",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1840.png",
      edition: 1840,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1841",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1841.png",
      edition: 1841,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1842",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1842.png",
      edition: 1842,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1843",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1843.png",
      edition: 1843,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1844",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1844.png",
      edition: 1844,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1845",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1845.png",
      edition: 1845,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1846",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1846.png",
      edition: 1846,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1847",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1847.png",
      edition: 1847,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1848",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1848.png",
      edition: 1848,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1849",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1849.png",
      edition: 1849,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #185",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/185.png",
      edition: 185,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1850",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1850.png",
      edition: 1850,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1851",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1851.png",
      edition: 1851,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1852",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1852.png",
      edition: 1852,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1853",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1853.png",
      edition: 1853,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1854",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1854.png",
      edition: 1854,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1855",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1855.png",
      edition: 1855,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1856",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1856.png",
      edition: 1856,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1857",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1857.png",
      edition: 1857,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1858",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1858.png",
      edition: 1858,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1859",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1859.png",
      edition: 1859,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #186",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/186.png",
      edition: 186,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1860",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1860.png",
      edition: 1860,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1861",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1861.png",
      edition: 1861,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1862",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1862.png",
      edition: 1862,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1863",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1863.png",
      edition: 1863,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1864",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1864.png",
      edition: 1864,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1865",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1865.png",
      edition: 1865,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1866",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1866.png",
      edition: 1866,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1867",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1867.png",
      edition: 1867,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1868",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1868.png",
      edition: 1868,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1869",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1869.png",
      edition: 1869,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #187",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/187.png",
      edition: 187,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1870",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1870.png",
      edition: 1870,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1871",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1871.png",
      edition: 1871,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1872",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1872.png",
      edition: 1872,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1873",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1873.png",
      edition: 1873,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1874",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1874.png",
      edition: 1874,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1875",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1875.png",
      edition: 1875,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1876",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1876.png",
      edition: 1876,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1877",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1877.png",
      edition: 1877,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1878",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1878.png",
      edition: 1878,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1879",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1879.png",
      edition: 1879,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 600,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #188",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/188.png",
      edition: 188,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1880",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1880.png",
      edition: 1880,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1881",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1881.png",
      edition: 1881,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1882",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1882.png",
      edition: 1882,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1883",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1883.png",
      edition: 1883,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1884",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1884.png",
      edition: 1884,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1885",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1885.png",
      edition: 1885,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1886",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1886.png",
      edition: 1886,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1887",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1887.png",
      edition: 1887,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1888",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1888.png",
      edition: 1888,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1889",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1889.png",
      edition: 1889,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #189",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/189.png",
      edition: 189,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1890",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1890.png",
      edition: 1890,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1891",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1891.png",
      edition: 1891,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1892",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1892.png",
      edition: 1892,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1893",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1893.png",
      edition: 1893,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1894",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1894.png",
      edition: 1894,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1895",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1895.png",
      edition: 1895,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1896",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1896.png",
      edition: 1896,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1897",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1897.png",
      edition: 1897,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1898",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1898.png",
      edition: 1898,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1899",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1899.png",
      edition: 1899,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #19",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/19.png",
      edition: 19,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #190",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/190.png",
      edition: 190,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 650,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1900",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1900.png",
      edition: 1900,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1901",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1901.png",
      edition: 1901,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1902",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1902.png",
      edition: 1902,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1903",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1903.png",
      edition: 1903,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1904",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1904.png",
      edition: 1904,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1905",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1905.png",
      edition: 1905,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1906",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1906.png",
      edition: 1906,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1907",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1907.png",
      edition: 1907,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1908",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1908.png",
      edition: 1908,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1909",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1909.png",
      edition: 1909,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #191",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/191.png",
      edition: 191,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1910",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1910.png",
      edition: 1910,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1911",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1911.png",
      edition: 1911,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1912",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1912.png",
      edition: 1912,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1913",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1913.png",
      edition: 1913,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1914",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1914.png",
      edition: 1914,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1915",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1915.png",
      edition: 1915,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1916",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1916.png",
      edition: 1916,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1917",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1917.png",
      edition: 1917,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1918",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1918.png",
      edition: 1918,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1919",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1919.png",
      edition: 1919,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #192",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/192.png",
      edition: 192,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1920",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1920.png",
      edition: 1920,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1921",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1921.png",
      edition: 1921,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1922",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1922.png",
      edition: 1922,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1923",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1923.png",
      edition: 1923,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1924",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1924.png",
      edition: 1924,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1925",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1925.png",
      edition: 1925,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1926",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1926.png",
      edition: 1926,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1927",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1927.png",
      edition: 1927,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1928",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1928.png",
      edition: 1928,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1929",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1929.png",
      edition: 1929,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #193",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/193.png",
      edition: 193,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1930",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1930.png",
      edition: 1930,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1931",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1931.png",
      edition: 1931,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1932",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1932.png",
      edition: 1932,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1933",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1933.png",
      edition: 1933,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1934",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1934.png",
      edition: 1934,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1935",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1935.png",
      edition: 1935,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1936",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1936.png",
      edition: 1936,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1937",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1937.png",
      edition: 1937,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1938",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1938.png",
      edition: 1938,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1939",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1939.png",
      edition: 1939,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #194",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/194.png",
      edition: 194,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1940",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1940.png",
      edition: 1940,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1941",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1941.png",
      edition: 1941,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1942",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1942.png",
      edition: 1942,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1943",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1943.png",
      edition: 1943,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1944",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1944.png",
      edition: 1944,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1945",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1945.png",
      edition: 1945,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1946",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1946.png",
      edition: 1946,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1947",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1947.png",
      edition: 1947,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1948",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1948.png",
      edition: 1948,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1949",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1949.png",
      edition: 1949,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #195",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/195.png",
      edition: 195,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1950",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1950.png",
      edition: 1950,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1951",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1951.png",
      edition: 1951,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1952",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1952.png",
      edition: 1952,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1953",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1953.png",
      edition: 1953,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1954",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1954.png",
      edition: 1954,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1955",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1955.png",
      edition: 1955,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1956",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1956.png",
      edition: 1956,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1957",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1957.png",
      edition: 1957,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1958",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1958.png",
      edition: 1958,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1959",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1959.png",
      edition: 1959,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #196",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/196.png",
      edition: 196,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1960",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1960.png",
      edition: 1960,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1961",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1961.png",
      edition: 1961,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1962",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1962.png",
      edition: 1962,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1963",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1963.png",
      edition: 1963,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1964",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1964.png",
      edition: 1964,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1965",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1965.png",
      edition: 1965,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1966",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1966.png",
      edition: 1966,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1967",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1967.png",
      edition: 1967,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1968",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1968.png",
      edition: 1968,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 650,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1969",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1969.png",
      edition: 1969,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 650,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #197",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/197.png",
      edition: 197,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1970",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1970.png",
      edition: 1970,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1971",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1971.png",
      edition: 1971,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1972",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1972.png",
      edition: 1972,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1973",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1973.png",
      edition: 1973,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1974",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1974.png",
      edition: 1974,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1975",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1975.png",
      edition: 1975,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 650,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1976",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1976.png",
      edition: 1976,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1977",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1977.png",
      edition: 1977,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1978",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1978.png",
      edition: 1978,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1979",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1979.png",
      edition: 1979,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #198",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/198.png",
      edition: 198,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1980",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1980.png",
      edition: 1980,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1981",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1981.png",
      edition: 1981,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1982",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1982.png",
      edition: 1982,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1983",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1983.png",
      edition: 1983,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1984",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1984.png",
      edition: 1984,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1985",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1985.png",
      edition: 1985,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1986",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1986.png",
      edition: 1986,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1987",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1987.png",
      edition: 1987,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1988",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1988.png",
      edition: 1988,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1989",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1989.png",
      edition: 1989,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #199",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/199.png",
      edition: 199,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1990",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1990.png",
      edition: 1990,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1991",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1991.png",
      edition: 1991,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1992",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1992.png",
      edition: 1992,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1993",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1993.png",
      edition: 1993,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1994",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1994.png",
      edition: 1994,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1995",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1995.png",
      edition: 1995,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1996",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1996.png",
      edition: 1996,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1997",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1997.png",
      edition: 1997,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1998",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1998.png",
      edition: 1998,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #1999",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/1999.png",
      edition: 1999,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2.png",
      edition: 2,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #20",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/20.png",
      edition: 20,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #200",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/200.png",
      edition: 200,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2000",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2000.png",
      edition: 2000,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2001",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2001.png",
      edition: 2001,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2002",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2002.png",
      edition: 2002,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2003",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2003.png",
      edition: 2003,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2004",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2004.png",
      edition: 2004,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2005",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2005.png",
      edition: 2005,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2006",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2006.png",
      edition: 2006,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2007",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2007.png",
      edition: 2007,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2008",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2008.png",
      edition: 2008,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2009",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2009.png",
      edition: 2009,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #201",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/201.png",
      edition: 201,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2010",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2010.png",
      edition: 2010,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2011",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2011.png",
      edition: 2011,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2012",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2012.png",
      edition: 2012,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2013",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2013.png",
      edition: 2013,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2014",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2014.png",
      edition: 2014,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2015",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2015.png",
      edition: 2015,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2016",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2016.png",
      edition: 2016,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2017",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2017.png",
      edition: 2017,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2018",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2018.png",
      edition: 2018,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2019",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2019.png",
      edition: 2019,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #202",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/202.png",
      edition: 202,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2020",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2020.png",
      edition: 2020,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2021",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2021.png",
      edition: 2021,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2022",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2022.png",
      edition: 2022,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2023",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2023.png",
      edition: 2023,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2024",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2024.png",
      edition: 2024,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2025",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2025.png",
      edition: 2025,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2026",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2026.png",
      edition: 2026,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2027",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2027.png",
      edition: 2027,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2028",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2028.png",
      edition: 2028,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2029",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2029.png",
      edition: 2029,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #203",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/203.png",
      edition: 203,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2030",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2030.png",
      edition: 2030,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2031",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2031.png",
      edition: 2031,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2032",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2032.png",
      edition: 2032,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2033",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2033.png",
      edition: 2033,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2034",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2034.png",
      edition: 2034,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2035",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2035.png",
      edition: 2035,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2036",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2036.png",
      edition: 2036,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2037",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2037.png",
      edition: 2037,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2038",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2038.png",
      edition: 2038,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2039",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2039.png",
      edition: 2039,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #204",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/204.png",
      edition: 204,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2040",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2040.png",
      edition: 2040,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2041",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2041.png",
      edition: 2041,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2042",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2042.png",
      edition: 2042,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2043",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2043.png",
      edition: 2043,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2044",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2044.png",
      edition: 2044,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2045",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2045.png",
      edition: 2045,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2046",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2046.png",
      edition: 2046,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2047",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2047.png",
      edition: 2047,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2048",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2048.png",
      edition: 2048,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2049",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2049.png",
      edition: 2049,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #205",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/205.png",
      edition: 205,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2050",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2050.png",
      edition: 2050,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2051",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2051.png",
      edition: 2051,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2052",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2052.png",
      edition: 2052,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2053",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2053.png",
      edition: 2053,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 750,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2054",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2054.png",
      edition: 2054,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2055",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2055.png",
      edition: 2055,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2056",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2056.png",
      edition: 2056,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2057",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2057.png",
      edition: 2057,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2058",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2058.png",
      edition: 2058,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2059",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2059.png",
      edition: 2059,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #206",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/206.png",
      edition: 206,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 600,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2060",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2060.png",
      edition: 2060,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2061",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2061.png",
      edition: 2061,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2062",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2062.png",
      edition: 2062,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2063",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2063.png",
      edition: 2063,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2064",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2064.png",
      edition: 2064,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2065",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2065.png",
      edition: 2065,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2066",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2066.png",
      edition: 2066,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2067",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2067.png",
      edition: 2067,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2068",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2068.png",
      edition: 2068,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2069",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2069.png",
      edition: 2069,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #207",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/207.png",
      edition: 207,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2070",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2070.png",
      edition: 2070,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2071",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2071.png",
      edition: 2071,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2072",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2072.png",
      edition: 2072,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2073",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2073.png",
      edition: 2073,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2074",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2074.png",
      edition: 2074,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2075",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2075.png",
      edition: 2075,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2076",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2076.png",
      edition: 2076,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2077",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2077.png",
      edition: 2077,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2078",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2078.png",
      edition: 2078,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2079",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2079.png",
      edition: 2079,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #208",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/208.png",
      edition: 208,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2080",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2080.png",
      edition: 2080,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2081",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2081.png",
      edition: 2081,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2082",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2082.png",
      edition: 2082,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2083",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2083.png",
      edition: 2083,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2084",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2084.png",
      edition: 2084,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2085",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2085.png",
      edition: 2085,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2086",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2086.png",
      edition: 2086,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2087",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2087.png",
      edition: 2087,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2088",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2088.png",
      edition: 2088,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2089",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2089.png",
      edition: 2089,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #209",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/209.png",
      edition: 209,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2090",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2090.png",
      edition: 2090,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2091",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2091.png",
      edition: 2091,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2092",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2092.png",
      edition: 2092,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2093",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2093.png",
      edition: 2093,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2094",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2094.png",
      edition: 2094,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2095",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2095.png",
      edition: 2095,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2096",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2096.png",
      edition: 2096,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2097",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2097.png",
      edition: 2097,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2098",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2098.png",
      edition: 2098,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2099",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2099.png",
      edition: 2099,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #21",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/21.png",
      edition: 21,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #210",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/210.png",
      edition: 210,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2100",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2100.png",
      edition: 2100,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2101",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2101.png",
      edition: 2101,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2102",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2102.png",
      edition: 2102,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2103",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2103.png",
      edition: 2103,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2104",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2104.png",
      edition: 2104,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2105",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2105.png",
      edition: 2105,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2106",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2106.png",
      edition: 2106,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2107",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2107.png",
      edition: 2107,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2108",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2108.png",
      edition: 2108,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2109",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2109.png",
      edition: 2109,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #211",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/211.png",
      edition: 211,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2110",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2110.png",
      edition: 2110,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2111",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2111.png",
      edition: 2111,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 650,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2112",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2112.png",
      edition: 2112,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2113",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2113.png",
      edition: 2113,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2114",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2114.png",
      edition: 2114,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2115",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2115.png",
      edition: 2115,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2116",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2116.png",
      edition: 2116,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2117",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2117.png",
      edition: 2117,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2118",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2118.png",
      edition: 2118,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2119",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2119.png",
      edition: 2119,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #212",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/212.png",
      edition: 212,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2120",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2120.png",
      edition: 2120,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2121",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2121.png",
      edition: 2121,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2122",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2122.png",
      edition: 2122,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2123",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2123.png",
      edition: 2123,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2124",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2124.png",
      edition: 2124,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2125",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2125.png",
      edition: 2125,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2126",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2126.png",
      edition: 2126,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2127",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2127.png",
      edition: 2127,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2128",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2128.png",
      edition: 2128,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2129",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2129.png",
      edition: 2129,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #213",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/213.png",
      edition: 213,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2130",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2130.png",
      edition: 2130,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2131",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2131.png",
      edition: 2131,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2132",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2132.png",
      edition: 2132,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2133",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2133.png",
      edition: 2133,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2134",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2134.png",
      edition: 2134,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2135",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2135.png",
      edition: 2135,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2136",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2136.png",
      edition: 2136,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2137",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2137.png",
      edition: 2137,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2138",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2138.png",
      edition: 2138,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2139",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2139.png",
      edition: 2139,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #214",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/214.png",
      edition: 214,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2140",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2140.png",
      edition: 2140,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2141",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2141.png",
      edition: 2141,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2142",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2142.png",
      edition: 2142,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2143",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2143.png",
      edition: 2143,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2144",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2144.png",
      edition: 2144,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2145",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2145.png",
      edition: 2145,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2146",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2146.png",
      edition: 2146,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2147",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2147.png",
      edition: 2147,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2148",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2148.png",
      edition: 2148,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2149",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2149.png",
      edition: 2149,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #215",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/215.png",
      edition: 215,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2150",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2150.png",
      edition: 2150,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2151",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2151.png",
      edition: 2151,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2152",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2152.png",
      edition: 2152,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2153",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2153.png",
      edition: 2153,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 650,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2154",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2154.png",
      edition: 2154,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2155",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2155.png",
      edition: 2155,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2156",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2156.png",
      edition: 2156,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2157",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2157.png",
      edition: 2157,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2158",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2158.png",
      edition: 2158,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2159",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2159.png",
      edition: 2159,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #216",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/216.png",
      edition: 216,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2160",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2160.png",
      edition: 2160,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2161",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2161.png",
      edition: 2161,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2162",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2162.png",
      edition: 2162,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2163",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2163.png",
      edition: 2163,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2164",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2164.png",
      edition: 2164,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2165",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2165.png",
      edition: 2165,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2166",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2166.png",
      edition: 2166,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2167",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2167.png",
      edition: 2167,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2168",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2168.png",
      edition: 2168,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2169",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2169.png",
      edition: 2169,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #217",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/217.png",
      edition: 217,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2170",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2170.png",
      edition: 2170,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 600,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2171",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2171.png",
      edition: 2171,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2172",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2172.png",
      edition: 2172,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2173",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2173.png",
      edition: 2173,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2174",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2174.png",
      edition: 2174,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2175",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2175.png",
      edition: 2175,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2176",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2176.png",
      edition: 2176,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2177",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2177.png",
      edition: 2177,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2178",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2178.png",
      edition: 2178,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2179",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2179.png",
      edition: 2179,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #218",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/218.png",
      edition: 218,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2180",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2180.png",
      edition: 2180,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2181",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2181.png",
      edition: 2181,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2182",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2182.png",
      edition: 2182,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2183",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2183.png",
      edition: 2183,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2184",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2184.png",
      edition: 2184,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2185",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2185.png",
      edition: 2185,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2186",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2186.png",
      edition: 2186,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2187",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2187.png",
      edition: 2187,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2188",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2188.png",
      edition: 2188,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2189",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2189.png",
      edition: 2189,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #219",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/219.png",
      edition: 219,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2190",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2190.png",
      edition: 2190,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2191",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2191.png",
      edition: 2191,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2192",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2192.png",
      edition: 2192,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2193",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2193.png",
      edition: 2193,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2194",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2194.png",
      edition: 2194,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2195",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2195.png",
      edition: 2195,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2196",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2196.png",
      edition: 2196,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2197",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2197.png",
      edition: 2197,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2198",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2198.png",
      edition: 2198,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2199",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2199.png",
      edition: 2199,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #22",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/22.png",
      edition: 22,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #220",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/220.png",
      edition: 220,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2200",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2200.png",
      edition: 2200,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 700,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2201",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2201.png",
      edition: 2201,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2202",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2202.png",
      edition: 2202,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2203",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2203.png",
      edition: 2203,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2204",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2204.png",
      edition: 2204,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2205",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2205.png",
      edition: 2205,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2206",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2206.png",
      edition: 2206,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2207",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2207.png",
      edition: 2207,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2208",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2208.png",
      edition: 2208,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2209",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2209.png",
      edition: 2209,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #221",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/221.png",
      edition: 221,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2210",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2210.png",
      edition: 2210,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2211",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2211.png",
      edition: 2211,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2212",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2212.png",
      edition: 2212,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2213",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2213.png",
      edition: 2213,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2214",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2214.png",
      edition: 2214,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2215",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2215.png",
      edition: 2215,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2216",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2216.png",
      edition: 2216,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2217",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2217.png",
      edition: 2217,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2218",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2218.png",
      edition: 2218,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2219",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2219.png",
      edition: 2219,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #222",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/222.png",
      edition: 222,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2220",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2220.png",
      edition: 2220,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2221",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2221.png",
      edition: 2221,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2222",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2222.png",
      edition: 2222,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2223",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2223.png",
      edition: 2223,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 600,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2224",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2224.png",
      edition: 2224,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 600,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2225",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2225.png",
      edition: 2225,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2226",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2226.png",
      edition: 2226,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2227",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2227.png",
      edition: 2227,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2228",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2228.png",
      edition: 2228,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2229",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2229.png",
      edition: 2229,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #223",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/223.png",
      edition: 223,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2230",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2230.png",
      edition: 2230,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2231",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2231.png",
      edition: 2231,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2232",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2232.png",
      edition: 2232,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2233",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2233.png",
      edition: 2233,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2234",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2234.png",
      edition: 2234,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2235",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2235.png",
      edition: 2235,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2236",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2236.png",
      edition: 2236,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 750,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2237",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2237.png",
      edition: 2237,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2238",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2238.png",
      edition: 2238,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2239",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2239.png",
      edition: 2239,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #224",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/224.png",
      edition: 224,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2240",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2240.png",
      edition: 2240,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2241",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2241.png",
      edition: 2241,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2242",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2242.png",
      edition: 2242,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2243",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2243.png",
      edition: 2243,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2244",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2244.png",
      edition: 2244,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2245",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2245.png",
      edition: 2245,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2246",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2246.png",
      edition: 2246,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2247",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2247.png",
      edition: 2247,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2248",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2248.png",
      edition: 2248,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2249",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2249.png",
      edition: 2249,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #225",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/225.png",
      edition: 225,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2250",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2250.png",
      edition: 2250,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2251",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2251.png",
      edition: 2251,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2252",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2252.png",
      edition: 2252,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2253",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2253.png",
      edition: 2253,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2254",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2254.png",
      edition: 2254,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2255",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2255.png",
      edition: 2255,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2256",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2256.png",
      edition: 2256,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2257",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2257.png",
      edition: 2257,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2258",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2258.png",
      edition: 2258,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2259",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2259.png",
      edition: 2259,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #226",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/226.png",
      edition: 226,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2260",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2260.png",
      edition: 2260,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2261",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2261.png",
      edition: 2261,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2262",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2262.png",
      edition: 2262,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2263",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2263.png",
      edition: 2263,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2264",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2264.png",
      edition: 2264,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2265",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2265.png",
      edition: 2265,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2266",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2266.png",
      edition: 2266,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2267",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2267.png",
      edition: 2267,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2268",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2268.png",
      edition: 2268,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 600,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2269",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2269.png",
      edition: 2269,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #227",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/227.png",
      edition: 227,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2270",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2270.png",
      edition: 2270,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2271",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2271.png",
      edition: 2271,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2272",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2272.png",
      edition: 2272,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2273",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2273.png",
      edition: 2273,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2274",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2274.png",
      edition: 2274,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2275",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2275.png",
      edition: 2275,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2276",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2276.png",
      edition: 2276,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2277",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2277.png",
      edition: 2277,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2278",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2278.png",
      edition: 2278,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2279",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2279.png",
      edition: 2279,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #228",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/228.png",
      edition: 228,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2280",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2280.png",
      edition: 2280,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2281",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2281.png",
      edition: 2281,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2282",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2282.png",
      edition: 2282,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2283",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2283.png",
      edition: 2283,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2284",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2284.png",
      edition: 2284,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2285",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2285.png",
      edition: 2285,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2286",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2286.png",
      edition: 2286,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2287",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2287.png",
      edition: 2287,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2288",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2288.png",
      edition: 2288,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2289",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2289.png",
      edition: 2289,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #229",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/229.png",
      edition: 229,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2290",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2290.png",
      edition: 2290,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2291",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2291.png",
      edition: 2291,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2292",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2292.png",
      edition: 2292,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2293",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2293.png",
      edition: 2293,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2294",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2294.png",
      edition: 2294,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2295",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2295.png",
      edition: 2295,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2296",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2296.png",
      edition: 2296,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2297",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2297.png",
      edition: 2297,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2298",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2298.png",
      edition: 2298,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2299",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2299.png",
      edition: 2299,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #23",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/23.png",
      edition: 23,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #230",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/230.png",
      edition: 230,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2300",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2300.png",
      edition: 2300,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2301",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2301.png",
      edition: 2301,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2302",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2302.png",
      edition: 2302,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2303",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2303.png",
      edition: 2303,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2304",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2304.png",
      edition: 2304,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2305",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2305.png",
      edition: 2305,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2306",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2306.png",
      edition: 2306,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 700,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2307",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2307.png",
      edition: 2307,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2308",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2308.png",
      edition: 2308,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2309",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2309.png",
      edition: 2309,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #231",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/231.png",
      edition: 231,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2310",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2310.png",
      edition: 2310,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2311",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2311.png",
      edition: 2311,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2312",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2312.png",
      edition: 2312,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2313",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2313.png",
      edition: 2313,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2314",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2314.png",
      edition: 2314,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2315",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2315.png",
      edition: 2315,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2316",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2316.png",
      edition: 2316,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2317",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2317.png",
      edition: 2317,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2318",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2318.png",
      edition: 2318,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2319",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2319.png",
      edition: 2319,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #232",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/232.png",
      edition: 232,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2320",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2320.png",
      edition: 2320,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2321",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2321.png",
      edition: 2321,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2322",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2322.png",
      edition: 2322,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 750,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2323",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2323.png",
      edition: 2323,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2324",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2324.png",
      edition: 2324,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2325",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2325.png",
      edition: 2325,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2326",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2326.png",
      edition: 2326,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2327",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2327.png",
      edition: 2327,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2328",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2328.png",
      edition: 2328,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2329",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2329.png",
      edition: 2329,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #233",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/233.png",
      edition: 233,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2330",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2330.png",
      edition: 2330,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2331",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2331.png",
      edition: 2331,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2332",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2332.png",
      edition: 2332,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2333",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2333.png",
      edition: 2333,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2334",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2334.png",
      edition: 2334,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2335",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2335.png",
      edition: 2335,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2336",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2336.png",
      edition: 2336,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2337",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2337.png",
      edition: 2337,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2338",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2338.png",
      edition: 2338,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2339",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2339.png",
      edition: 2339,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #234",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/234.png",
      edition: 234,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2340",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2340.png",
      edition: 2340,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2341",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2341.png",
      edition: 2341,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2342",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2342.png",
      edition: 2342,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2343",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2343.png",
      edition: 2343,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2344",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2344.png",
      edition: 2344,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2345",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2345.png",
      edition: 2345,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 600,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2346",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2346.png",
      edition: 2346,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2347",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2347.png",
      edition: 2347,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2348",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2348.png",
      edition: 2348,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2349",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2349.png",
      edition: 2349,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #235",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/235.png",
      edition: 235,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2350",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2350.png",
      edition: 2350,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2351",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2351.png",
      edition: 2351,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2352",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2352.png",
      edition: 2352,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2353",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2353.png",
      edition: 2353,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2354",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2354.png",
      edition: 2354,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2355",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2355.png",
      edition: 2355,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2356",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2356.png",
      edition: 2356,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2357",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2357.png",
      edition: 2357,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2358",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2358.png",
      edition: 2358,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2359",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2359.png",
      edition: 2359,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #236",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/236.png",
      edition: 236,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2360",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2360.png",
      edition: 2360,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2361",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2361.png",
      edition: 2361,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2362",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2362.png",
      edition: 2362,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2363",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2363.png",
      edition: 2363,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2364",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2364.png",
      edition: 2364,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2365",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2365.png",
      edition: 2365,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2366",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2366.png",
      edition: 2366,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2367",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2367.png",
      edition: 2367,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2368",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2368.png",
      edition: 2368,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2369",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2369.png",
      edition: 2369,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #237",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/237.png",
      edition: 237,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2370",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2370.png",
      edition: 2370,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2371",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2371.png",
      edition: 2371,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2372",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2372.png",
      edition: 2372,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2373",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2373.png",
      edition: 2373,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2374",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2374.png",
      edition: 2374,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2375",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2375.png",
      edition: 2375,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2376",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2376.png",
      edition: 2376,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2377",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2377.png",
      edition: 2377,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2378",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2378.png",
      edition: 2378,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2379",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2379.png",
      edition: 2379,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #238",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/238.png",
      edition: 238,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2380",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2380.png",
      edition: 2380,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2381",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2381.png",
      edition: 2381,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2382",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2382.png",
      edition: 2382,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2383",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2383.png",
      edition: 2383,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2384",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2384.png",
      edition: 2384,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2385",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2385.png",
      edition: 2385,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2386",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2386.png",
      edition: 2386,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2387",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2387.png",
      edition: 2387,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2388",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2388.png",
      edition: 2388,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2389",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2389.png",
      edition: 2389,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #239",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/239.png",
      edition: 239,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2390",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2390.png",
      edition: 2390,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2391",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2391.png",
      edition: 2391,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2392",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2392.png",
      edition: 2392,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2393",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2393.png",
      edition: 2393,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2394",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2394.png",
      edition: 2394,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2395",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2395.png",
      edition: 2395,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2396",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2396.png",
      edition: 2396,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2397",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2397.png",
      edition: 2397,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2398",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2398.png",
      edition: 2398,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2399",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2399.png",
      edition: 2399,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #24",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/24.png",
      edition: 24,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #240",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/240.png",
      edition: 240,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2400",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2400.png",
      edition: 2400,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2401",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2401.png",
      edition: 2401,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2402",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2402.png",
      edition: 2402,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2403",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2403.png",
      edition: 2403,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2404",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2404.png",
      edition: 2404,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2405",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2405.png",
      edition: 2405,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2406",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2406.png",
      edition: 2406,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2407",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2407.png",
      edition: 2407,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2408",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2408.png",
      edition: 2408,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2409",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2409.png",
      edition: 2409,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #241",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/241.png",
      edition: 241,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2410",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2410.png",
      edition: 2410,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2411",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2411.png",
      edition: 2411,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2412",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2412.png",
      edition: 2412,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2413",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2413.png",
      edition: 2413,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2414",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2414.png",
      edition: 2414,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2415",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2415.png",
      edition: 2415,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2416",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2416.png",
      edition: 2416,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2417",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2417.png",
      edition: 2417,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2418",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2418.png",
      edition: 2418,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2419",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2419.png",
      edition: 2419,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #242",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/242.png",
      edition: 242,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 650,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2420",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2420.png",
      edition: 2420,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2421",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2421.png",
      edition: 2421,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2422",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2422.png",
      edition: 2422,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2423",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2423.png",
      edition: 2423,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2424",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2424.png",
      edition: 2424,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2425",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2425.png",
      edition: 2425,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2426",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2426.png",
      edition: 2426,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2427",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2427.png",
      edition: 2427,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2428",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2428.png",
      edition: 2428,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2429",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2429.png",
      edition: 2429,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #243",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/243.png",
      edition: 243,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2430",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2430.png",
      edition: 2430,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2431",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2431.png",
      edition: 2431,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2432",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2432.png",
      edition: 2432,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2433",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2433.png",
      edition: 2433,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2434",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2434.png",
      edition: 2434,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2435",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2435.png",
      edition: 2435,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2436",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2436.png",
      edition: 2436,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2437",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2437.png",
      edition: 2437,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2438",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2438.png",
      edition: 2438,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2439",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2439.png",
      edition: 2439,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #244",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/244.png",
      edition: 244,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2440",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2440.png",
      edition: 2440,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2441",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2441.png",
      edition: 2441,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2442",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2442.png",
      edition: 2442,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2443",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2443.png",
      edition: 2443,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2444",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2444.png",
      edition: 2444,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2445",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2445.png",
      edition: 2445,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2446",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2446.png",
      edition: 2446,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2447",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2447.png",
      edition: 2447,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2448",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2448.png",
      edition: 2448,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2449",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2449.png",
      edition: 2449,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #245",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/245.png",
      edition: 245,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2450",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2450.png",
      edition: 2450,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2451",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2451.png",
      edition: 2451,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2452",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2452.png",
      edition: 2452,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2453",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2453.png",
      edition: 2453,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2454",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2454.png",
      edition: 2454,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 650,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2455",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2455.png",
      edition: 2455,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2456",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2456.png",
      edition: 2456,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2457",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2457.png",
      edition: 2457,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2458",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2458.png",
      edition: 2458,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2459",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2459.png",
      edition: 2459,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #246",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/246.png",
      edition: 246,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2460",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2460.png",
      edition: 2460,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2461",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2461.png",
      edition: 2461,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2462",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2462.png",
      edition: 2462,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2463",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2463.png",
      edition: 2463,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2464",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2464.png",
      edition: 2464,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2465",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2465.png",
      edition: 2465,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2466",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2466.png",
      edition: 2466,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2467",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2467.png",
      edition: 2467,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2468",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2468.png",
      edition: 2468,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2469",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2469.png",
      edition: 2469,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #247",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/247.png",
      edition: 247,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2470",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2470.png",
      edition: 2470,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2471",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2471.png",
      edition: 2471,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2472",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2472.png",
      edition: 2472,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2473",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2473.png",
      edition: 2473,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2474",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2474.png",
      edition: 2474,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2475",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2475.png",
      edition: 2475,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2476",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2476.png",
      edition: 2476,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2477",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2477.png",
      edition: 2477,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2478",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2478.png",
      edition: 2478,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2479",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2479.png",
      edition: 2479,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #248",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/248.png",
      edition: 248,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2480",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2480.png",
      edition: 2480,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2481",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2481.png",
      edition: 2481,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2482",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2482.png",
      edition: 2482,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2483",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2483.png",
      edition: 2483,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2484",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2484.png",
      edition: 2484,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2485",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2485.png",
      edition: 2485,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2486",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2486.png",
      edition: 2486,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2487",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2487.png",
      edition: 2487,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2488",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2488.png",
      edition: 2488,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2489",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2489.png",
      edition: 2489,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #249",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/249.png",
      edition: 249,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2490",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2490.png",
      edition: 2490,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2491",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2491.png",
      edition: 2491,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2492",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2492.png",
      edition: 2492,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2493",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2493.png",
      edition: 2493,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2494",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2494.png",
      edition: 2494,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2495",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2495.png",
      edition: 2495,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2496",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2496.png",
      edition: 2496,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2497",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2497.png",
      edition: 2497,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 600,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2498",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2498.png",
      edition: 2498,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2499",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2499.png",
      edition: 2499,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #25",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/25.png",
      edition: 25,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #250",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/250.png",
      edition: 250,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2500",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2500.png",
      edition: 2500,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2501",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2501.png",
      edition: 2501,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2502",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2502.png",
      edition: 2502,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2503",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2503.png",
      edition: 2503,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2504",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2504.png",
      edition: 2504,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2505",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2505.png",
      edition: 2505,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2506",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2506.png",
      edition: 2506,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2507",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2507.png",
      edition: 2507,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2508",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2508.png",
      edition: 2508,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2509",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2509.png",
      edition: 2509,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #251",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/251.png",
      edition: 251,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2510",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2510.png",
      edition: 2510,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2511",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2511.png",
      edition: 2511,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2512",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2512.png",
      edition: 2512,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2513",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2513.png",
      edition: 2513,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2514",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2514.png",
      edition: 2514,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2515",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2515.png",
      edition: 2515,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2516",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2516.png",
      edition: 2516,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2517",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2517.png",
      edition: 2517,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2518",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2518.png",
      edition: 2518,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2519",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2519.png",
      edition: 2519,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #252",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/252.png",
      edition: 252,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2520",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2520.png",
      edition: 2520,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2521",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2521.png",
      edition: 2521,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2522",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2522.png",
      edition: 2522,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2523",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2523.png",
      edition: 2523,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2524",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2524.png",
      edition: 2524,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2525",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2525.png",
      edition: 2525,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2526",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2526.png",
      edition: 2526,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2527",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2527.png",
      edition: 2527,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2528",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2528.png",
      edition: 2528,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2529",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2529.png",
      edition: 2529,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #253",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/253.png",
      edition: 253,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2530",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2530.png",
      edition: 2530,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2531",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2531.png",
      edition: 2531,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2532",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2532.png",
      edition: 2532,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2533",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2533.png",
      edition: 2533,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2534",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2534.png",
      edition: 2534,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2535",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2535.png",
      edition: 2535,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2536",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2536.png",
      edition: 2536,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2537",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2537.png",
      edition: 2537,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2538",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2538.png",
      edition: 2538,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2539",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2539.png",
      edition: 2539,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #254",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/254.png",
      edition: 254,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 750,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2540",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2540.png",
      edition: 2540,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2541",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2541.png",
      edition: 2541,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2542",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2542.png",
      edition: 2542,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2543",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2543.png",
      edition: 2543,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2544",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2544.png",
      edition: 2544,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2545",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2545.png",
      edition: 2545,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2546",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2546.png",
      edition: 2546,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2547",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2547.png",
      edition: 2547,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2548",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2548.png",
      edition: 2548,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2549",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2549.png",
      edition: 2549,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #255",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/255.png",
      edition: 255,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2550",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2550.png",
      edition: 2550,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2551",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2551.png",
      edition: 2551,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2552",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2552.png",
      edition: 2552,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2553",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2553.png",
      edition: 2553,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2554",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2554.png",
      edition: 2554,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2555",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2555.png",
      edition: 2555,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2556",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2556.png",
      edition: 2556,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2557",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2557.png",
      edition: 2557,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2558",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2558.png",
      edition: 2558,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2559",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2559.png",
      edition: 2559,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #256",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/256.png",
      edition: 256,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2560",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2560.png",
      edition: 2560,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2561",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2561.png",
      edition: 2561,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2562",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2562.png",
      edition: 2562,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2563",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2563.png",
      edition: 2563,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2564",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2564.png",
      edition: 2564,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2565",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2565.png",
      edition: 2565,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2566",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2566.png",
      edition: 2566,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2567",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2567.png",
      edition: 2567,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2568",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2568.png",
      edition: 2568,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2569",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2569.png",
      edition: 2569,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #257",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/257.png",
      edition: 257,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2570",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2570.png",
      edition: 2570,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2571",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2571.png",
      edition: 2571,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2572",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2572.png",
      edition: 2572,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2573",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2573.png",
      edition: 2573,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2574",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2574.png",
      edition: 2574,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2575",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2575.png",
      edition: 2575,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2576",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2576.png",
      edition: 2576,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2577",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2577.png",
      edition: 2577,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2578",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2578.png",
      edition: 2578,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2579",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2579.png",
      edition: 2579,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #258",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/258.png",
      edition: 258,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 600,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2580",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2580.png",
      edition: 2580,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2581",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2581.png",
      edition: 2581,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2582",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2582.png",
      edition: 2582,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2583",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2583.png",
      edition: 2583,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2584",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2584.png",
      edition: 2584,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2585",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2585.png",
      edition: 2585,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2586",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2586.png",
      edition: 2586,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2587",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2587.png",
      edition: 2587,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2588",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2588.png",
      edition: 2588,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2589",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2589.png",
      edition: 2589,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #259",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/259.png",
      edition: 259,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2590",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2590.png",
      edition: 2590,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2591",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2591.png",
      edition: 2591,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2592",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2592.png",
      edition: 2592,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2593",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2593.png",
      edition: 2593,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2594",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2594.png",
      edition: 2594,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2595",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2595.png",
      edition: 2595,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2596",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2596.png",
      edition: 2596,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2597",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2597.png",
      edition: 2597,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2598",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2598.png",
      edition: 2598,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2599",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2599.png",
      edition: 2599,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #26",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/26.png",
      edition: 26,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #260",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/260.png",
      edition: 260,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2600",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2600.png",
      edition: 2600,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2601",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2601.png",
      edition: 2601,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2602",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2602.png",
      edition: 2602,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2603",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2603.png",
      edition: 2603,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2604",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2604.png",
      edition: 2604,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2605",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2605.png",
      edition: 2605,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2606",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2606.png",
      edition: 2606,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2607",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2607.png",
      edition: 2607,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 650,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2608",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2608.png",
      edition: 2608,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2609",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2609.png",
      edition: 2609,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #261",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/261.png",
      edition: 261,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2610",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2610.png",
      edition: 2610,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2611",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2611.png",
      edition: 2611,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2612",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2612.png",
      edition: 2612,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2613",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2613.png",
      edition: 2613,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2614",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2614.png",
      edition: 2614,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2615",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2615.png",
      edition: 2615,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2616",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2616.png",
      edition: 2616,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2617",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2617.png",
      edition: 2617,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2618",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2618.png",
      edition: 2618,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2619",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2619.png",
      edition: 2619,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #262",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/262.png",
      edition: 262,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2620",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2620.png",
      edition: 2620,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2621",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2621.png",
      edition: 2621,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2622",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2622.png",
      edition: 2622,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2623",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2623.png",
      edition: 2623,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2624",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2624.png",
      edition: 2624,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 600,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2625",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2625.png",
      edition: 2625,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2626",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2626.png",
      edition: 2626,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2627",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2627.png",
      edition: 2627,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2628",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2628.png",
      edition: 2628,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2629",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2629.png",
      edition: 2629,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #263",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/263.png",
      edition: 263,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2630",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2630.png",
      edition: 2630,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2631",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2631.png",
      edition: 2631,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2632",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2632.png",
      edition: 2632,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2633",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2633.png",
      edition: 2633,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2634",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2634.png",
      edition: 2634,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2635",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2635.png",
      edition: 2635,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2636",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2636.png",
      edition: 2636,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2637",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2637.png",
      edition: 2637,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2638",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2638.png",
      edition: 2638,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2639",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2639.png",
      edition: 2639,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #264",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/264.png",
      edition: 264,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2640",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2640.png",
      edition: 2640,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2641",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2641.png",
      edition: 2641,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2642",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2642.png",
      edition: 2642,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2643",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2643.png",
      edition: 2643,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2644",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2644.png",
      edition: 2644,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2645",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2645.png",
      edition: 2645,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2646",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2646.png",
      edition: 2646,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2647",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2647.png",
      edition: 2647,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2648",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2648.png",
      edition: 2648,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2649",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2649.png",
      edition: 2649,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #265",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/265.png",
      edition: 265,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2650",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2650.png",
      edition: 2650,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2651",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2651.png",
      edition: 2651,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2652",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2652.png",
      edition: 2652,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2653",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2653.png",
      edition: 2653,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 700,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2654",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2654.png",
      edition: 2654,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2655",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2655.png",
      edition: 2655,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2656",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2656.png",
      edition: 2656,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2657",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2657.png",
      edition: 2657,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2658",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2658.png",
      edition: 2658,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2659",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2659.png",
      edition: 2659,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #266",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/266.png",
      edition: 266,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2660",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2660.png",
      edition: 2660,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2661",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2661.png",
      edition: 2661,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2662",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2662.png",
      edition: 2662,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2663",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2663.png",
      edition: 2663,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2664",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2664.png",
      edition: 2664,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2665",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2665.png",
      edition: 2665,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2666",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2666.png",
      edition: 2666,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2667",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2667.png",
      edition: 2667,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2668",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2668.png",
      edition: 2668,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2669",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2669.png",
      edition: 2669,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #267",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/267.png",
      edition: 267,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2670",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2670.png",
      edition: 2670,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2671",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2671.png",
      edition: 2671,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 600,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2672",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2672.png",
      edition: 2672,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2673",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2673.png",
      edition: 2673,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 750,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2674",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2674.png",
      edition: 2674,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2675",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2675.png",
      edition: 2675,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2676",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2676.png",
      edition: 2676,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2677",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2677.png",
      edition: 2677,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2678",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2678.png",
      edition: 2678,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2679",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2679.png",
      edition: 2679,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #268",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/268.png",
      edition: 268,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2680",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2680.png",
      edition: 2680,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2681",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2681.png",
      edition: 2681,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2682",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2682.png",
      edition: 2682,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2683",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2683.png",
      edition: 2683,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2684",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2684.png",
      edition: 2684,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2685",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2685.png",
      edition: 2685,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2686",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2686.png",
      edition: 2686,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2687",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2687.png",
      edition: 2687,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2688",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2688.png",
      edition: 2688,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2689",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2689.png",
      edition: 2689,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #269",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/269.png",
      edition: 269,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2690",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2690.png",
      edition: 2690,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2691",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2691.png",
      edition: 2691,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2692",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2692.png",
      edition: 2692,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2693",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2693.png",
      edition: 2693,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2694",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2694.png",
      edition: 2694,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2695",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2695.png",
      edition: 2695,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2696",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2696.png",
      edition: 2696,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2697",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2697.png",
      edition: 2697,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2698",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2698.png",
      edition: 2698,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 600,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2699",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2699.png",
      edition: 2699,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #27",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/27.png",
      edition: 27,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #270",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/270.png",
      edition: 270,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2700",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2700.png",
      edition: 2700,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2701",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2701.png",
      edition: 2701,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2702",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2702.png",
      edition: 2702,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2703",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2703.png",
      edition: 2703,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2704",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2704.png",
      edition: 2704,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2705",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2705.png",
      edition: 2705,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2706",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2706.png",
      edition: 2706,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2707",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2707.png",
      edition: 2707,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2708",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2708.png",
      edition: 2708,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2709",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2709.png",
      edition: 2709,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #271",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/271.png",
      edition: 271,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2710",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2710.png",
      edition: 2710,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2711",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2711.png",
      edition: 2711,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2712",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2712.png",
      edition: 2712,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2713",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2713.png",
      edition: 2713,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2714",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2714.png",
      edition: 2714,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2715",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2715.png",
      edition: 2715,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2716",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2716.png",
      edition: 2716,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2717",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2717.png",
      edition: 2717,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2718",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2718.png",
      edition: 2718,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2719",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2719.png",
      edition: 2719,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #272",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/272.png",
      edition: 272,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2720",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2720.png",
      edition: 2720,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2721",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2721.png",
      edition: 2721,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2722",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2722.png",
      edition: 2722,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2723",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2723.png",
      edition: 2723,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2724",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2724.png",
      edition: 2724,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2725",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2725.png",
      edition: 2725,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2726",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2726.png",
      edition: 2726,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2727",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2727.png",
      edition: 2727,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2728",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2728.png",
      edition: 2728,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2729",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2729.png",
      edition: 2729,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #273",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/273.png",
      edition: 273,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2730",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2730.png",
      edition: 2730,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2731",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2731.png",
      edition: 2731,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2732",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2732.png",
      edition: 2732,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 600,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2733",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2733.png",
      edition: 2733,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2734",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2734.png",
      edition: 2734,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2735",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2735.png",
      edition: 2735,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2736",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2736.png",
      edition: 2736,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2737",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2737.png",
      edition: 2737,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2738",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2738.png",
      edition: 2738,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2739",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2739.png",
      edition: 2739,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #274",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/274.png",
      edition: 274,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2740",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2740.png",
      edition: 2740,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2741",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2741.png",
      edition: 2741,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2742",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2742.png",
      edition: 2742,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2743",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2743.png",
      edition: 2743,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 650,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2744",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2744.png",
      edition: 2744,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2745",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2745.png",
      edition: 2745,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2746",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2746.png",
      edition: 2746,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2747",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2747.png",
      edition: 2747,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2748",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2748.png",
      edition: 2748,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2749",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2749.png",
      edition: 2749,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #275",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/275.png",
      edition: 275,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2750",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2750.png",
      edition: 2750,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2751",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2751.png",
      edition: 2751,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2752",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2752.png",
      edition: 2752,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2753",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2753.png",
      edition: 2753,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2754",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2754.png",
      edition: 2754,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2755",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2755.png",
      edition: 2755,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2756",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2756.png",
      edition: 2756,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2757",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2757.png",
      edition: 2757,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2758",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2758.png",
      edition: 2758,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2759",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2759.png",
      edition: 2759,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #276",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/276.png",
      edition: 276,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2760",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2760.png",
      edition: 2760,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2761",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2761.png",
      edition: 2761,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2762",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2762.png",
      edition: 2762,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2763",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2763.png",
      edition: 2763,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2764",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2764.png",
      edition: 2764,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2765",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2765.png",
      edition: 2765,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2766",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2766.png",
      edition: 2766,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2767",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2767.png",
      edition: 2767,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2768",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2768.png",
      edition: 2768,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2769",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2769.png",
      edition: 2769,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #277",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/277.png",
      edition: 277,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2770",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2770.png",
      edition: 2770,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2771",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2771.png",
      edition: 2771,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2772",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2772.png",
      edition: 2772,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2773",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2773.png",
      edition: 2773,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2774",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2774.png",
      edition: 2774,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2775",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2775.png",
      edition: 2775,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2776",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2776.png",
      edition: 2776,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2777",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2777.png",
      edition: 2777,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2778",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2778.png",
      edition: 2778,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2779",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2779.png",
      edition: 2779,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #278",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/278.png",
      edition: 278,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2780",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2780.png",
      edition: 2780,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2781",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2781.png",
      edition: 2781,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2782",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2782.png",
      edition: 2782,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2783",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2783.png",
      edition: 2783,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2784",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2784.png",
      edition: 2784,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2785",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2785.png",
      edition: 2785,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2786",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2786.png",
      edition: 2786,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2787",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2787.png",
      edition: 2787,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2788",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2788.png",
      edition: 2788,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2789",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2789.png",
      edition: 2789,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #279",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/279.png",
      edition: 279,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2790",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2790.png",
      edition: 2790,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2791",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2791.png",
      edition: 2791,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2792",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2792.png",
      edition: 2792,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2793",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2793.png",
      edition: 2793,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2794",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2794.png",
      edition: 2794,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2795",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2795.png",
      edition: 2795,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2796",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2796.png",
      edition: 2796,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2797",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2797.png",
      edition: 2797,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2798",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2798.png",
      edition: 2798,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2799",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2799.png",
      edition: 2799,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #28",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/28.png",
      edition: 28,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #280",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/280.png",
      edition: 280,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2800",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2800.png",
      edition: 2800,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2801",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2801.png",
      edition: 2801,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2802",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2802.png",
      edition: 2802,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2803",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2803.png",
      edition: 2803,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2804",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2804.png",
      edition: 2804,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2805",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2805.png",
      edition: 2805,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2806",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2806.png",
      edition: 2806,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2807",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2807.png",
      edition: 2807,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2808",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2808.png",
      edition: 2808,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2809",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2809.png",
      edition: 2809,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #281",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/281.png",
      edition: 281,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2810",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2810.png",
      edition: 2810,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2811",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2811.png",
      edition: 2811,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2812",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2812.png",
      edition: 2812,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2813",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2813.png",
      edition: 2813,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 600,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2814",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2814.png",
      edition: 2814,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2815",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2815.png",
      edition: 2815,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2816",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2816.png",
      edition: 2816,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2817",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2817.png",
      edition: 2817,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2818",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2818.png",
      edition: 2818,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2819",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2819.png",
      edition: 2819,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #282",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/282.png",
      edition: 282,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2820",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2820.png",
      edition: 2820,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2821",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2821.png",
      edition: 2821,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2822",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2822.png",
      edition: 2822,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2823",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2823.png",
      edition: 2823,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2824",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2824.png",
      edition: 2824,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2825",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2825.png",
      edition: 2825,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 600,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2826",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2826.png",
      edition: 2826,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2827",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2827.png",
      edition: 2827,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2828",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2828.png",
      edition: 2828,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2829",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2829.png",
      edition: 2829,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #283",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/283.png",
      edition: 283,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2830",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2830.png",
      edition: 2830,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2831",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2831.png",
      edition: 2831,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2832",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2832.png",
      edition: 2832,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 600,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2833",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2833.png",
      edition: 2833,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2834",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2834.png",
      edition: 2834,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2835",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2835.png",
      edition: 2835,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2836",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2836.png",
      edition: 2836,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2837",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2837.png",
      edition: 2837,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2838",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2838.png",
      edition: 2838,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2839",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2839.png",
      edition: 2839,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #284",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/284.png",
      edition: 284,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2840",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2840.png",
      edition: 2840,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2841",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2841.png",
      edition: 2841,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2842",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2842.png",
      edition: 2842,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2843",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2843.png",
      edition: 2843,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2844",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2844.png",
      edition: 2844,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2845",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2845.png",
      edition: 2845,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2846",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2846.png",
      edition: 2846,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2847",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2847.png",
      edition: 2847,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2848",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2848.png",
      edition: 2848,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2849",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2849.png",
      edition: 2849,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #285",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/285.png",
      edition: 285,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2850",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2850.png",
      edition: 2850,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2851",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2851.png",
      edition: 2851,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2852",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2852.png",
      edition: 2852,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2853",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2853.png",
      edition: 2853,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2854",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2854.png",
      edition: 2854,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2855",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2855.png",
      edition: 2855,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2856",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2856.png",
      edition: 2856,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2857",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2857.png",
      edition: 2857,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2858",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2858.png",
      edition: 2858,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2859",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2859.png",
      edition: 2859,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #286",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/286.png",
      edition: 286,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2860",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2860.png",
      edition: 2860,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2861",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2861.png",
      edition: 2861,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 600,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2862",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2862.png",
      edition: 2862,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 650,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2863",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2863.png",
      edition: 2863,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2864",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2864.png",
      edition: 2864,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2865",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2865.png",
      edition: 2865,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2866",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2866.png",
      edition: 2866,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2867",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2867.png",
      edition: 2867,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2868",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2868.png",
      edition: 2868,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2869",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2869.png",
      edition: 2869,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #287",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/287.png",
      edition: 287,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2870",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2870.png",
      edition: 2870,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2871",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2871.png",
      edition: 2871,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2872",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2872.png",
      edition: 2872,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2873",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2873.png",
      edition: 2873,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2874",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2874.png",
      edition: 2874,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2875",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2875.png",
      edition: 2875,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2876",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2876.png",
      edition: 2876,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2877",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2877.png",
      edition: 2877,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2878",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2878.png",
      edition: 2878,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2879",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2879.png",
      edition: 2879,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #288",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/288.png",
      edition: 288,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2880",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2880.png",
      edition: 2880,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2881",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2881.png",
      edition: 2881,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2882",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2882.png",
      edition: 2882,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2883",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2883.png",
      edition: 2883,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2884",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2884.png",
      edition: 2884,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 650,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2885",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2885.png",
      edition: 2885,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2886",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2886.png",
      edition: 2886,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2887",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2887.png",
      edition: 2887,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2888",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2888.png",
      edition: 2888,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2889",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2889.png",
      edition: 2889,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 650,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #289",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/289.png",
      edition: 289,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 650,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2890",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2890.png",
      edition: 2890,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2891",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2891.png",
      edition: 2891,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2892",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2892.png",
      edition: 2892,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2893",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2893.png",
      edition: 2893,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2894",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2894.png",
      edition: 2894,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2895",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2895.png",
      edition: 2895,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2896",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2896.png",
      edition: 2896,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2897",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2897.png",
      edition: 2897,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2898",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2898.png",
      edition: 2898,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2899",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2899.png",
      edition: 2899,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #29",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/29.png",
      edition: 29,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #290",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/290.png",
      edition: 290,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2900",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2900.png",
      edition: 2900,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2901",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2901.png",
      edition: 2901,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2902",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2902.png",
      edition: 2902,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2903",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2903.png",
      edition: 2903,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2904",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2904.png",
      edition: 2904,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2905",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2905.png",
      edition: 2905,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2906",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2906.png",
      edition: 2906,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2907",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2907.png",
      edition: 2907,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2908",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2908.png",
      edition: 2908,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2909",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2909.png",
      edition: 2909,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #291",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/291.png",
      edition: 291,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2910",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2910.png",
      edition: 2910,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2911",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2911.png",
      edition: 2911,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2912",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2912.png",
      edition: 2912,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2913",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2913.png",
      edition: 2913,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2914",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2914.png",
      edition: 2914,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2915",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2915.png",
      edition: 2915,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2916",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2916.png",
      edition: 2916,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2917",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2917.png",
      edition: 2917,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 750,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2918",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2918.png",
      edition: 2918,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2919",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2919.png",
      edition: 2919,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #292",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/292.png",
      edition: 292,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2920",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2920.png",
      edition: 2920,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2921",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2921.png",
      edition: 2921,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2922",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2922.png",
      edition: 2922,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 600,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2923",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2923.png",
      edition: 2923,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2924",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2924.png",
      edition: 2924,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2925",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2925.png",
      edition: 2925,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2926",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2926.png",
      edition: 2926,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2927",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2927.png",
      edition: 2927,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2928",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2928.png",
      edition: 2928,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2929",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2929.png",
      edition: 2929,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #293",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/293.png",
      edition: 293,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2930",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2930.png",
      edition: 2930,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2931",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2931.png",
      edition: 2931,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2932",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2932.png",
      edition: 2932,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2933",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2933.png",
      edition: 2933,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2934",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2934.png",
      edition: 2934,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2935",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2935.png",
      edition: 2935,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2936",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2936.png",
      edition: 2936,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2937",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2937.png",
      edition: 2937,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2938",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2938.png",
      edition: 2938,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2939",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2939.png",
      edition: 2939,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #294",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/294.png",
      edition: 294,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2940",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2940.png",
      edition: 2940,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2941",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2941.png",
      edition: 2941,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2942",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2942.png",
      edition: 2942,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2943",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2943.png",
      edition: 2943,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2944",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2944.png",
      edition: 2944,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2945",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2945.png",
      edition: 2945,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2946",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2946.png",
      edition: 2946,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2947",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2947.png",
      edition: 2947,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2948",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2948.png",
      edition: 2948,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2949",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2949.png",
      edition: 2949,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #295",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/295.png",
      edition: 295,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2950",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2950.png",
      edition: 2950,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2951",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2951.png",
      edition: 2951,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2952",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2952.png",
      edition: 2952,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2953",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2953.png",
      edition: 2953,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2954",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2954.png",
      edition: 2954,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2955",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2955.png",
      edition: 2955,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2956",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2956.png",
      edition: 2956,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2957",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2957.png",
      edition: 2957,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2958",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2958.png",
      edition: 2958,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2959",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2959.png",
      edition: 2959,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #296",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/296.png",
      edition: 296,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2960",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2960.png",
      edition: 2960,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2961",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2961.png",
      edition: 2961,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2962",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2962.png",
      edition: 2962,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2963",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2963.png",
      edition: 2963,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2964",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2964.png",
      edition: 2964,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2965",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2965.png",
      edition: 2965,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2966",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2966.png",
      edition: 2966,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2967",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2967.png",
      edition: 2967,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2968",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2968.png",
      edition: 2968,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2969",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2969.png",
      edition: 2969,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #297",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/297.png",
      edition: 297,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2970",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2970.png",
      edition: 2970,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2971",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2971.png",
      edition: 2971,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2972",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2972.png",
      edition: 2972,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2973",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2973.png",
      edition: 2973,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2974",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2974.png",
      edition: 2974,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2975",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2975.png",
      edition: 2975,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2976",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2976.png",
      edition: 2976,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2977",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2977.png",
      edition: 2977,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2978",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2978.png",
      edition: 2978,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2979",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2979.png",
      edition: 2979,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #298",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/298.png",
      edition: 298,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2980",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2980.png",
      edition: 2980,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2981",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2981.png",
      edition: 2981,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2982",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2982.png",
      edition: 2982,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2983",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2983.png",
      edition: 2983,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2984",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2984.png",
      edition: 2984,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2985",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2985.png",
      edition: 2985,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2986",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2986.png",
      edition: 2986,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2987",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2987.png",
      edition: 2987,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2988",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2988.png",
      edition: 2988,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2989",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2989.png",
      edition: 2989,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 650,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #299",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/299.png",
      edition: 299,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2990",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2990.png",
      edition: 2990,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2991",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2991.png",
      edition: 2991,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2992",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2992.png",
      edition: 2992,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2993",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2993.png",
      edition: 2993,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2994",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2994.png",
      edition: 2994,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2995",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2995.png",
      edition: 2995,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2996",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2996.png",
      edition: 2996,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2997",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2997.png",
      edition: 2997,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2998",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2998.png",
      edition: 2998,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #2999",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/2999.png",
      edition: 2999,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 600,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3.png",
      edition: 3,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #30",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/30.png",
      edition: 30,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #300",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/300.png",
      edition: 300,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3000",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3000.png",
      edition: 3000,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3001",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3001.png",
      edition: 3001,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3002",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3002.png",
      edition: 3002,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3003",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3003.png",
      edition: 3003,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3004",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3004.png",
      edition: 3004,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3005",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3005.png",
      edition: 3005,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3006",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3006.png",
      edition: 3006,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3007",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3007.png",
      edition: 3007,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3008",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3008.png",
      edition: 3008,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3009",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3009.png",
      edition: 3009,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #301",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/301.png",
      edition: 301,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3010",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3010.png",
      edition: 3010,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3011",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3011.png",
      edition: 3011,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3012",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3012.png",
      edition: 3012,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3013",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3013.png",
      edition: 3013,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 600,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3014",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3014.png",
      edition: 3014,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3015",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3015.png",
      edition: 3015,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3016",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3016.png",
      edition: 3016,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3017",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3017.png",
      edition: 3017,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 750,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3018",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3018.png",
      edition: 3018,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3019",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3019.png",
      edition: 3019,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #302",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/302.png",
      edition: 302,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3020",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3020.png",
      edition: 3020,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3021",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3021.png",
      edition: 3021,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3022",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3022.png",
      edition: 3022,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3023",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3023.png",
      edition: 3023,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3024",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3024.png",
      edition: 3024,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3025",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3025.png",
      edition: 3025,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3026",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3026.png",
      edition: 3026,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3027",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3027.png",
      edition: 3027,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3028",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3028.png",
      edition: 3028,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3029",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3029.png",
      edition: 3029,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #303",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/303.png",
      edition: 303,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3030",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3030.png",
      edition: 3030,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3031",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3031.png",
      edition: 3031,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3032",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3032.png",
      edition: 3032,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3033",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3033.png",
      edition: 3033,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3034",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3034.png",
      edition: 3034,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3035",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3035.png",
      edition: 3035,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3036",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3036.png",
      edition: 3036,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3037",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3037.png",
      edition: 3037,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3038",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3038.png",
      edition: 3038,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3039",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3039.png",
      edition: 3039,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #304",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/304.png",
      edition: 304,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3040",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3040.png",
      edition: 3040,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3041",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3041.png",
      edition: 3041,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3042",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3042.png",
      edition: 3042,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3043",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3043.png",
      edition: 3043,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3044",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3044.png",
      edition: 3044,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3045",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3045.png",
      edition: 3045,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3046",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3046.png",
      edition: 3046,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3047",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3047.png",
      edition: 3047,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3048",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3048.png",
      edition: 3048,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3049",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3049.png",
      edition: 3049,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #305",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/305.png",
      edition: 305,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3050",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3050.png",
      edition: 3050,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3051",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3051.png",
      edition: 3051,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3052",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3052.png",
      edition: 3052,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3053",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3053.png",
      edition: 3053,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3054",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3054.png",
      edition: 3054,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3055",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3055.png",
      edition: 3055,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3056",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3056.png",
      edition: 3056,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3057",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3057.png",
      edition: 3057,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3058",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3058.png",
      edition: 3058,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3059",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3059.png",
      edition: 3059,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #306",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/306.png",
      edition: 306,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3060",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3060.png",
      edition: 3060,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3061",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3061.png",
      edition: 3061,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3062",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3062.png",
      edition: 3062,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3063",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3063.png",
      edition: 3063,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3064",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3064.png",
      edition: 3064,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3065",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3065.png",
      edition: 3065,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3066",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3066.png",
      edition: 3066,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3067",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3067.png",
      edition: 3067,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3068",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3068.png",
      edition: 3068,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3069",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3069.png",
      edition: 3069,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #307",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/307.png",
      edition: 307,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3070",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3070.png",
      edition: 3070,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3071",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3071.png",
      edition: 3071,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3072",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3072.png",
      edition: 3072,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 600,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3073",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3073.png",
      edition: 3073,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3074",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3074.png",
      edition: 3074,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3075",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3075.png",
      edition: 3075,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3076",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3076.png",
      edition: 3076,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3077",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3077.png",
      edition: 3077,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3078",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3078.png",
      edition: 3078,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3079",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3079.png",
      edition: 3079,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #308",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/308.png",
      edition: 308,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3080",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3080.png",
      edition: 3080,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3081",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3081.png",
      edition: 3081,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3082",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3082.png",
      edition: 3082,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3083",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3083.png",
      edition: 3083,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3084",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3084.png",
      edition: 3084,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3085",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3085.png",
      edition: 3085,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3086",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3086.png",
      edition: 3086,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3087",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3087.png",
      edition: 3087,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3088",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3088.png",
      edition: 3088,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3089",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3089.png",
      edition: 3089,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #309",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/309.png",
      edition: 309,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3090",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3090.png",
      edition: 3090,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3091",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3091.png",
      edition: 3091,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 700,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3092",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3092.png",
      edition: 3092,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3093",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3093.png",
      edition: 3093,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3094",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3094.png",
      edition: 3094,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3095",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3095.png",
      edition: 3095,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3096",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3096.png",
      edition: 3096,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3097",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3097.png",
      edition: 3097,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3098",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3098.png",
      edition: 3098,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3099",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3099.png",
      edition: 3099,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #31",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/31.png",
      edition: 31,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #310",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/310.png",
      edition: 310,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3100",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3100.png",
      edition: 3100,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3101",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3101.png",
      edition: 3101,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3102",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3102.png",
      edition: 3102,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3103",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3103.png",
      edition: 3103,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3104",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3104.png",
      edition: 3104,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3105",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3105.png",
      edition: 3105,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3106",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3106.png",
      edition: 3106,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3107",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3107.png",
      edition: 3107,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3108",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3108.png",
      edition: 3108,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3109",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3109.png",
      edition: 3109,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #311",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/311.png",
      edition: 311,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3110",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3110.png",
      edition: 3110,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3111",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3111.png",
      edition: 3111,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3112",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3112.png",
      edition: 3112,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3113",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3113.png",
      edition: 3113,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3114",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3114.png",
      edition: 3114,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3115",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3115.png",
      edition: 3115,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3116",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3116.png",
      edition: 3116,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 650,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3117",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3117.png",
      edition: 3117,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3118",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3118.png",
      edition: 3118,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3119",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3119.png",
      edition: 3119,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #312",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/312.png",
      edition: 312,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3120",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3120.png",
      edition: 3120,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3121",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3121.png",
      edition: 3121,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3122",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3122.png",
      edition: 3122,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3123",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3123.png",
      edition: 3123,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3124",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3124.png",
      edition: 3124,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3125",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3125.png",
      edition: 3125,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3126",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3126.png",
      edition: 3126,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3127",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3127.png",
      edition: 3127,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3128",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3128.png",
      edition: 3128,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3129",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3129.png",
      edition: 3129,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #313",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/313.png",
      edition: 313,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3130",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3130.png",
      edition: 3130,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3131",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3131.png",
      edition: 3131,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3132",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3132.png",
      edition: 3132,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3133",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3133.png",
      edition: 3133,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3134",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3134.png",
      edition: 3134,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3135",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3135.png",
      edition: 3135,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3136",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3136.png",
      edition: 3136,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3137",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3137.png",
      edition: 3137,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3138",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3138.png",
      edition: 3138,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3139",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3139.png",
      edition: 3139,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #314",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/314.png",
      edition: 314,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3140",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3140.png",
      edition: 3140,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3141",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3141.png",
      edition: 3141,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3142",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3142.png",
      edition: 3142,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3143",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3143.png",
      edition: 3143,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3144",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3144.png",
      edition: 3144,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3145",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3145.png",
      edition: 3145,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3146",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3146.png",
      edition: 3146,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3147",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3147.png",
      edition: 3147,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3148",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3148.png",
      edition: 3148,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3149",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3149.png",
      edition: 3149,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #315",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/315.png",
      edition: 315,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3150",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3150.png",
      edition: 3150,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3151",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3151.png",
      edition: 3151,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3152",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3152.png",
      edition: 3152,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3153",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3153.png",
      edition: 3153,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3154",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3154.png",
      edition: 3154,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3155",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3155.png",
      edition: 3155,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3156",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3156.png",
      edition: 3156,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3157",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3157.png",
      edition: 3157,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3158",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3158.png",
      edition: 3158,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3159",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3159.png",
      edition: 3159,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #316",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/316.png",
      edition: 316,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3160",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3160.png",
      edition: 3160,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3161",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3161.png",
      edition: 3161,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3162",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3162.png",
      edition: 3162,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3163",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3163.png",
      edition: 3163,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3164",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3164.png",
      edition: 3164,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3165",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3165.png",
      edition: 3165,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3166",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3166.png",
      edition: 3166,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3167",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3167.png",
      edition: 3167,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3168",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3168.png",
      edition: 3168,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3169",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3169.png",
      edition: 3169,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #317",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/317.png",
      edition: 317,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3170",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3170.png",
      edition: 3170,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3171",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3171.png",
      edition: 3171,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3172",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3172.png",
      edition: 3172,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3173",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3173.png",
      edition: 3173,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3174",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3174.png",
      edition: 3174,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3175",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3175.png",
      edition: 3175,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3176",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3176.png",
      edition: 3176,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3177",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3177.png",
      edition: 3177,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3178",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3178.png",
      edition: 3178,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3179",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3179.png",
      edition: 3179,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #318",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/318.png",
      edition: 318,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3180",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3180.png",
      edition: 3180,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3181",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3181.png",
      edition: 3181,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 600,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3182",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3182.png",
      edition: 3182,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3183",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3183.png",
      edition: 3183,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 750,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3184",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3184.png",
      edition: 3184,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3185",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3185.png",
      edition: 3185,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3186",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3186.png",
      edition: 3186,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3187",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3187.png",
      edition: 3187,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3188",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3188.png",
      edition: 3188,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3189",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3189.png",
      edition: 3189,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #319",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/319.png",
      edition: 319,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3190",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3190.png",
      edition: 3190,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3191",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3191.png",
      edition: 3191,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3192",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3192.png",
      edition: 3192,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3193",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3193.png",
      edition: 3193,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3194",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3194.png",
      edition: 3194,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3195",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3195.png",
      edition: 3195,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3196",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3196.png",
      edition: 3196,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3197",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3197.png",
      edition: 3197,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3198",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3198.png",
      edition: 3198,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3199",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3199.png",
      edition: 3199,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #32",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/32.png",
      edition: 32,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #320",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/320.png",
      edition: 320,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3200",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3200.png",
      edition: 3200,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3201",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3201.png",
      edition: 3201,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3202",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3202.png",
      edition: 3202,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3203",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3203.png",
      edition: 3203,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3204",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3204.png",
      edition: 3204,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3205",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3205.png",
      edition: 3205,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3206",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3206.png",
      edition: 3206,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3207",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3207.png",
      edition: 3207,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3208",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3208.png",
      edition: 3208,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3209",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3209.png",
      edition: 3209,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #321",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/321.png",
      edition: 321,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3210",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3210.png",
      edition: 3210,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3211",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3211.png",
      edition: 3211,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3212",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3212.png",
      edition: 3212,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3213",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3213.png",
      edition: 3213,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3214",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3214.png",
      edition: 3214,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3215",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3215.png",
      edition: 3215,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3216",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3216.png",
      edition: 3216,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3217",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3217.png",
      edition: 3217,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3218",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3218.png",
      edition: 3218,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3219",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3219.png",
      edition: 3219,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #322",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/322.png",
      edition: 322,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3220",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3220.png",
      edition: 3220,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3221",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3221.png",
      edition: 3221,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3222",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3222.png",
      edition: 3222,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3223",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3223.png",
      edition: 3223,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3224",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3224.png",
      edition: 3224,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3225",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3225.png",
      edition: 3225,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3226",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3226.png",
      edition: 3226,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3227",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3227.png",
      edition: 3227,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3228",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3228.png",
      edition: 3228,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3229",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3229.png",
      edition: 3229,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #323",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/323.png",
      edition: 323,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3230",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3230.png",
      edition: 3230,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3231",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3231.png",
      edition: 3231,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3232",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3232.png",
      edition: 3232,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3233",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3233.png",
      edition: 3233,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3234",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3234.png",
      edition: 3234,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3235",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3235.png",
      edition: 3235,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3236",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3236.png",
      edition: 3236,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3237",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3237.png",
      edition: 3237,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3238",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3238.png",
      edition: 3238,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3239",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3239.png",
      edition: 3239,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #324",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/324.png",
      edition: 324,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3240",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3240.png",
      edition: 3240,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3241",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3241.png",
      edition: 3241,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3242",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3242.png",
      edition: 3242,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3243",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3243.png",
      edition: 3243,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3244",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3244.png",
      edition: 3244,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3245",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3245.png",
      edition: 3245,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3246",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3246.png",
      edition: 3246,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3247",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3247.png",
      edition: 3247,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3248",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3248.png",
      edition: 3248,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3249",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3249.png",
      edition: 3249,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #325",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/325.png",
      edition: 325,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3250",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3250.png",
      edition: 3250,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3251",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3251.png",
      edition: 3251,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3252",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3252.png",
      edition: 3252,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3253",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3253.png",
      edition: 3253,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3254",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3254.png",
      edition: 3254,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3255",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3255.png",
      edition: 3255,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 600,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3256",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3256.png",
      edition: 3256,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3257",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3257.png",
      edition: 3257,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3258",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3258.png",
      edition: 3258,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3259",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3259.png",
      edition: 3259,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #326",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/326.png",
      edition: 326,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3260",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3260.png",
      edition: 3260,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3261",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3261.png",
      edition: 3261,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3262",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3262.png",
      edition: 3262,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3263",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3263.png",
      edition: 3263,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3264",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3264.png",
      edition: 3264,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3265",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3265.png",
      edition: 3265,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3266",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3266.png",
      edition: 3266,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3267",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3267.png",
      edition: 3267,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3268",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3268.png",
      edition: 3268,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3269",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3269.png",
      edition: 3269,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #327",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/327.png",
      edition: 327,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3270",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3270.png",
      edition: 3270,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3271",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3271.png",
      edition: 3271,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3272",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3272.png",
      edition: 3272,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3273",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3273.png",
      edition: 3273,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3274",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3274.png",
      edition: 3274,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3275",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3275.png",
      edition: 3275,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3276",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3276.png",
      edition: 3276,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3277",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3277.png",
      edition: 3277,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3278",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3278.png",
      edition: 3278,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3279",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3279.png",
      edition: 3279,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #328",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/328.png",
      edition: 328,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3280",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3280.png",
      edition: 3280,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3281",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3281.png",
      edition: 3281,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3282",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3282.png",
      edition: 3282,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3283",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3283.png",
      edition: 3283,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3284",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3284.png",
      edition: 3284,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3285",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3285.png",
      edition: 3285,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3286",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3286.png",
      edition: 3286,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3287",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3287.png",
      edition: 3287,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3288",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3288.png",
      edition: 3288,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3289",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3289.png",
      edition: 3289,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #329",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/329.png",
      edition: 329,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3290",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3290.png",
      edition: 3290,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3291",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3291.png",
      edition: 3291,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3292",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3292.png",
      edition: 3292,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3293",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3293.png",
      edition: 3293,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3294",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3294.png",
      edition: 3294,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3295",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3295.png",
      edition: 3295,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3296",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3296.png",
      edition: 3296,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3297",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3297.png",
      edition: 3297,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3298",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3298.png",
      edition: 3298,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3299",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3299.png",
      edition: 3299,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #33",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/33.png",
      edition: 33,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #330",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/330.png",
      edition: 330,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3300",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3300.png",
      edition: 3300,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3301",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3301.png",
      edition: 3301,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3302",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3302.png",
      edition: 3302,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3303",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3303.png",
      edition: 3303,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3304",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3304.png",
      edition: 3304,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3305",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3305.png",
      edition: 3305,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3306",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3306.png",
      edition: 3306,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3307",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3307.png",
      edition: 3307,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3308",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3308.png",
      edition: 3308,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3309",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3309.png",
      edition: 3309,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #331",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/331.png",
      edition: 331,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3310",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3310.png",
      edition: 3310,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3311",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3311.png",
      edition: 3311,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3312",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3312.png",
      edition: 3312,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3313",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3313.png",
      edition: 3313,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3314",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3314.png",
      edition: 3314,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3315",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3315.png",
      edition: 3315,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3316",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3316.png",
      edition: 3316,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3317",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3317.png",
      edition: 3317,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3318",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3318.png",
      edition: 3318,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3319",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3319.png",
      edition: 3319,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #332",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/332.png",
      edition: 332,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3320",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3320.png",
      edition: 3320,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3321",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3321.png",
      edition: 3321,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 700,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3322",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3322.png",
      edition: 3322,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3323",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3323.png",
      edition: 3323,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3324",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3324.png",
      edition: 3324,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3325",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3325.png",
      edition: 3325,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3326",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3326.png",
      edition: 3326,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3327",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3327.png",
      edition: 3327,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3328",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3328.png",
      edition: 3328,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3329",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3329.png",
      edition: 3329,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #333",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/333.png",
      edition: 333,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3330",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3330.png",
      edition: 3330,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3331",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3331.png",
      edition: 3331,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3332",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3332.png",
      edition: 3332,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3333",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3333.png",
      edition: 3333,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3334",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3334.png",
      edition: 3334,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3335",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3335.png",
      edition: 3335,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3336",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3336.png",
      edition: 3336,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3337",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3337.png",
      edition: 3337,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3338",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3338.png",
      edition: 3338,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3339",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3339.png",
      edition: 3339,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #334",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/334.png",
      edition: 334,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3340",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3340.png",
      edition: 3340,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3341",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3341.png",
      edition: 3341,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3342",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3342.png",
      edition: 3342,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3343",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3343.png",
      edition: 3343,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3344",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3344.png",
      edition: 3344,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3345",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3345.png",
      edition: 3345,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3346",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3346.png",
      edition: 3346,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3347",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3347.png",
      edition: 3347,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3348",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3348.png",
      edition: 3348,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3349",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3349.png",
      edition: 3349,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #335",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/335.png",
      edition: 335,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3350",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3350.png",
      edition: 3350,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3351",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3351.png",
      edition: 3351,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3352",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3352.png",
      edition: 3352,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3353",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3353.png",
      edition: 3353,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3354",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3354.png",
      edition: 3354,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3355",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3355.png",
      edition: 3355,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3356",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3356.png",
      edition: 3356,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3357",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3357.png",
      edition: 3357,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3358",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3358.png",
      edition: 3358,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3359",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3359.png",
      edition: 3359,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #336",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/336.png",
      edition: 336,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3360",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3360.png",
      edition: 3360,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3361",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3361.png",
      edition: 3361,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3362",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3362.png",
      edition: 3362,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3363",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3363.png",
      edition: 3363,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3364",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3364.png",
      edition: 3364,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3365",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3365.png",
      edition: 3365,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3366",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3366.png",
      edition: 3366,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3367",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3367.png",
      edition: 3367,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3368",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3368.png",
      edition: 3368,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3369",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3369.png",
      edition: 3369,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #337",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/337.png",
      edition: 337,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3370",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3370.png",
      edition: 3370,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3371",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3371.png",
      edition: 3371,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3372",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3372.png",
      edition: 3372,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3373",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3373.png",
      edition: 3373,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3374",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3374.png",
      edition: 3374,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3375",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3375.png",
      edition: 3375,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3376",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3376.png",
      edition: 3376,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3377",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3377.png",
      edition: 3377,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3378",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3378.png",
      edition: 3378,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3379",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3379.png",
      edition: 3379,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #338",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/338.png",
      edition: 338,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3380",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3380.png",
      edition: 3380,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3381",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3381.png",
      edition: 3381,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3382",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3382.png",
      edition: 3382,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3383",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3383.png",
      edition: 3383,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3384",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3384.png",
      edition: 3384,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3385",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3385.png",
      edition: 3385,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3386",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3386.png",
      edition: 3386,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3387",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3387.png",
      edition: 3387,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3388",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3388.png",
      edition: 3388,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3389",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3389.png",
      edition: 3389,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #339",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/339.png",
      edition: 339,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3390",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3390.png",
      edition: 3390,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3391",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3391.png",
      edition: 3391,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3392",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3392.png",
      edition: 3392,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 650,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3393",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3393.png",
      edition: 3393,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3394",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3394.png",
      edition: 3394,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3395",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3395.png",
      edition: 3395,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3396",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3396.png",
      edition: 3396,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3397",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3397.png",
      edition: 3397,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3398",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3398.png",
      edition: 3398,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3399",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3399.png",
      edition: 3399,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #34",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/34.png",
      edition: 34,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #340",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/340.png",
      edition: 340,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3400",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3400.png",
      edition: 3400,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3401",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3401.png",
      edition: 3401,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3402",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3402.png",
      edition: 3402,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3403",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3403.png",
      edition: 3403,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3404",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3404.png",
      edition: 3404,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3405",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3405.png",
      edition: 3405,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3406",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3406.png",
      edition: 3406,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3407",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3407.png",
      edition: 3407,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3408",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3408.png",
      edition: 3408,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3409",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3409.png",
      edition: 3409,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #341",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/341.png",
      edition: 341,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3410",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3410.png",
      edition: 3410,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3411",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3411.png",
      edition: 3411,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3412",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3412.png",
      edition: 3412,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3413",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3413.png",
      edition: 3413,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3414",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3414.png",
      edition: 3414,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3415",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3415.png",
      edition: 3415,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3416",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3416.png",
      edition: 3416,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3417",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3417.png",
      edition: 3417,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3418",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3418.png",
      edition: 3418,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3419",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3419.png",
      edition: 3419,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #342",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/342.png",
      edition: 342,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3420",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3420.png",
      edition: 3420,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3421",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3421.png",
      edition: 3421,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3422",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3422.png",
      edition: 3422,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3423",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3423.png",
      edition: 3423,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3424",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3424.png",
      edition: 3424,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3425",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3425.png",
      edition: 3425,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3426",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3426.png",
      edition: 3426,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3427",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3427.png",
      edition: 3427,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3428",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3428.png",
      edition: 3428,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3429",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3429.png",
      edition: 3429,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #343",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/343.png",
      edition: 343,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3430",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3430.png",
      edition: 3430,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3431",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3431.png",
      edition: 3431,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3432",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3432.png",
      edition: 3432,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3433",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3433.png",
      edition: 3433,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3434",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3434.png",
      edition: 3434,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3435",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3435.png",
      edition: 3435,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3436",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3436.png",
      edition: 3436,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3437",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3437.png",
      edition: 3437,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3438",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3438.png",
      edition: 3438,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3439",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3439.png",
      edition: 3439,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #344",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/344.png",
      edition: 344,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3440",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3440.png",
      edition: 3440,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3441",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3441.png",
      edition: 3441,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3442",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3442.png",
      edition: 3442,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3443",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3443.png",
      edition: 3443,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3444",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3444.png",
      edition: 3444,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3445",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3445.png",
      edition: 3445,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3446",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3446.png",
      edition: 3446,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3447",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3447.png",
      edition: 3447,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3448",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3448.png",
      edition: 3448,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3449",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3449.png",
      edition: 3449,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #345",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/345.png",
      edition: 345,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3450",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3450.png",
      edition: 3450,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3451",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3451.png",
      edition: 3451,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 600,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3452",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3452.png",
      edition: 3452,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3453",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3453.png",
      edition: 3453,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3454",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3454.png",
      edition: 3454,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3455",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3455.png",
      edition: 3455,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3456",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3456.png",
      edition: 3456,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3457",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3457.png",
      edition: 3457,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3458",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3458.png",
      edition: 3458,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3459",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3459.png",
      edition: 3459,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #346",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/346.png",
      edition: 346,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3460",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3460.png",
      edition: 3460,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 600,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3461",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3461.png",
      edition: 3461,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3462",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3462.png",
      edition: 3462,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3463",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3463.png",
      edition: 3463,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3464",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3464.png",
      edition: 3464,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3465",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3465.png",
      edition: 3465,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 600,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3466",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3466.png",
      edition: 3466,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3467",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3467.png",
      edition: 3467,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3468",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3468.png",
      edition: 3468,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3469",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3469.png",
      edition: 3469,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #347",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/347.png",
      edition: 347,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3470",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3470.png",
      edition: 3470,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3471",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3471.png",
      edition: 3471,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3472",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3472.png",
      edition: 3472,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3473",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3473.png",
      edition: 3473,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3474",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3474.png",
      edition: 3474,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3475",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3475.png",
      edition: 3475,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3476",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3476.png",
      edition: 3476,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3477",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3477.png",
      edition: 3477,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3478",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3478.png",
      edition: 3478,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3479",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3479.png",
      edition: 3479,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #348",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/348.png",
      edition: 348,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3480",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3480.png",
      edition: 3480,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3481",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3481.png",
      edition: 3481,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3482",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3482.png",
      edition: 3482,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3483",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3483.png",
      edition: 3483,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3484",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3484.png",
      edition: 3484,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3485",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3485.png",
      edition: 3485,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3486",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3486.png",
      edition: 3486,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3487",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3487.png",
      edition: 3487,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3488",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3488.png",
      edition: 3488,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3489",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3489.png",
      edition: 3489,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #349",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/349.png",
      edition: 349,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3490",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3490.png",
      edition: 3490,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3491",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3491.png",
      edition: 3491,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3492",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3492.png",
      edition: 3492,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3493",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3493.png",
      edition: 3493,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3494",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3494.png",
      edition: 3494,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3495",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3495.png",
      edition: 3495,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3496",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3496.png",
      edition: 3496,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3497",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3497.png",
      edition: 3497,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3498",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3498.png",
      edition: 3498,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3499",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3499.png",
      edition: 3499,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #35",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/35.png",
      edition: 35,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #350",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/350.png",
      edition: 350,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3500",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3500.png",
      edition: 3500,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3501",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3501.png",
      edition: 3501,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3502",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3502.png",
      edition: 3502,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3503",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3503.png",
      edition: 3503,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3504",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3504.png",
      edition: 3504,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3505",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3505.png",
      edition: 3505,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3506",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3506.png",
      edition: 3506,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3507",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3507.png",
      edition: 3507,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3508",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3508.png",
      edition: 3508,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3509",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3509.png",
      edition: 3509,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #351",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/351.png",
      edition: 351,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3510",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3510.png",
      edition: 3510,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3511",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3511.png",
      edition: 3511,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3512",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3512.png",
      edition: 3512,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3513",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3513.png",
      edition: 3513,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3514",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3514.png",
      edition: 3514,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 600,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3515",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3515.png",
      edition: 3515,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3516",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3516.png",
      edition: 3516,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3517",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3517.png",
      edition: 3517,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3518",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3518.png",
      edition: 3518,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3519",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3519.png",
      edition: 3519,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #352",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/352.png",
      edition: 352,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3520",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3520.png",
      edition: 3520,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3521",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3521.png",
      edition: 3521,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3522",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3522.png",
      edition: 3522,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3523",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3523.png",
      edition: 3523,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3524",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3524.png",
      edition: 3524,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3525",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3525.png",
      edition: 3525,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3526",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3526.png",
      edition: 3526,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3527",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3527.png",
      edition: 3527,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3528",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3528.png",
      edition: 3528,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3529",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3529.png",
      edition: 3529,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #353",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/353.png",
      edition: 353,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3530",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3530.png",
      edition: 3530,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3531",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3531.png",
      edition: 3531,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3532",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3532.png",
      edition: 3532,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3533",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3533.png",
      edition: 3533,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3534",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3534.png",
      edition: 3534,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3535",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3535.png",
      edition: 3535,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3536",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3536.png",
      edition: 3536,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3537",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3537.png",
      edition: 3537,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 600,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3538",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3538.png",
      edition: 3538,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3539",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3539.png",
      edition: 3539,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #354",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/354.png",
      edition: 354,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3540",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3540.png",
      edition: 3540,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3541",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3541.png",
      edition: 3541,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3542",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3542.png",
      edition: 3542,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3543",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3543.png",
      edition: 3543,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3544",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3544.png",
      edition: 3544,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3545",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3545.png",
      edition: 3545,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3546",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3546.png",
      edition: 3546,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3547",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3547.png",
      edition: 3547,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3548",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3548.png",
      edition: 3548,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3549",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3549.png",
      edition: 3549,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #355",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/355.png",
      edition: 355,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3550",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3550.png",
      edition: 3550,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3551",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3551.png",
      edition: 3551,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3552",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3552.png",
      edition: 3552,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3553",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3553.png",
      edition: 3553,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3554",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3554.png",
      edition: 3554,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3555",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3555.png",
      edition: 3555,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3556",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3556.png",
      edition: 3556,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3557",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3557.png",
      edition: 3557,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3558",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3558.png",
      edition: 3558,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3559",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3559.png",
      edition: 3559,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #356",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/356.png",
      edition: 356,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3560",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3560.png",
      edition: 3560,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3561",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3561.png",
      edition: 3561,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3562",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3562.png",
      edition: 3562,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 650,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3563",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3563.png",
      edition: 3563,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3564",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3564.png",
      edition: 3564,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3565",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3565.png",
      edition: 3565,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3566",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3566.png",
      edition: 3566,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3567",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3567.png",
      edition: 3567,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3568",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3568.png",
      edition: 3568,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3569",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3569.png",
      edition: 3569,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #357",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/357.png",
      edition: 357,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3570",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3570.png",
      edition: 3570,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3571",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3571.png",
      edition: 3571,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3572",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3572.png",
      edition: 3572,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3573",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3573.png",
      edition: 3573,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3574",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3574.png",
      edition: 3574,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3575",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3575.png",
      edition: 3575,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3576",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3576.png",
      edition: 3576,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3577",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3577.png",
      edition: 3577,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3578",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3578.png",
      edition: 3578,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3579",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3579.png",
      edition: 3579,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #358",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/358.png",
      edition: 358,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3580",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3580.png",
      edition: 3580,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3581",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3581.png",
      edition: 3581,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3582",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3582.png",
      edition: 3582,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3583",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3583.png",
      edition: 3583,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3584",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3584.png",
      edition: 3584,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3585",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3585.png",
      edition: 3585,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3586",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3586.png",
      edition: 3586,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3587",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3587.png",
      edition: 3587,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3588",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3588.png",
      edition: 3588,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3589",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3589.png",
      edition: 3589,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #359",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/359.png",
      edition: 359,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3590",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3590.png",
      edition: 3590,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3591",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3591.png",
      edition: 3591,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3592",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3592.png",
      edition: 3592,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3593",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3593.png",
      edition: 3593,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3594",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3594.png",
      edition: 3594,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3595",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3595.png",
      edition: 3595,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3596",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3596.png",
      edition: 3596,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3597",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3597.png",
      edition: 3597,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3598",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3598.png",
      edition: 3598,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3599",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3599.png",
      edition: 3599,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #36",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/36.png",
      edition: 36,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #360",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/360.png",
      edition: 360,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3600",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3600.png",
      edition: 3600,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3601",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3601.png",
      edition: 3601,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3602",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3602.png",
      edition: 3602,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3603",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3603.png",
      edition: 3603,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3604",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3604.png",
      edition: 3604,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3605",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3605.png",
      edition: 3605,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3606",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3606.png",
      edition: 3606,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3607",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3607.png",
      edition: 3607,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3608",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3608.png",
      edition: 3608,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 650,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3609",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3609.png",
      edition: 3609,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #361",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/361.png",
      edition: 361,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3610",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3610.png",
      edition: 3610,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3611",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3611.png",
      edition: 3611,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3612",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3612.png",
      edition: 3612,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3613",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3613.png",
      edition: 3613,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3614",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3614.png",
      edition: 3614,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3615",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3615.png",
      edition: 3615,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3616",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3616.png",
      edition: 3616,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3617",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3617.png",
      edition: 3617,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3618",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3618.png",
      edition: 3618,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3619",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3619.png",
      edition: 3619,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #362",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/362.png",
      edition: 362,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3620",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3620.png",
      edition: 3620,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3621",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3621.png",
      edition: 3621,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3622",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3622.png",
      edition: 3622,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 600,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3623",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3623.png",
      edition: 3623,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3624",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3624.png",
      edition: 3624,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 600,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3625",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3625.png",
      edition: 3625,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3626",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3626.png",
      edition: 3626,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3627",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3627.png",
      edition: 3627,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3628",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3628.png",
      edition: 3628,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3629",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3629.png",
      edition: 3629,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #363",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/363.png",
      edition: 363,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3630",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3630.png",
      edition: 3630,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3631",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3631.png",
      edition: 3631,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3632",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3632.png",
      edition: 3632,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3633",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3633.png",
      edition: 3633,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3634",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3634.png",
      edition: 3634,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3635",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3635.png",
      edition: 3635,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3636",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3636.png",
      edition: 3636,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3637",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3637.png",
      edition: 3637,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3638",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3638.png",
      edition: 3638,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3639",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3639.png",
      edition: 3639,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #364",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/364.png",
      edition: 364,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3640",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3640.png",
      edition: 3640,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3641",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3641.png",
      edition: 3641,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3642",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3642.png",
      edition: 3642,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 650,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3643",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3643.png",
      edition: 3643,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3644",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3644.png",
      edition: 3644,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3645",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3645.png",
      edition: 3645,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3646",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3646.png",
      edition: 3646,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3647",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3647.png",
      edition: 3647,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3648",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3648.png",
      edition: 3648,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3649",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3649.png",
      edition: 3649,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #365",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/365.png",
      edition: 365,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3650",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3650.png",
      edition: 3650,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3651",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3651.png",
      edition: 3651,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3652",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3652.png",
      edition: 3652,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3653",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3653.png",
      edition: 3653,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3654",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3654.png",
      edition: 3654,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3655",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3655.png",
      edition: 3655,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3656",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3656.png",
      edition: 3656,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3657",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3657.png",
      edition: 3657,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3658",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3658.png",
      edition: 3658,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3659",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3659.png",
      edition: 3659,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #366",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/366.png",
      edition: 366,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3660",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3660.png",
      edition: 3660,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3661",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3661.png",
      edition: 3661,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3662",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3662.png",
      edition: 3662,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3663",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3663.png",
      edition: 3663,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3664",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3664.png",
      edition: 3664,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3665",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3665.png",
      edition: 3665,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3666",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3666.png",
      edition: 3666,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3667",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3667.png",
      edition: 3667,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3668",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3668.png",
      edition: 3668,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3669",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3669.png",
      edition: 3669,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #367",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/367.png",
      edition: 367,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3670",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3670.png",
      edition: 3670,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3671",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3671.png",
      edition: 3671,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3672",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3672.png",
      edition: 3672,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3673",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3673.png",
      edition: 3673,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3674",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3674.png",
      edition: 3674,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3675",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3675.png",
      edition: 3675,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3676",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3676.png",
      edition: 3676,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3677",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3677.png",
      edition: 3677,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3678",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3678.png",
      edition: 3678,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3679",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3679.png",
      edition: 3679,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #368",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/368.png",
      edition: 368,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 700,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3680",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3680.png",
      edition: 3680,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3681",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3681.png",
      edition: 3681,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3682",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3682.png",
      edition: 3682,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3683",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3683.png",
      edition: 3683,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3684",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3684.png",
      edition: 3684,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3685",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3685.png",
      edition: 3685,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3686",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3686.png",
      edition: 3686,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3687",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3687.png",
      edition: 3687,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3688",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3688.png",
      edition: 3688,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3689",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3689.png",
      edition: 3689,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #369",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/369.png",
      edition: 369,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3690",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3690.png",
      edition: 3690,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3691",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3691.png",
      edition: 3691,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3692",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3692.png",
      edition: 3692,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3693",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3693.png",
      edition: 3693,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3694",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3694.png",
      edition: 3694,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3695",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3695.png",
      edition: 3695,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3696",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3696.png",
      edition: 3696,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3697",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3697.png",
      edition: 3697,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3698",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3698.png",
      edition: 3698,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3699",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3699.png",
      edition: 3699,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #37",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/37.png",
      edition: 37,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #370",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/370.png",
      edition: 370,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3700",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3700.png",
      edition: 3700,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3701",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3701.png",
      edition: 3701,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3702",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3702.png",
      edition: 3702,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3703",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3703.png",
      edition: 3703,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3704",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3704.png",
      edition: 3704,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3705",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3705.png",
      edition: 3705,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3706",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3706.png",
      edition: 3706,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3707",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3707.png",
      edition: 3707,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3708",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3708.png",
      edition: 3708,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3709",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3709.png",
      edition: 3709,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #371",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/371.png",
      edition: 371,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3710",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3710.png",
      edition: 3710,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3711",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3711.png",
      edition: 3711,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3712",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3712.png",
      edition: 3712,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3713",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3713.png",
      edition: 3713,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3714",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3714.png",
      edition: 3714,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3715",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3715.png",
      edition: 3715,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3716",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3716.png",
      edition: 3716,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3717",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3717.png",
      edition: 3717,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3718",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3718.png",
      edition: 3718,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3719",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3719.png",
      edition: 3719,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #372",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/372.png",
      edition: 372,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3720",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3720.png",
      edition: 3720,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3721",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3721.png",
      edition: 3721,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3722",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3722.png",
      edition: 3722,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3723",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3723.png",
      edition: 3723,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3724",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3724.png",
      edition: 3724,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3725",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3725.png",
      edition: 3725,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3726",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3726.png",
      edition: 3726,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3727",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3727.png",
      edition: 3727,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3728",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3728.png",
      edition: 3728,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3729",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3729.png",
      edition: 3729,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #373",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/373.png",
      edition: 373,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3730",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3730.png",
      edition: 3730,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3731",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3731.png",
      edition: 3731,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3732",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3732.png",
      edition: 3732,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3733",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3733.png",
      edition: 3733,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3734",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3734.png",
      edition: 3734,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 600,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3735",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3735.png",
      edition: 3735,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3736",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3736.png",
      edition: 3736,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3737",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3737.png",
      edition: 3737,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3738",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3738.png",
      edition: 3738,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3739",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3739.png",
      edition: 3739,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #374",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/374.png",
      edition: 374,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3740",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3740.png",
      edition: 3740,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3741",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3741.png",
      edition: 3741,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3742",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3742.png",
      edition: 3742,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3743",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3743.png",
      edition: 3743,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3744",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3744.png",
      edition: 3744,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3745",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3745.png",
      edition: 3745,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3746",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3746.png",
      edition: 3746,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3747",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3747.png",
      edition: 3747,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3748",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3748.png",
      edition: 3748,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3749",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3749.png",
      edition: 3749,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #375",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/375.png",
      edition: 375,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3750",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3750.png",
      edition: 3750,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3751",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3751.png",
      edition: 3751,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3752",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3752.png",
      edition: 3752,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3753",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3753.png",
      edition: 3753,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3754",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3754.png",
      edition: 3754,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3755",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3755.png",
      edition: 3755,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3756",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3756.png",
      edition: 3756,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3757",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3757.png",
      edition: 3757,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3758",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3758.png",
      edition: 3758,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 600,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3759",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3759.png",
      edition: 3759,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #376",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/376.png",
      edition: 376,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3760",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3760.png",
      edition: 3760,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3761",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3761.png",
      edition: 3761,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3762",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3762.png",
      edition: 3762,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3763",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3763.png",
      edition: 3763,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3764",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3764.png",
      edition: 3764,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3765",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3765.png",
      edition: 3765,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3766",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3766.png",
      edition: 3766,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 600,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3767",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3767.png",
      edition: 3767,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3768",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3768.png",
      edition: 3768,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3769",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3769.png",
      edition: 3769,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #377",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/377.png",
      edition: 377,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3770",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3770.png",
      edition: 3770,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3771",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3771.png",
      edition: 3771,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3772",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3772.png",
      edition: 3772,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3773",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3773.png",
      edition: 3773,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3774",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3774.png",
      edition: 3774,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3775",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3775.png",
      edition: 3775,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3776",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3776.png",
      edition: 3776,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3777",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3777.png",
      edition: 3777,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3778",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3778.png",
      edition: 3778,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3779",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3779.png",
      edition: 3779,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #378",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/378.png",
      edition: 378,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3780",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3780.png",
      edition: 3780,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3781",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3781.png",
      edition: 3781,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3782",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3782.png",
      edition: 3782,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3783",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3783.png",
      edition: 3783,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3784",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3784.png",
      edition: 3784,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3785",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3785.png",
      edition: 3785,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3786",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3786.png",
      edition: 3786,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3787",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3787.png",
      edition: 3787,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3788",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3788.png",
      edition: 3788,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3789",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3789.png",
      edition: 3789,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #379",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/379.png",
      edition: 379,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3790",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3790.png",
      edition: 3790,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3791",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3791.png",
      edition: 3791,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3792",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3792.png",
      edition: 3792,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3793",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3793.png",
      edition: 3793,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3794",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3794.png",
      edition: 3794,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3795",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3795.png",
      edition: 3795,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3796",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3796.png",
      edition: 3796,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3797",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3797.png",
      edition: 3797,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3798",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3798.png",
      edition: 3798,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3799",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3799.png",
      edition: 3799,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #38",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/38.png",
      edition: 38,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #380",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/380.png",
      edition: 380,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3800",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3800.png",
      edition: 3800,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3801",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3801.png",
      edition: 3801,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3802",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3802.png",
      edition: 3802,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3803",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3803.png",
      edition: 3803,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3804",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3804.png",
      edition: 3804,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3805",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3805.png",
      edition: 3805,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3806",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3806.png",
      edition: 3806,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3807",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3807.png",
      edition: 3807,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3808",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3808.png",
      edition: 3808,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3809",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3809.png",
      edition: 3809,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #381",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/381.png",
      edition: 381,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3810",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3810.png",
      edition: 3810,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3811",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3811.png",
      edition: 3811,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3812",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3812.png",
      edition: 3812,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3813",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3813.png",
      edition: 3813,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3814",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3814.png",
      edition: 3814,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3815",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3815.png",
      edition: 3815,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3816",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3816.png",
      edition: 3816,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3817",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3817.png",
      edition: 3817,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3818",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3818.png",
      edition: 3818,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3819",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3819.png",
      edition: 3819,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #382",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/382.png",
      edition: 382,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3820",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3820.png",
      edition: 3820,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3821",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3821.png",
      edition: 3821,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3822",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3822.png",
      edition: 3822,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3823",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3823.png",
      edition: 3823,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3824",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3824.png",
      edition: 3824,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3825",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3825.png",
      edition: 3825,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3826",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3826.png",
      edition: 3826,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3827",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3827.png",
      edition: 3827,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3828",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3828.png",
      edition: 3828,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3829",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3829.png",
      edition: 3829,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #383",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/383.png",
      edition: 383,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3830",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3830.png",
      edition: 3830,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3831",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3831.png",
      edition: 3831,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3832",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3832.png",
      edition: 3832,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3833",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3833.png",
      edition: 3833,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3834",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3834.png",
      edition: 3834,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3835",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3835.png",
      edition: 3835,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3836",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3836.png",
      edition: 3836,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3837",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3837.png",
      edition: 3837,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3838",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3838.png",
      edition: 3838,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3839",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3839.png",
      edition: 3839,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #384",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/384.png",
      edition: 384,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3840",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3840.png",
      edition: 3840,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3841",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3841.png",
      edition: 3841,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3842",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3842.png",
      edition: 3842,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3843",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3843.png",
      edition: 3843,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3844",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3844.png",
      edition: 3844,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3845",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3845.png",
      edition: 3845,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3846",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3846.png",
      edition: 3846,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3847",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3847.png",
      edition: 3847,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3848",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3848.png",
      edition: 3848,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3849",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3849.png",
      edition: 3849,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #385",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/385.png",
      edition: 385,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3850",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3850.png",
      edition: 3850,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3851",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3851.png",
      edition: 3851,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3852",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3852.png",
      edition: 3852,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3853",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3853.png",
      edition: 3853,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3854",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3854.png",
      edition: 3854,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 650,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3855",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3855.png",
      edition: 3855,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3856",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3856.png",
      edition: 3856,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3857",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3857.png",
      edition: 3857,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3858",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3858.png",
      edition: 3858,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3859",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3859.png",
      edition: 3859,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #386",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/386.png",
      edition: 386,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3860",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3860.png",
      edition: 3860,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3861",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3861.png",
      edition: 3861,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3862",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3862.png",
      edition: 3862,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3863",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3863.png",
      edition: 3863,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 650,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3864",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3864.png",
      edition: 3864,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3865",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3865.png",
      edition: 3865,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3866",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3866.png",
      edition: 3866,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3867",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3867.png",
      edition: 3867,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3868",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3868.png",
      edition: 3868,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3869",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3869.png",
      edition: 3869,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #387",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/387.png",
      edition: 387,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3870",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3870.png",
      edition: 3870,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 600,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3871",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3871.png",
      edition: 3871,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3872",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3872.png",
      edition: 3872,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3873",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3873.png",
      edition: 3873,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3874",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3874.png",
      edition: 3874,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3875",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3875.png",
      edition: 3875,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3876",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3876.png",
      edition: 3876,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3877",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3877.png",
      edition: 3877,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3878",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3878.png",
      edition: 3878,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3879",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3879.png",
      edition: 3879,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #388",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/388.png",
      edition: 388,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3880",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3880.png",
      edition: 3880,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3881",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3881.png",
      edition: 3881,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3882",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3882.png",
      edition: 3882,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3883",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3883.png",
      edition: 3883,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3884",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3884.png",
      edition: 3884,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3885",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3885.png",
      edition: 3885,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3886",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3886.png",
      edition: 3886,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3887",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3887.png",
      edition: 3887,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3888",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3888.png",
      edition: 3888,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3889",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3889.png",
      edition: 3889,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #389",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/389.png",
      edition: 389,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3890",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3890.png",
      edition: 3890,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3891",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3891.png",
      edition: 3891,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3892",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3892.png",
      edition: 3892,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3893",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3893.png",
      edition: 3893,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3894",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3894.png",
      edition: 3894,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3895",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3895.png",
      edition: 3895,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3896",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3896.png",
      edition: 3896,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3897",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3897.png",
      edition: 3897,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3898",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3898.png",
      edition: 3898,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3899",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3899.png",
      edition: 3899,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #39",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/39.png",
      edition: 39,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #390",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/390.png",
      edition: 390,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3900",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3900.png",
      edition: 3900,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3901",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3901.png",
      edition: 3901,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3902",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3902.png",
      edition: 3902,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3903",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3903.png",
      edition: 3903,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3904",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3904.png",
      edition: 3904,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3905",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3905.png",
      edition: 3905,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3906",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3906.png",
      edition: 3906,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3907",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3907.png",
      edition: 3907,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3908",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3908.png",
      edition: 3908,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3909",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3909.png",
      edition: 3909,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #391",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/391.png",
      edition: 391,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3910",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3910.png",
      edition: 3910,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3911",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3911.png",
      edition: 3911,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3912",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3912.png",
      edition: 3912,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3913",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3913.png",
      edition: 3913,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3914",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3914.png",
      edition: 3914,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3915",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3915.png",
      edition: 3915,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3916",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3916.png",
      edition: 3916,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3917",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3917.png",
      edition: 3917,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3918",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3918.png",
      edition: 3918,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3919",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3919.png",
      edition: 3919,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #392",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/392.png",
      edition: 392,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3920",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3920.png",
      edition: 3920,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3921",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3921.png",
      edition: 3921,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 600,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3922",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3922.png",
      edition: 3922,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3923",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3923.png",
      edition: 3923,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3924",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3924.png",
      edition: 3924,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3925",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3925.png",
      edition: 3925,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3926",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3926.png",
      edition: 3926,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3927",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3927.png",
      edition: 3927,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3928",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3928.png",
      edition: 3928,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3929",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3929.png",
      edition: 3929,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #393",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/393.png",
      edition: 393,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3930",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3930.png",
      edition: 3930,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3931",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3931.png",
      edition: 3931,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3932",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3932.png",
      edition: 3932,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3933",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3933.png",
      edition: 3933,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3934",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3934.png",
      edition: 3934,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3935",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3935.png",
      edition: 3935,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3936",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3936.png",
      edition: 3936,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3937",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3937.png",
      edition: 3937,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3938",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3938.png",
      edition: 3938,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3939",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3939.png",
      edition: 3939,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #394",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/394.png",
      edition: 394,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3940",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3940.png",
      edition: 3940,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3941",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3941.png",
      edition: 3941,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3942",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3942.png",
      edition: 3942,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3943",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3943.png",
      edition: 3943,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3944",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3944.png",
      edition: 3944,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3945",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3945.png",
      edition: 3945,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3946",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3946.png",
      edition: 3946,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3947",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3947.png",
      edition: 3947,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3948",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3948.png",
      edition: 3948,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 650,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3949",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3949.png",
      edition: 3949,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #395",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/395.png",
      edition: 395,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3950",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3950.png",
      edition: 3950,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3951",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3951.png",
      edition: 3951,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3952",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3952.png",
      edition: 3952,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3953",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3953.png",
      edition: 3953,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3954",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3954.png",
      edition: 3954,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3955",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3955.png",
      edition: 3955,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3956",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3956.png",
      edition: 3956,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3957",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3957.png",
      edition: 3957,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3958",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3958.png",
      edition: 3958,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3959",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3959.png",
      edition: 3959,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #396",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/396.png",
      edition: 396,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3960",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3960.png",
      edition: 3960,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3961",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3961.png",
      edition: 3961,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3962",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3962.png",
      edition: 3962,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3963",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3963.png",
      edition: 3963,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3964",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3964.png",
      edition: 3964,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3965",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3965.png",
      edition: 3965,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3966",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3966.png",
      edition: 3966,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3967",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3967.png",
      edition: 3967,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3968",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3968.png",
      edition: 3968,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3969",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3969.png",
      edition: 3969,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #397",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/397.png",
      edition: 397,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3970",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3970.png",
      edition: 3970,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3971",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3971.png",
      edition: 3971,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3972",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3972.png",
      edition: 3972,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3973",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3973.png",
      edition: 3973,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3974",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3974.png",
      edition: 3974,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3975",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3975.png",
      edition: 3975,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3976",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3976.png",
      edition: 3976,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3977",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3977.png",
      edition: 3977,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3978",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3978.png",
      edition: 3978,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3979",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3979.png",
      edition: 3979,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #398",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/398.png",
      edition: 398,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3980",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3980.png",
      edition: 3980,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3981",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3981.png",
      edition: 3981,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3982",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3982.png",
      edition: 3982,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3983",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3983.png",
      edition: 3983,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3984",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3984.png",
      edition: 3984,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3985",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3985.png",
      edition: 3985,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3986",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3986.png",
      edition: 3986,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3987",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3987.png",
      edition: 3987,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3988",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3988.png",
      edition: 3988,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3989",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3989.png",
      edition: 3989,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #399",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/399.png",
      edition: 399,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3990",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3990.png",
      edition: 3990,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3991",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3991.png",
      edition: 3991,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3992",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3992.png",
      edition: 3992,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3993",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3993.png",
      edition: 3993,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3994",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3994.png",
      edition: 3994,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3995",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3995.png",
      edition: 3995,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3996",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3996.png",
      edition: 3996,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3997",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3997.png",
      edition: 3997,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3998",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3998.png",
      edition: 3998,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #3999",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/3999.png",
      edition: 3999,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4.png",
      edition: 4,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #40",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/40.png",
      edition: 40,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #400",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/400.png",
      edition: 400,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4000",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4000.png",
      edition: 4000,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4001",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4001.png",
      edition: 4001,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4002",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4002.png",
      edition: 4002,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4003",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4003.png",
      edition: 4003,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4004",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4004.png",
      edition: 4004,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 650,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4005",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4005.png",
      edition: 4005,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4006",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4006.png",
      edition: 4006,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4007",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4007.png",
      edition: 4007,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4008",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4008.png",
      edition: 4008,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4009",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4009.png",
      edition: 4009,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #401",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/401.png",
      edition: 401,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4010",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4010.png",
      edition: 4010,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4011",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4011.png",
      edition: 4011,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4012",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4012.png",
      edition: 4012,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4013",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4013.png",
      edition: 4013,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4014",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4014.png",
      edition: 4014,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4015",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4015.png",
      edition: 4015,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4016",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4016.png",
      edition: 4016,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4017",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4017.png",
      edition: 4017,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4018",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4018.png",
      edition: 4018,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4019",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4019.png",
      edition: 4019,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #402",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/402.png",
      edition: 402,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4020",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4020.png",
      edition: 4020,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4021",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4021.png",
      edition: 4021,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4022",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4022.png",
      edition: 4022,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4023",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4023.png",
      edition: 4023,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4024",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4024.png",
      edition: 4024,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4025",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4025.png",
      edition: 4025,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4026",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4026.png",
      edition: 4026,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4027",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4027.png",
      edition: 4027,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4028",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4028.png",
      edition: 4028,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4029",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4029.png",
      edition: 4029,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #403",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/403.png",
      edition: 403,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4030",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4030.png",
      edition: 4030,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4031",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4031.png",
      edition: 4031,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4032",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4032.png",
      edition: 4032,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4033",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4033.png",
      edition: 4033,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4034",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4034.png",
      edition: 4034,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4035",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4035.png",
      edition: 4035,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4036",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4036.png",
      edition: 4036,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4037",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4037.png",
      edition: 4037,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4038",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4038.png",
      edition: 4038,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4039",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4039.png",
      edition: 4039,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #404",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/404.png",
      edition: 404,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4040",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4040.png",
      edition: 4040,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4041",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4041.png",
      edition: 4041,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4042",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4042.png",
      edition: 4042,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4043",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4043.png",
      edition: 4043,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4044",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4044.png",
      edition: 4044,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4045",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4045.png",
      edition: 4045,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4046",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4046.png",
      edition: 4046,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4047",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4047.png",
      edition: 4047,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4048",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4048.png",
      edition: 4048,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4049",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4049.png",
      edition: 4049,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #405",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/405.png",
      edition: 405,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4050",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4050.png",
      edition: 4050,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4051",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4051.png",
      edition: 4051,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4052",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4052.png",
      edition: 4052,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4053",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4053.png",
      edition: 4053,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4054",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4054.png",
      edition: 4054,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4055",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4055.png",
      edition: 4055,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4056",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4056.png",
      edition: 4056,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4057",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4057.png",
      edition: 4057,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4058",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4058.png",
      edition: 4058,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4059",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4059.png",
      edition: 4059,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #406",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/406.png",
      edition: 406,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4060",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4060.png",
      edition: 4060,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4061",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4061.png",
      edition: 4061,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4062",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4062.png",
      edition: 4062,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4063",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4063.png",
      edition: 4063,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4064",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4064.png",
      edition: 4064,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4065",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4065.png",
      edition: 4065,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4066",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4066.png",
      edition: 4066,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4067",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4067.png",
      edition: 4067,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4068",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4068.png",
      edition: 4068,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4069",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4069.png",
      edition: 4069,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #407",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/407.png",
      edition: 407,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4070",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4070.png",
      edition: 4070,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4071",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4071.png",
      edition: 4071,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4072",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4072.png",
      edition: 4072,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4073",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4073.png",
      edition: 4073,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4074",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4074.png",
      edition: 4074,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4075",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4075.png",
      edition: 4075,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4076",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4076.png",
      edition: 4076,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4077",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4077.png",
      edition: 4077,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4078",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4078.png",
      edition: 4078,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4079",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4079.png",
      edition: 4079,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #408",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/408.png",
      edition: 408,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4080",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4080.png",
      edition: 4080,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4081",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4081.png",
      edition: 4081,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4082",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4082.png",
      edition: 4082,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4083",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4083.png",
      edition: 4083,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4084",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4084.png",
      edition: 4084,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4085",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4085.png",
      edition: 4085,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 650,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4086",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4086.png",
      edition: 4086,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4087",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4087.png",
      edition: 4087,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4088",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4088.png",
      edition: 4088,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4089",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4089.png",
      edition: 4089,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #409",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/409.png",
      edition: 409,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4090",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4090.png",
      edition: 4090,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4091",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4091.png",
      edition: 4091,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4092",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4092.png",
      edition: 4092,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4093",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4093.png",
      edition: 4093,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4094",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4094.png",
      edition: 4094,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4095",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4095.png",
      edition: 4095,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4096",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4096.png",
      edition: 4096,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 650,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4097",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4097.png",
      edition: 4097,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4098",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4098.png",
      edition: 4098,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 650,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4099",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4099.png",
      edition: 4099,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #41",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/41.png",
      edition: 41,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #410",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/410.png",
      edition: 410,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4100",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4100.png",
      edition: 4100,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4101",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4101.png",
      edition: 4101,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4102",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4102.png",
      edition: 4102,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4103",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4103.png",
      edition: 4103,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4104",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4104.png",
      edition: 4104,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4105",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4105.png",
      edition: 4105,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4106",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4106.png",
      edition: 4106,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4107",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4107.png",
      edition: 4107,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4108",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4108.png",
      edition: 4108,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4109",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4109.png",
      edition: 4109,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #411",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/411.png",
      edition: 411,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4110",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4110.png",
      edition: 4110,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4111",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4111.png",
      edition: 4111,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4112",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4112.png",
      edition: 4112,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4113",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4113.png",
      edition: 4113,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4114",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4114.png",
      edition: 4114,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4115",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4115.png",
      edition: 4115,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4116",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4116.png",
      edition: 4116,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4117",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4117.png",
      edition: 4117,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4118",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4118.png",
      edition: 4118,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4119",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4119.png",
      edition: 4119,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #412",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/412.png",
      edition: 412,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4120",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4120.png",
      edition: 4120,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4121",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4121.png",
      edition: 4121,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4122",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4122.png",
      edition: 4122,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4123",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4123.png",
      edition: 4123,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4124",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4124.png",
      edition: 4124,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4125",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4125.png",
      edition: 4125,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4126",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4126.png",
      edition: 4126,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4127",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4127.png",
      edition: 4127,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4128",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4128.png",
      edition: 4128,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 700,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4129",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4129.png",
      edition: 4129,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #413",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/413.png",
      edition: 413,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4130",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4130.png",
      edition: 4130,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4131",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4131.png",
      edition: 4131,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4132",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4132.png",
      edition: 4132,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4133",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4133.png",
      edition: 4133,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 650,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4134",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4134.png",
      edition: 4134,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4135",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4135.png",
      edition: 4135,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4136",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4136.png",
      edition: 4136,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4137",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4137.png",
      edition: 4137,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4138",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4138.png",
      edition: 4138,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4139",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4139.png",
      edition: 4139,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #414",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/414.png",
      edition: 414,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4140",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4140.png",
      edition: 4140,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4141",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4141.png",
      edition: 4141,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4142",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4142.png",
      edition: 4142,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4143",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4143.png",
      edition: 4143,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4144",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4144.png",
      edition: 4144,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4145",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4145.png",
      edition: 4145,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4146",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4146.png",
      edition: 4146,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4147",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4147.png",
      edition: 4147,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4148",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4148.png",
      edition: 4148,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4149",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4149.png",
      edition: 4149,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #415",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/415.png",
      edition: 415,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4150",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4150.png",
      edition: 4150,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4151",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4151.png",
      edition: 4151,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4152",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4152.png",
      edition: 4152,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4153",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4153.png",
      edition: 4153,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4154",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4154.png",
      edition: 4154,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4155",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4155.png",
      edition: 4155,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4156",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4156.png",
      edition: 4156,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4157",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4157.png",
      edition: 4157,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4158",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4158.png",
      edition: 4158,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4159",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4159.png",
      edition: 4159,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #416",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/416.png",
      edition: 416,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4160",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4160.png",
      edition: 4160,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4161",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4161.png",
      edition: 4161,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4162",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4162.png",
      edition: 4162,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4163",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4163.png",
      edition: 4163,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4164",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4164.png",
      edition: 4164,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4165",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4165.png",
      edition: 4165,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4166",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4166.png",
      edition: 4166,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4167",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4167.png",
      edition: 4167,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4168",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4168.png",
      edition: 4168,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 750,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4169",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4169.png",
      edition: 4169,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #417",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/417.png",
      edition: 417,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4170",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4170.png",
      edition: 4170,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4171",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4171.png",
      edition: 4171,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4172",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4172.png",
      edition: 4172,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4173",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4173.png",
      edition: 4173,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4174",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4174.png",
      edition: 4174,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4175",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4175.png",
      edition: 4175,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4176",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4176.png",
      edition: 4176,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4177",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4177.png",
      edition: 4177,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4178",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4178.png",
      edition: 4178,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4179",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4179.png",
      edition: 4179,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #418",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/418.png",
      edition: 418,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4180",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4180.png",
      edition: 4180,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4181",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4181.png",
      edition: 4181,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4182",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4182.png",
      edition: 4182,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4183",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4183.png",
      edition: 4183,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4184",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4184.png",
      edition: 4184,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4185",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4185.png",
      edition: 4185,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4186",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4186.png",
      edition: 4186,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4187",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4187.png",
      edition: 4187,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4188",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4188.png",
      edition: 4188,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4189",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4189.png",
      edition: 4189,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #419",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/419.png",
      edition: 419,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4190",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4190.png",
      edition: 4190,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4191",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4191.png",
      edition: 4191,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4192",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4192.png",
      edition: 4192,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4193",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4193.png",
      edition: 4193,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4194",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4194.png",
      edition: 4194,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4195",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4195.png",
      edition: 4195,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4196",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4196.png",
      edition: 4196,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4197",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4197.png",
      edition: 4197,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4198",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4198.png",
      edition: 4198,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4199",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4199.png",
      edition: 4199,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #42",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/42.png",
      edition: 42,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #420",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/420.png",
      edition: 420,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4200",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4200.png",
      edition: 4200,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4201",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4201.png",
      edition: 4201,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4202",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4202.png",
      edition: 4202,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4203",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4203.png",
      edition: 4203,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4204",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4204.png",
      edition: 4204,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4205",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4205.png",
      edition: 4205,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4206",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4206.png",
      edition: 4206,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4207",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4207.png",
      edition: 4207,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4208",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4208.png",
      edition: 4208,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4209",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4209.png",
      edition: 4209,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #421",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/421.png",
      edition: 421,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4210",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4210.png",
      edition: 4210,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4211",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4211.png",
      edition: 4211,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4212",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4212.png",
      edition: 4212,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4213",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4213.png",
      edition: 4213,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4214",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4214.png",
      edition: 4214,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4215",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4215.png",
      edition: 4215,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4216",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4216.png",
      edition: 4216,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4217",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4217.png",
      edition: 4217,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4218",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4218.png",
      edition: 4218,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4219",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4219.png",
      edition: 4219,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #422",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/422.png",
      edition: 422,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4220",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4220.png",
      edition: 4220,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4221",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4221.png",
      edition: 4221,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4222",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4222.png",
      edition: 4222,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4223",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4223.png",
      edition: 4223,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4224",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4224.png",
      edition: 4224,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4225",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4225.png",
      edition: 4225,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4226",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4226.png",
      edition: 4226,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4227",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4227.png",
      edition: 4227,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4228",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4228.png",
      edition: 4228,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4229",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4229.png",
      edition: 4229,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #423",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/423.png",
      edition: 423,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4230",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4230.png",
      edition: 4230,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4231",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4231.png",
      edition: 4231,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4232",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4232.png",
      edition: 4232,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4233",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4233.png",
      edition: 4233,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4234",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4234.png",
      edition: 4234,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4235",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4235.png",
      edition: 4235,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4236",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4236.png",
      edition: 4236,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4237",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4237.png",
      edition: 4237,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4238",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4238.png",
      edition: 4238,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4239",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4239.png",
      edition: 4239,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #424",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/424.png",
      edition: 424,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4240",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4240.png",
      edition: 4240,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4241",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4241.png",
      edition: 4241,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4242",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4242.png",
      edition: 4242,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4243",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4243.png",
      edition: 4243,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4244",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4244.png",
      edition: 4244,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4245",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4245.png",
      edition: 4245,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4246",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4246.png",
      edition: 4246,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4247",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4247.png",
      edition: 4247,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4248",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4248.png",
      edition: 4248,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 700,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4249",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4249.png",
      edition: 4249,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #425",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/425.png",
      edition: 425,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4250",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4250.png",
      edition: 4250,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4251",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4251.png",
      edition: 4251,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4252",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4252.png",
      edition: 4252,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4253",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4253.png",
      edition: 4253,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4254",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4254.png",
      edition: 4254,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 600,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4255",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4255.png",
      edition: 4255,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4256",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4256.png",
      edition: 4256,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4257",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4257.png",
      edition: 4257,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4258",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4258.png",
      edition: 4258,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4259",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4259.png",
      edition: 4259,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #426",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/426.png",
      edition: 426,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 600,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4260",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4260.png",
      edition: 4260,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4261",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4261.png",
      edition: 4261,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4262",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4262.png",
      edition: 4262,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4263",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4263.png",
      edition: 4263,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4264",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4264.png",
      edition: 4264,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4265",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4265.png",
      edition: 4265,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4266",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4266.png",
      edition: 4266,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4267",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4267.png",
      edition: 4267,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4268",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4268.png",
      edition: 4268,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4269",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4269.png",
      edition: 4269,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #427",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/427.png",
      edition: 427,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4270",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4270.png",
      edition: 4270,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4271",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4271.png",
      edition: 4271,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4272",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4272.png",
      edition: 4272,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4273",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4273.png",
      edition: 4273,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4274",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4274.png",
      edition: 4274,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4275",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4275.png",
      edition: 4275,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4276",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4276.png",
      edition: 4276,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4277",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4277.png",
      edition: 4277,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4278",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4278.png",
      edition: 4278,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4279",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4279.png",
      edition: 4279,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #428",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/428.png",
      edition: 428,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4280",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4280.png",
      edition: 4280,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4281",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4281.png",
      edition: 4281,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4282",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4282.png",
      edition: 4282,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4283",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4283.png",
      edition: 4283,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4284",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4284.png",
      edition: 4284,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4285",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4285.png",
      edition: 4285,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4286",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4286.png",
      edition: 4286,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4287",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4287.png",
      edition: 4287,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4288",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4288.png",
      edition: 4288,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4289",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4289.png",
      edition: 4289,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #429",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/429.png",
      edition: 429,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4290",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4290.png",
      edition: 4290,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4291",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4291.png",
      edition: 4291,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4292",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4292.png",
      edition: 4292,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4293",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4293.png",
      edition: 4293,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4294",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4294.png",
      edition: 4294,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4295",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4295.png",
      edition: 4295,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4296",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4296.png",
      edition: 4296,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4297",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4297.png",
      edition: 4297,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4298",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4298.png",
      edition: 4298,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4299",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4299.png",
      edition: 4299,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #43",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/43.png",
      edition: 43,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #430",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/430.png",
      edition: 430,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4300",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4300.png",
      edition: 4300,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4301",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4301.png",
      edition: 4301,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4302",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4302.png",
      edition: 4302,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4303",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4303.png",
      edition: 4303,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4304",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4304.png",
      edition: 4304,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4305",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4305.png",
      edition: 4305,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4306",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4306.png",
      edition: 4306,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4307",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4307.png",
      edition: 4307,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4308",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4308.png",
      edition: 4308,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4309",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4309.png",
      edition: 4309,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 600,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #431",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/431.png",
      edition: 431,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4310",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4310.png",
      edition: 4310,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4311",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4311.png",
      edition: 4311,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4312",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4312.png",
      edition: 4312,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4313",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4313.png",
      edition: 4313,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4314",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4314.png",
      edition: 4314,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4315",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4315.png",
      edition: 4315,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4316",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4316.png",
      edition: 4316,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4317",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4317.png",
      edition: 4317,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4318",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4318.png",
      edition: 4318,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4319",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4319.png",
      edition: 4319,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #432",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/432.png",
      edition: 432,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4320",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4320.png",
      edition: 4320,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4321",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4321.png",
      edition: 4321,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4322",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4322.png",
      edition: 4322,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4323",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4323.png",
      edition: 4323,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4324",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4324.png",
      edition: 4324,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4325",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4325.png",
      edition: 4325,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4326",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4326.png",
      edition: 4326,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4327",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4327.png",
      edition: 4327,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4328",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4328.png",
      edition: 4328,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4329",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4329.png",
      edition: 4329,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #433",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/433.png",
      edition: 433,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4330",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4330.png",
      edition: 4330,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4331",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4331.png",
      edition: 4331,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4332",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4332.png",
      edition: 4332,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4333",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4333.png",
      edition: 4333,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4334",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4334.png",
      edition: 4334,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4335",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4335.png",
      edition: 4335,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4336",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4336.png",
      edition: 4336,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4337",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4337.png",
      edition: 4337,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4338",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4338.png",
      edition: 4338,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4339",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4339.png",
      edition: 4339,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #434",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/434.png",
      edition: 434,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4340",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4340.png",
      edition: 4340,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4341",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4341.png",
      edition: 4341,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4342",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4342.png",
      edition: 4342,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4343",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4343.png",
      edition: 4343,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4344",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4344.png",
      edition: 4344,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4345",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4345.png",
      edition: 4345,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4346",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4346.png",
      edition: 4346,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4347",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4347.png",
      edition: 4347,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 700,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4348",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4348.png",
      edition: 4348,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4349",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4349.png",
      edition: 4349,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #435",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/435.png",
      edition: 435,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4350",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4350.png",
      edition: 4350,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4351",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4351.png",
      edition: 4351,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4352",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4352.png",
      edition: 4352,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4353",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4353.png",
      edition: 4353,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4354",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4354.png",
      edition: 4354,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4355",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4355.png",
      edition: 4355,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4356",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4356.png",
      edition: 4356,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4357",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4357.png",
      edition: 4357,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4358",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4358.png",
      edition: 4358,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4359",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4359.png",
      edition: 4359,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #436",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/436.png",
      edition: 436,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4360",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4360.png",
      edition: 4360,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4361",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4361.png",
      edition: 4361,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4362",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4362.png",
      edition: 4362,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4363",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4363.png",
      edition: 4363,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4364",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4364.png",
      edition: 4364,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4365",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4365.png",
      edition: 4365,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4366",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4366.png",
      edition: 4366,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4367",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4367.png",
      edition: 4367,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4368",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4368.png",
      edition: 4368,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4369",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4369.png",
      edition: 4369,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #437",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/437.png",
      edition: 437,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4370",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4370.png",
      edition: 4370,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4371",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4371.png",
      edition: 4371,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4372",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4372.png",
      edition: 4372,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4373",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4373.png",
      edition: 4373,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4374",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4374.png",
      edition: 4374,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4375",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4375.png",
      edition: 4375,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4376",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4376.png",
      edition: 4376,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 650,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4377",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4377.png",
      edition: 4377,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4378",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4378.png",
      edition: 4378,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4379",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4379.png",
      edition: 4379,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #438",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/438.png",
      edition: 438,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 600,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4380",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4380.png",
      edition: 4380,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4381",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4381.png",
      edition: 4381,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4382",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4382.png",
      edition: 4382,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4383",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4383.png",
      edition: 4383,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4384",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4384.png",
      edition: 4384,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4385",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4385.png",
      edition: 4385,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4386",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4386.png",
      edition: 4386,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4387",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4387.png",
      edition: 4387,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4388",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4388.png",
      edition: 4388,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4389",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4389.png",
      edition: 4389,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #439",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/439.png",
      edition: 439,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4390",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4390.png",
      edition: 4390,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4391",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4391.png",
      edition: 4391,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4392",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4392.png",
      edition: 4392,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4393",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4393.png",
      edition: 4393,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4394",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4394.png",
      edition: 4394,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4395",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4395.png",
      edition: 4395,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4396",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4396.png",
      edition: 4396,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4397",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4397.png",
      edition: 4397,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4398",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4398.png",
      edition: 4398,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4399",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4399.png",
      edition: 4399,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #44",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/44.png",
      edition: 44,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #440",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/440.png",
      edition: 440,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4400",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4400.png",
      edition: 4400,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4401",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4401.png",
      edition: 4401,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4402",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4402.png",
      edition: 4402,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4403",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4403.png",
      edition: 4403,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4404",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4404.png",
      edition: 4404,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4405",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4405.png",
      edition: 4405,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4406",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4406.png",
      edition: 4406,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4407",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4407.png",
      edition: 4407,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4408",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4408.png",
      edition: 4408,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4409",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4409.png",
      edition: 4409,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #441",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/441.png",
      edition: 441,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4410",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4410.png",
      edition: 4410,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4411",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4411.png",
      edition: 4411,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4412",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4412.png",
      edition: 4412,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4413",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4413.png",
      edition: 4413,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4414",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4414.png",
      edition: 4414,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4415",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4415.png",
      edition: 4415,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4416",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4416.png",
      edition: 4416,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4417",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4417.png",
      edition: 4417,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4418",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4418.png",
      edition: 4418,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4419",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4419.png",
      edition: 4419,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #442",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/442.png",
      edition: 442,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4420",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4420.png",
      edition: 4420,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4421",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4421.png",
      edition: 4421,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 700,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4422",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4422.png",
      edition: 4422,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4423",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4423.png",
      edition: 4423,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4424",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4424.png",
      edition: 4424,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4425",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4425.png",
      edition: 4425,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4426",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4426.png",
      edition: 4426,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4427",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4427.png",
      edition: 4427,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4428",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4428.png",
      edition: 4428,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4429",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4429.png",
      edition: 4429,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #443",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/443.png",
      edition: 443,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4430",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4430.png",
      edition: 4430,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4431",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4431.png",
      edition: 4431,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4432",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4432.png",
      edition: 4432,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4433",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4433.png",
      edition: 4433,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4434",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4434.png",
      edition: 4434,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4435",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4435.png",
      edition: 4435,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4436",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4436.png",
      edition: 4436,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4437",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4437.png",
      edition: 4437,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4438",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4438.png",
      edition: 4438,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4439",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4439.png",
      edition: 4439,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #444",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/444.png",
      edition: 444,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4440",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4440.png",
      edition: 4440,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4441",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4441.png",
      edition: 4441,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4442",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4442.png",
      edition: 4442,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4443",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4443.png",
      edition: 4443,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #4444",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/4444.png",
      edition: 4444,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #445",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/445.png",
      edition: 445,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #446",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/446.png",
      edition: 446,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #447",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/447.png",
      edition: 447,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #448",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/448.png",
      edition: 448,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #449",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/449.png",
      edition: 449,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #45",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/45.png",
      edition: 45,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 650,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #450",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/450.png",
      edition: 450,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #451",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/451.png",
      edition: 451,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #452",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/452.png",
      edition: 452,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #453",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/453.png",
      edition: 453,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #454",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/454.png",
      edition: 454,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #455",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/455.png",
      edition: 455,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #456",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/456.png",
      edition: 456,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #457",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/457.png",
      edition: 457,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #458",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/458.png",
      edition: 458,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #459",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/459.png",
      edition: 459,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #46",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/46.png",
      edition: 46,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #460",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/460.png",
      edition: 460,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #461",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/461.png",
      edition: 461,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #462",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/462.png",
      edition: 462,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #463",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/463.png",
      edition: 463,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 750,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #464",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/464.png",
      edition: 464,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #465",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/465.png",
      edition: 465,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #466",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/466.png",
      edition: 466,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #467",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/467.png",
      edition: 467,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #468",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/468.png",
      edition: 468,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #469",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/469.png",
      edition: 469,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #47",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/47.png",
      edition: 47,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #470",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/470.png",
      edition: 470,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #471",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/471.png",
      edition: 471,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #472",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/472.png",
      edition: 472,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #473",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/473.png",
      edition: 473,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #474",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/474.png",
      edition: 474,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #475",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/475.png",
      edition: 475,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #476",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/476.png",
      edition: 476,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #477",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/477.png",
      edition: 477,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #478",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/478.png",
      edition: 478,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #479",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/479.png",
      edition: 479,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #48",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/48.png",
      edition: 48,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #480",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/480.png",
      edition: 480,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #481",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/481.png",
      edition: 481,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #482",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/482.png",
      edition: 482,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #483",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/483.png",
      edition: 483,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #484",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/484.png",
      edition: 484,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #485",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/485.png",
      edition: 485,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #486",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/486.png",
      edition: 486,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #487",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/487.png",
      edition: 487,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #488",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/488.png",
      edition: 488,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #489",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/489.png",
      edition: 489,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #49",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/49.png",
      edition: 49,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #490",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/490.png",
      edition: 490,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #491",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/491.png",
      edition: 491,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 600,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #492",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/492.png",
      edition: 492,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #493",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/493.png",
      edition: 493,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #494",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/494.png",
      edition: 494,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #495",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/495.png",
      edition: 495,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #496",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/496.png",
      edition: 496,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #497",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/497.png",
      edition: 497,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #498",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/498.png",
      edition: 498,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #499",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/499.png",
      edition: 499,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #5",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/5.png",
      edition: 5,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #50",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/50.png",
      edition: 50,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #500",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/500.png",
      edition: 500,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #501",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/501.png",
      edition: 501,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #502",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/502.png",
      edition: 502,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #503",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/503.png",
      edition: 503,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #504",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/504.png",
      edition: 504,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #505",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/505.png",
      edition: 505,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #506",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/506.png",
      edition: 506,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #507",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/507.png",
      edition: 507,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #508",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/508.png",
      edition: 508,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #509",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/509.png",
      edition: 509,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #51",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/51.png",
      edition: 51,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #510",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/510.png",
      edition: 510,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #511",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/511.png",
      edition: 511,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #512",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/512.png",
      edition: 512,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #513",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/513.png",
      edition: 513,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #514",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/514.png",
      edition: 514,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #515",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/515.png",
      edition: 515,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #516",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/516.png",
      edition: 516,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #517",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/517.png",
      edition: 517,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #518",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/518.png",
      edition: 518,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #519",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/519.png",
      edition: 519,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #52",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/52.png",
      edition: 52,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #520",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/520.png",
      edition: 520,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #521",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/521.png",
      edition: 521,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #522",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/522.png",
      edition: 522,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #523",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/523.png",
      edition: 523,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #524",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/524.png",
      edition: 524,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #525",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/525.png",
      edition: 525,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #526",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/526.png",
      edition: 526,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #527",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/527.png",
      edition: 527,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #528",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/528.png",
      edition: 528,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #529",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/529.png",
      edition: 529,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #53",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/53.png",
      edition: 53,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #530",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/530.png",
      edition: 530,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #531",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/531.png",
      edition: 531,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #532",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/532.png",
      edition: 532,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #533",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/533.png",
      edition: 533,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #534",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/534.png",
      edition: 534,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #535",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/535.png",
      edition: 535,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #536",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/536.png",
      edition: 536,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #537",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/537.png",
      edition: 537,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #538",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/538.png",
      edition: 538,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #539",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/539.png",
      edition: 539,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #54",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/54.png",
      edition: 54,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #540",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/540.png",
      edition: 540,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #541",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/541.png",
      edition: 541,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #542",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/542.png",
      edition: 542,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #543",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/543.png",
      edition: 543,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #544",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/544.png",
      edition: 544,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 600,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #545",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/545.png",
      edition: 545,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #546",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/546.png",
      edition: 546,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #547",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/547.png",
      edition: 547,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #548",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/548.png",
      edition: 548,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #549",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/549.png",
      edition: 549,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #55",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/55.png",
      edition: 55,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #550",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/550.png",
      edition: 550,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #551",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/551.png",
      edition: 551,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #552",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/552.png",
      edition: 552,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #553",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/553.png",
      edition: 553,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #554",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/554.png",
      edition: 554,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #555",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/555.png",
      edition: 555,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #556",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/556.png",
      edition: 556,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #557",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/557.png",
      edition: 557,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 650,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #558",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/558.png",
      edition: 558,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #559",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/559.png",
      edition: 559,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #56",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/56.png",
      edition: 56,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #560",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/560.png",
      edition: 560,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #561",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/561.png",
      edition: 561,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #562",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/562.png",
      edition: 562,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #563",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/563.png",
      edition: 563,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #564",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/564.png",
      edition: 564,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #565",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/565.png",
      edition: 565,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #566",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/566.png",
      edition: 566,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #567",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/567.png",
      edition: 567,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #568",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/568.png",
      edition: 568,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #569",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/569.png",
      edition: 569,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #57",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/57.png",
      edition: 57,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #570",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/570.png",
      edition: 570,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #571",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/571.png",
      edition: 571,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #572",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/572.png",
      edition: 572,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #573",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/573.png",
      edition: 573,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #574",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/574.png",
      edition: 574,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #575",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/575.png",
      edition: 575,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #576",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/576.png",
      edition: 576,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #577",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/577.png",
      edition: 577,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #578",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/578.png",
      edition: 578,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #579",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/579.png",
      edition: 579,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #58",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/58.png",
      edition: 58,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #580",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/580.png",
      edition: 580,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #581",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/581.png",
      edition: 581,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #582",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/582.png",
      edition: 582,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #583",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/583.png",
      edition: 583,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #584",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/584.png",
      edition: 584,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #585",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/585.png",
      edition: 585,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #586",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/586.png",
      edition: 586,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #587",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/587.png",
      edition: 587,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #588",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/588.png",
      edition: 588,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #589",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/589.png",
      edition: 589,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #59",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/59.png",
      edition: 59,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #590",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/590.png",
      edition: 590,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #591",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/591.png",
      edition: 591,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #592",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/592.png",
      edition: 592,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #593",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/593.png",
      edition: 593,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #594",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/594.png",
      edition: 594,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #595",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/595.png",
      edition: 595,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #596",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/596.png",
      edition: 596,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #597",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/597.png",
      edition: 597,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #598",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/598.png",
      edition: 598,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #599",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/599.png",
      edition: 599,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #6",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/6.png",
      edition: 6,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #60",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/60.png",
      edition: 60,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #600",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/600.png",
      edition: 600,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #601",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/601.png",
      edition: 601,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #602",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/602.png",
      edition: 602,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #603",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/603.png",
      edition: 603,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #604",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/604.png",
      edition: 604,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #605",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/605.png",
      edition: 605,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #606",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/606.png",
      edition: 606,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #607",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/607.png",
      edition: 607,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #608",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/608.png",
      edition: 608,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #609",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/609.png",
      edition: 609,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #61",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/61.png",
      edition: 61,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #610",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/610.png",
      edition: 610,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #611",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/611.png",
      edition: 611,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #612",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/612.png",
      edition: 612,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #613",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/613.png",
      edition: 613,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #614",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/614.png",
      edition: 614,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #615",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/615.png",
      edition: 615,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #616",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/616.png",
      edition: 616,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #617",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/617.png",
      edition: 617,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #618",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/618.png",
      edition: 618,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #619",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/619.png",
      edition: 619,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #62",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/62.png",
      edition: 62,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #620",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/620.png",
      edition: 620,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 600,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #621",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/621.png",
      edition: 621,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #622",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/622.png",
      edition: 622,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #623",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/623.png",
      edition: 623,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #624",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/624.png",
      edition: 624,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #625",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/625.png",
      edition: 625,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #626",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/626.png",
      edition: 626,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #627",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/627.png",
      edition: 627,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #628",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/628.png",
      edition: 628,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #629",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/629.png",
      edition: 629,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #63",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/63.png",
      edition: 63,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #630",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/630.png",
      edition: 630,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #631",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/631.png",
      edition: 631,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #632",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/632.png",
      edition: 632,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #633",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/633.png",
      edition: 633,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #634",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/634.png",
      edition: 634,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #635",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/635.png",
      edition: 635,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #636",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/636.png",
      edition: 636,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #637",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/637.png",
      edition: 637,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #638",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/638.png",
      edition: 638,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #639",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/639.png",
      edition: 639,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #64",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/64.png",
      edition: 64,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #640",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/640.png",
      edition: 640,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #641",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/641.png",
      edition: 641,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #642",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/642.png",
      edition: 642,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #643",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/643.png",
      edition: 643,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #644",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/644.png",
      edition: 644,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #645",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/645.png",
      edition: 645,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #646",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/646.png",
      edition: 646,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #647",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/647.png",
      edition: 647,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #648",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/648.png",
      edition: 648,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #649",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/649.png",
      edition: 649,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #65",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/65.png",
      edition: 65,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #650",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/650.png",
      edition: 650,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #651",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/651.png",
      edition: 651,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #652",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/652.png",
      edition: 652,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #653",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/653.png",
      edition: 653,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #654",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/654.png",
      edition: 654,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #655",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/655.png",
      edition: 655,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #656",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/656.png",
      edition: 656,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #657",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/657.png",
      edition: 657,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #658",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/658.png",
      edition: 658,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #659",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/659.png",
      edition: 659,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #66",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/66.png",
      edition: 66,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #660",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/660.png",
      edition: 660,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #661",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/661.png",
      edition: 661,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #662",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/662.png",
      edition: 662,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #663",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/663.png",
      edition: 663,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #664",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/664.png",
      edition: 664,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #665",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/665.png",
      edition: 665,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #666",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/666.png",
      edition: 666,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #667",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/667.png",
      edition: 667,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #668",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/668.png",
      edition: 668,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #669",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/669.png",
      edition: 669,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #67",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/67.png",
      edition: 67,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #670",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/670.png",
      edition: 670,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #671",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/671.png",
      edition: 671,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #672",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/672.png",
      edition: 672,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #673",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/673.png",
      edition: 673,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #674",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/674.png",
      edition: 674,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #675",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/675.png",
      edition: 675,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #676",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/676.png",
      edition: 676,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #677",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/677.png",
      edition: 677,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #678",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/678.png",
      edition: 678,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #679",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/679.png",
      edition: 679,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #68",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/68.png",
      edition: 68,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #680",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/680.png",
      edition: 680,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #681",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/681.png",
      edition: 681,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #682",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/682.png",
      edition: 682,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 650,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #683",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/683.png",
      edition: 683,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #684",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/684.png",
      edition: 684,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #685",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/685.png",
      edition: 685,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #686",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/686.png",
      edition: 686,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 650,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #687",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/687.png",
      edition: 687,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #688",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/688.png",
      edition: 688,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #689",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/689.png",
      edition: 689,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #69",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/69.png",
      edition: 69,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #690",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/690.png",
      edition: 690,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #691",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/691.png",
      edition: 691,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #692",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/692.png",
      edition: 692,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #693",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/693.png",
      edition: 693,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #694",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/694.png",
      edition: 694,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #695",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/695.png",
      edition: 695,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #696",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/696.png",
      edition: 696,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #697",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/697.png",
      edition: 697,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #698",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/698.png",
      edition: 698,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #699",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/699.png",
      edition: 699,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #7",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/7.png",
      edition: 7,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #70",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/70.png",
      edition: 70,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #700",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/700.png",
      edition: 700,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #701",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/701.png",
      edition: 701,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #702",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/702.png",
      edition: 702,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #703",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/703.png",
      edition: 703,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 700,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #704",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/704.png",
      edition: 704,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 700,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #705",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/705.png",
      edition: 705,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #706",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/706.png",
      edition: 706,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #707",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/707.png",
      edition: 707,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #708",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/708.png",
      edition: 708,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #709",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/709.png",
      edition: 709,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #71",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/71.png",
      edition: 71,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #710",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/710.png",
      edition: 710,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #711",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/711.png",
      edition: 711,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #712",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/712.png",
      edition: 712,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #713",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/713.png",
      edition: 713,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 650,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #714",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/714.png",
      edition: 714,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #715",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/715.png",
      edition: 715,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #716",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/716.png",
      edition: 716,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #717",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/717.png",
      edition: 717,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #718",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/718.png",
      edition: 718,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #719",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/719.png",
      edition: 719,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #72",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/72.png",
      edition: 72,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #720",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/720.png",
      edition: 720,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #721",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/721.png",
      edition: 721,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #722",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/722.png",
      edition: 722,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #723",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/723.png",
      edition: 723,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #724",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/724.png",
      edition: 724,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #725",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/725.png",
      edition: 725,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #726",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/726.png",
      edition: 726,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #727",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/727.png",
      edition: 727,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #728",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/728.png",
      edition: 728,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #729",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/729.png",
      edition: 729,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #73",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/73.png",
      edition: 73,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #730",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/730.png",
      edition: 730,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #731",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/731.png",
      edition: 731,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #732",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/732.png",
      edition: 732,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #733",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/733.png",
      edition: 733,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #734",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/734.png",
      edition: 734,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #735",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/735.png",
      edition: 735,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #736",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/736.png",
      edition: 736,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #737",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/737.png",
      edition: 737,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #738",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/738.png",
      edition: 738,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #739",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/739.png",
      edition: 739,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #74",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/74.png",
      edition: 74,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #740",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/740.png",
      edition: 740,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #741",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/741.png",
      edition: 741,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #742",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/742.png",
      edition: 742,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #743",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/743.png",
      edition: 743,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #744",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/744.png",
      edition: 744,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #745",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/745.png",
      edition: 745,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #746",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/746.png",
      edition: 746,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 700,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #747",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/747.png",
      edition: 747,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #748",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/748.png",
      edition: 748,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #749",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/749.png",
      edition: 749,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #75",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/75.png",
      edition: 75,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #750",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/750.png",
      edition: 750,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #751",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/751.png",
      edition: 751,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #752",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/752.png",
      edition: 752,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #753",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/753.png",
      edition: 753,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #754",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/754.png",
      edition: 754,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #755",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/755.png",
      edition: 755,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #756",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/756.png",
      edition: 756,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #757",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/757.png",
      edition: 757,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #758",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/758.png",
      edition: 758,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #759",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/759.png",
      edition: 759,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #76",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/76.png",
      edition: 76,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #760",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/760.png",
      edition: 760,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #761",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/761.png",
      edition: 761,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #762",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/762.png",
      edition: 762,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #763",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/763.png",
      edition: 763,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #764",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/764.png",
      edition: 764,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #765",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/765.png",
      edition: 765,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #766",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/766.png",
      edition: 766,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 600,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #767",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/767.png",
      edition: 767,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #768",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/768.png",
      edition: 768,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #769",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/769.png",
      edition: 769,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #77",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/77.png",
      edition: 77,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #770",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/770.png",
      edition: 770,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #771",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/771.png",
      edition: 771,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #772",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/772.png",
      edition: 772,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #773",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/773.png",
      edition: 773,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #774",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/774.png",
      edition: 774,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #775",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/775.png",
      edition: 775,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #776",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/776.png",
      edition: 776,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #777",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/777.png",
      edition: 777,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #778",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/778.png",
      edition: 778,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #779",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/779.png",
      edition: 779,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #78",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/78.png",
      edition: 78,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #780",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/780.png",
      edition: 780,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #781",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/781.png",
      edition: 781,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #782",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/782.png",
      edition: 782,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #783",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/783.png",
      edition: 783,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #784",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/784.png",
      edition: 784,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #785",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/785.png",
      edition: 785,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #786",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/786.png",
      edition: 786,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #787",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/787.png",
      edition: 787,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #788",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/788.png",
      edition: 788,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #789",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/789.png",
      edition: 789,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #79",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/79.png",
      edition: 79,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #790",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/790.png",
      edition: 790,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 700,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #791",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/791.png",
      edition: 791,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #792",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/792.png",
      edition: 792,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #793",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/793.png",
      edition: 793,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #794",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/794.png",
      edition: 794,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #795",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/795.png",
      edition: 795,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #796",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/796.png",
      edition: 796,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #797",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/797.png",
      edition: 797,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #798",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/798.png",
      edition: 798,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #799",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/799.png",
      edition: 799,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #8",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/8.png",
      edition: 8,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #80",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/80.png",
      edition: 80,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #800",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/800.png",
      edition: 800,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #801",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/801.png",
      edition: 801,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #802",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/802.png",
      edition: 802,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #803",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/803.png",
      edition: 803,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #804",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/804.png",
      edition: 804,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #805",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/805.png",
      edition: 805,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #806",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/806.png",
      edition: 806,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #807",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/807.png",
      edition: 807,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #808",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/808.png",
      edition: 808,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #809",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/809.png",
      edition: 809,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #81",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/81.png",
      edition: 81,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #810",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/810.png",
      edition: 810,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #811",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/811.png",
      edition: 811,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #812",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/812.png",
      edition: 812,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #813",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/813.png",
      edition: 813,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #814",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/814.png",
      edition: 814,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #815",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/815.png",
      edition: 815,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #816",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/816.png",
      edition: 816,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #817",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/817.png",
      edition: 817,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #818",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/818.png",
      edition: 818,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #819",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/819.png",
      edition: 819,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #82",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/82.png",
      edition: 82,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #820",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/820.png",
      edition: 820,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #821",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/821.png",
      edition: 821,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #822",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/822.png",
      edition: 822,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #823",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/823.png",
      edition: 823,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #824",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/824.png",
      edition: 824,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #825",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/825.png",
      edition: 825,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #826",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/826.png",
      edition: 826,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #827",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/827.png",
      edition: 827,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #828",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/828.png",
      edition: 828,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #829",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/829.png",
      edition: 829,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #83",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/83.png",
      edition: 83,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #830",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/830.png",
      edition: 830,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #831",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/831.png",
      edition: 831,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #832",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/832.png",
      edition: 832,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #833",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/833.png",
      edition: 833,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #834",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/834.png",
      edition: 834,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #835",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/835.png",
      edition: 835,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #836",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/836.png",
      edition: 836,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #837",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/837.png",
      edition: 837,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #838",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/838.png",
      edition: 838,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #839",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/839.png",
      edition: 839,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #84",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/84.png",
      edition: 84,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #840",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/840.png",
      edition: 840,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #841",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/841.png",
      edition: 841,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #842",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/842.png",
      edition: 842,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #843",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/843.png",
      edition: 843,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #844",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/844.png",
      edition: 844,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #845",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/845.png",
      edition: 845,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #846",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/846.png",
      edition: 846,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #847",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/847.png",
      edition: 847,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #848",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/848.png",
      edition: 848,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #849",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/849.png",
      edition: 849,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #85",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/85.png",
      edition: 85,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #850",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/850.png",
      edition: 850,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #851",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/851.png",
      edition: 851,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #852",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/852.png",
      edition: 852,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #853",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/853.png",
      edition: 853,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 650,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #854",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/854.png",
      edition: 854,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #855",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/855.png",
      edition: 855,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #856",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/856.png",
      edition: 856,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #857",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/857.png",
      edition: 857,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #858",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/858.png",
      edition: 858,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #859",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/859.png",
      edition: 859,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 600,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #86",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/86.png",
      edition: 86,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #860",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/860.png",
      edition: 860,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #861",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/861.png",
      edition: 861,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #862",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/862.png",
      edition: 862,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #863",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/863.png",
      edition: 863,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #864",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/864.png",
      edition: 864,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #865",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/865.png",
      edition: 865,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #866",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/866.png",
      edition: 866,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #867",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/867.png",
      edition: 867,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #868",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/868.png",
      edition: 868,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #869",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/869.png",
      edition: 869,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #87",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/87.png",
      edition: 87,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #870",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/870.png",
      edition: 870,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #871",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/871.png",
      edition: 871,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #872",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/872.png",
      edition: 872,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #873",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/873.png",
      edition: 873,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #874",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/874.png",
      edition: 874,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #875",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/875.png",
      edition: 875,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #876",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/876.png",
      edition: 876,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #877",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/877.png",
      edition: 877,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #878",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/878.png",
      edition: 878,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #879",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/879.png",
      edition: 879,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #88",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/88.png",
      edition: 88,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #880",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/880.png",
      edition: 880,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #881",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/881.png",
      edition: 881,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #882",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/882.png",
      edition: 882,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #883",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/883.png",
      edition: 883,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #884",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/884.png",
      edition: 884,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #885",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/885.png",
      edition: 885,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #886",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/886.png",
      edition: 886,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #887",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/887.png",
      edition: 887,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #888",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/888.png",
      edition: 888,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #889",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/889.png",
      edition: 889,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #89",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/89.png",
      edition: 89,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #890",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/890.png",
      edition: 890,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #891",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/891.png",
      edition: 891,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #892",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/892.png",
      edition: 892,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #893",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/893.png",
      edition: 893,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #894",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/894.png",
      edition: 894,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #895",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/895.png",
      edition: 895,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #896",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/896.png",
      edition: 896,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #897",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/897.png",
      edition: 897,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #898",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/898.png",
      edition: 898,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #899",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/899.png",
      edition: 899,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #9",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/9.png",
      edition: 9,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #90",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/90.png",
      edition: 90,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #900",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/900.png",
      edition: 900,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #901",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/901.png",
      edition: 901,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #902",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/902.png",
      edition: 902,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #903",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/903.png",
      edition: 903,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #904",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/904.png",
      edition: 904,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #905",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/905.png",
      edition: 905,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #906",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/906.png",
      edition: 906,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #907",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/907.png",
      edition: 907,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #908",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/908.png",
      edition: 908,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #909",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/909.png",
      edition: 909,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #91",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/91.png",
      edition: 91,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #910",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/910.png",
      edition: 910,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #911",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/911.png",
      edition: 911,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 700,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #912",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/912.png",
      edition: 912,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #913",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/913.png",
      edition: 913,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #914",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/914.png",
      edition: 914,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #915",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/915.png",
      edition: 915,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #916",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/916.png",
      edition: 916,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #917",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/917.png",
      edition: 917,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #918",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/918.png",
      edition: 918,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #919",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/919.png",
      edition: 919,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #92",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/92.png",
      edition: 92,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #920",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/920.png",
      edition: 920,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 600,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #921",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/921.png",
      edition: 921,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #922",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/922.png",
      edition: 922,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #923",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/923.png",
      edition: 923,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #924",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/924.png",
      edition: 924,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #925",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/925.png",
      edition: 925,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #926",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/926.png",
      edition: 926,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #927",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/927.png",
      edition: 927,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #928",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/928.png",
      edition: 928,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #929",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/929.png",
      edition: 929,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #93",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/93.png",
      edition: 93,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 650,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #930",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/930.png",
      edition: 930,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #931",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/931.png",
      edition: 931,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #932",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/932.png",
      edition: 932,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #933",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/933.png",
      edition: 933,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #934",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/934.png",
      edition: 934,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #935",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/935.png",
      edition: 935,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 650,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #936",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/936.png",
      edition: 936,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #937",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/937.png",
      edition: 937,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #938",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/938.png",
      edition: 938,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #939",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/939.png",
      edition: 939,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #94",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/94.png",
      edition: 94,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #940",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/940.png",
      edition: 940,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #941",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/941.png",
      edition: 941,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #942",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/942.png",
      edition: 942,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #943",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/943.png",
      edition: 943,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #944",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/944.png",
      edition: 944,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #945",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/945.png",
      edition: 945,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #946",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/946.png",
      edition: 946,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #947",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/947.png",
      edition: 947,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #948",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/948.png",
      edition: 948,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 600,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #949",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/949.png",
      edition: 949,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #95",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/95.png",
      edition: 95,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #950",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/950.png",
      edition: 950,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #951",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/951.png",
      edition: 951,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #952",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/952.png",
      edition: 952,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #953",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/953.png",
      edition: 953,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #954",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/954.png",
      edition: 954,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #955",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/955.png",
      edition: 955,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #956",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/956.png",
      edition: 956,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #957",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/957.png",
      edition: 957,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #958",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/958.png",
      edition: 958,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 350,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #959",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/959.png",
      edition: 959,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #96",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/96.png",
      edition: 96,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #960",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/960.png",
      edition: 960,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #961",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/961.png",
      edition: 961,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #962",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/962.png",
      edition: 962,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #963",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/963.png",
      edition: 963,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #964",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/964.png",
      edition: 964,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #965",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/965.png",
      edition: 965,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 400,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #966",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/966.png",
      edition: 966,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #967",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/967.png",
      edition: 967,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #968",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/968.png",
      edition: 968,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #969",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/969.png",
      edition: 969,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #97",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/97.png",
      edition: 97,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #970",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/970.png",
      edition: 970,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #971",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/971.png",
      edition: 971,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #972",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/972.png",
      edition: 972,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #973",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/973.png",
      edition: 973,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 500,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #974",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/974.png",
      edition: 974,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #975",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/975.png",
      edition: 975,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #976",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/976.png",
      edition: 976,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #977",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/977.png",
      edition: 977,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #978",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/978.png",
      edition: 978,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #979",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/979.png",
      edition: 979,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #98",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/98.png",
      edition: 98,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #980",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/980.png",
      edition: 980,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #981",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/981.png",
      edition: 981,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #982",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/982.png",
      edition: 982,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #983",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/983.png",
      edition: 983,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #984",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/984.png",
      edition: 984,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #985",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/985.png",
      edition: 985,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #986",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/986.png",
      edition: 986,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #987",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/987.png",
      edition: 987,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #988",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/988.png",
      edition: 988,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #989",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/989.png",
      edition: 989,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #99",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/99.png",
      edition: 99,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 300,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #990",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/990.png",
      edition: 990,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #991",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/991.png",
      edition: 991,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #992",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/992.png",
      edition: 992,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #993",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/993.png",
      edition: 993,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #994",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/994.png",
      edition: 994,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 250,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #995",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/995.png",
      edition: 995,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 200,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #996",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/996.png",
      edition: 996,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 150,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #997",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/997.png",
      edition: 997,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #998",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/998.png",
      edition: 998,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 550,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
    {
      name: "Pathogen Lab #999",
      description:
        "Infect the world with your virus and change the fate of humanity!",
      image: "ipfs://QmYdXmZ1jvh1K1DYTfrD9uLNQbv8P396KTcyQnGvQS2bho/999.png",
      edition: 999,
      attributes: [
        {
          trait_type: "Spreading Power",
          value: 450,
          max_value: 750,
        },
      ],
      compiler: "Pathogen Lab",
    },
  ];

  const calculateStakeIncome = () => {
    let wallet = document.getElementById("wallet").value;
    setTotalNfts(0);
    setTotal(0);
    setSpreadingPowers([]);
    setNftId([]);
    setCheck(false);
    setIsSuccess(true);
    if (wallet.length > 41) {
      setCheck(true);
      let endPoint =
        "https://deep-index.moralis.io/api/v2.2/" +
        wallet +
        "/nft?chain=0x1&format=decimal&token_addresses%5B0%5D=0x96ff5aa07a26c2f9aec427f11e4b3c63589e9c25&media_items=false";

      const web3ApiKey =
        "QJbFFsdt06oFbuepezSFmeNNOsuuK5CMmd6W62X98WBJPcqpNsltV4hRAjgGHwfN";
      const headers = { accept: "application/json", "X-API-Key": web3ApiKey };

      axios
        .get(endPoint, {
          headers: headers,
        })
        .then(async (response) => {
          let data;
          if (response.data.cursor != null) {
            let cursor = response.data.cursor;
            let endPoint2 =
              "https://deep-index.moralis.io/api/v2.2/" +
              wallet +
              "/nft?chain=0x1&format=decimal&cursor=" +
              cursor +
              "&token_addresses%5B0%5D=0x96ff5aa07a26c2f9aec427f11e4b3c63589e9c25&media_items=false";
            await axios
              .get(endPoint2, {
                headers: headers,
              })
              .then(async (response2) => {
                if (response2.data.cursor != null) {
                  let cursor = response2.data.cursor;
                  let endPoint3 =
                    "https://deep-index.moralis.io/api/v2.2/" +
                    wallet +
                    "/nft?chain=0x1&format=decimal&cursor=" +
                    cursor +
                    "&token_addresses%5B0%5D=0x96ff5aa07a26c2f9aec427f11e4b3c63589e9c25&media_items=false";
                  await axios
                    .get(endPoint3, {
                      headers: headers,
                    })
                    .then((response3) => {
                      data = [
                        ...response.data.result,
                        ...response2.data.result,
                        ...response3.data.result,
                      ];
                    });
                } else {
                  data = [...response.data.result, ...response2.data.result];
                }
              });
          } else {
            data = response.data.result;
          }
          let i = 0;
          let total = 0;
          let spreadingPowers = [];
          let NFTIds = [];
          setTotalNfts(data.length);
          setTotal(0);
          setSpreadingPowers([]);
          setNftId([]);
          setHasBooster(false);
          setBooster("");
          if (data.length > 0) {
            while (i < data.length) {
              let metadata;
              let nftSpreadingPower;
              let nftIds = data[i].token_id;
              if (data[i].metadata !== null) {
                metadata = JSON.parse(data[i].metadata);
                nftSpreadingPower = metadata.attributes[0].value;
              } else {
                let obj = m1.find((o) => o.edition == nftIds);
                nftSpreadingPower = obj.attributes[0].value;
              }
              let monthlyIncome = 0;
              spreadingPowers.push(nftSpreadingPower);
              NFTIds.push(nftIds);
              monthlyIncome = nftSpreadingPower * 0.00002;
              console.log(monthlyIncome);
              total = total + monthlyIncome;
              i++;
            }

            // if (
            //   wallet.toLowerCase() ==
            //   "0xB7830B98E3f25e11A252d0ac5B8b07e9d1F5C57e".toLowerCase()
            // ) {
            //   setHasBooster(true);
            //   setBooster("%150");
            //   total = total * 2.5;
            // }

            setTotal(total);
            setSpreadingPowers(spreadingPowers);
            setNftId(NFTIds);
            setIsSuccess(true);
          }
          setCheck(false);
        })
        .catch(function (error) {
          setTotalNfts(0);
          setTotal(0);
          setSpreadingPowers([]);
          setNftId([]);
          setCheck(false);
          setIsSuccess(true);
        });
    }
  };

  return (
    <div>
      <div className="containerChecker">
        <div className="checkerBorder">
          {check ? (
            <div className="loader">
              <img src="/spinner.gif" className="spinner" />
              Checking your wallet..
            </div>
          ) : null}
          <div class="glad2">Pathogen Lab</div>
          <div class="glad">Passive Income Calculator</div>
          <input
            type="text"
            minlength="41"
            class="searchInput"
            id="wallet"
            placeholder="Write your wallet address here"
          />
          <button
            class="button-87"
            onClick={calculateStakeIncome}
            role="button"
          >
            Calculate Your Passive Income
          </button>
          {isSuccess ? (
            <div class="stake">
              <div class="totalNft"> You have {totalNfts} Pathogen Lab NFT!</div>
              {hasBooster ? (
                <div class="spreadingBooster">
                  You have {booster} Spreading Booster!
                </div>
              ) : null}
              {hasBooster ? (
                <div class="totalEarn">
                  Your Total Monthly Income with {booster} Booster: <br />{" "}
                  {total.toFixed(5)} ETH
                </div>
              ) : (
                <div class="totalEarn">
                  Your Total Monthly Income: {total.toFixed(5)} ETH
                </div>
              )}
              <div class="totalNft">
                <table class="tableStake">
                  <tr>
                    <th>NFT ID</th>
                    <th>Spreading Power</th>
                    <th>Monthly Income</th>
                  </tr>
                  {total > 0 ? (
                    <>
                      {spreadingPowers.map((spreadingPower, index) => {
                        return (
                          <tr>
                            <td>Pathogen Lab #{nftId[index]}</td>
                            <td>{spreadingPower}</td>
                            <td>
                              {(() => {
                                return <span>{spreadingPower * 0.00002} ETH</span>;
                              })()}
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <tr>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>{" "}
                      </tr>
                    </>
                  )}
                </table>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
